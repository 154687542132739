var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--7",
      attrs: {
        title: _vm.title,
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "label-input" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.intCompanyGoal,
                expression: "intCompanyGoal"
              }
            ],
            class: { "has-text": _vm.hasText },
            attrs: { name: "", id: "purpose", cols: "30", rows: "10" },
            domProps: { value: _vm.intCompanyGoal },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.intCompanyGoal = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "purpose" } }, [
            _vm._v("Selskabets formål er at...")
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e898ed44", { render: render, staticRenderFns: staticRenderFns })
  }
}