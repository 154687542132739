var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-overview" },
    [
      _c("div", { staticClass: "case-overview__block" }, [
        _c("div", { staticClass: "case-product" }, [
          _c("p", {
            staticClass: "case-product__heading",
            domProps: { textContent: _vm._s(_vm.order.producttitle) }
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "case-product__text",
            domProps: { textContent: _vm._s(_vm.order.key_field) }
          })
        ])
      ]),
      _vm._v(" "),
      _c("case-tasks", { attrs: { order: _vm.order, customer: _vm.customer } }),
      _vm._v(" "),
      _c("case-responsible", { attrs: { order: _vm.order } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-326e6d32", { render: render, staticRenderFns: staticRenderFns })
  }
}