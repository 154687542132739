import MomentObj from 'moment';

export const DefaultCallHours = (setDate) => {
	let hours = [];
	let hourSet = [
		{
			value: '09-11',
			key: '09',
			hourCheck: 10
		},
		{
			value: '11-13',
			key: '11',
			hourCheck: 12
		},
		{
			value: '13-15',
			key: '13',
			hourCheck: 14
		},
		{
			value: '15-17',
			key: '15',
			hourCheck: 16
		}
	];

	for (let i in hourSet) {
		let hourData = hourSet[i];
		let m = MomentObj(setDate + ' ' + parseInt(hourData.hourCheck) + ':00', 'DD-MM-YYYY HH:mm');
		if (m.isAfter(MomentObj())) {
			hours.push({
				key: hourData.key,
				value: hourData.value
			});
		}
	}

	return hours;
};

export const DefaultStartDate = () => {
	let startDay = MomentObj();
	if (startDay.isAfter(MomentObj(startDay).hour(16).minute(0).second(0))) {
		startDay = startDay.add(1, 'days');
	}

	if (startDay.isoWeekday() > 5) {
		startDay = startDay.add(1, 'weeks').isoWeekday(1);
	}

	return startDay.format('DD-MM-YYYY');
};

export const NextCallTime = () => {
	let defaultStartDate = DefaultStartDate();
	let callHours = DefaultCallHours(defaultStartDate);

	let date = defaultStartDate + ' ' + callHours[0].key + ':00';
	return MomentObj(date, 'DD-MM-YYYY HH:mm');
};
