var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-case" },
    [
      _c("h1", { staticClass: "new-case__heading" }, [
        _vm._v("Hvad kan vi hjælpe med?")
      ]),
      _vm._v(" "),
      _vm.isCompany
        ? _c("h3", { staticClass: "new-case__subheading" }, [
            _vm._v(
              "\n    Start din næste sag nedenfor. Alle priser er ekskl. moms.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCompany
        ? _c("h3", { staticClass: "new-case__subheading" }, [
            _vm._v(
              "\n    Start din næste sag nedenfor. Alle priser er inkl. moms.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "new-case__grid" },
        [
          _vm._l(_vm.products, function(product, key) {
            return _c("div", { key: key, staticClass: "case-item" }, [
              _c("img", {
                staticClass: "case-item__icon",
                attrs: { src: "/assets/img/icons/" + product.icon, alt: "" }
              }),
              _vm._v(" "),
              _c("h5", {
                staticClass: "case-item__title",
                domProps: { textContent: _vm._s(product.title) }
              }),
              _vm._v(" "),
              product.bookmeeting
                ? _c("span", { staticClass: "case-item__text" }, [
                    _vm._v("Uforpligtende")
                  ])
                : _c("span", {
                    directives: [
                      {
                        name: "price",
                        rawName: "v-price",
                        value: product.price,
                        expression: "product.price"
                      }
                    ],
                    staticClass: "case-item__price"
                  }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn case-item__btn",
                  class: { "case-item__btn--dark": product.bookmeeting },
                  on: {
                    click: function($event) {
                      return _vm.selectProduct(product)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(product.bookmeeting ? "Betil tid" : "Start sag") +
                      "\n      "
                  )
                ]
              )
            ])
          }),
          _vm._v(" "),
          _vm._m(0)
        ],
        2
      ),
      _vm._v(" "),
      _vm.product
        ? _c("new-case-modal", {
            attrs: {
              product: _vm.product,
              customer: _vm.customer,
              bookMeeting: _vm.product.bookmeeting ? true : false,
              "campaign-source-id": _vm.existing_customer_source_id
            },
            on: {
              close: function($event) {
                _vm.product = null
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productLoading ? _c("div", { staticClass: "loading" }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-case__footer" }, [
      _c("p", [
        _vm._v("Nogle produkttyper kan være underlagt gebyrer til staten. "),
        _c("span", [_vm._v("Disse afklares inden opstart af sagen.")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d0db1322", { render: render, staticRenderFns: staticRenderFns })
  }
}