var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kyc-wrapper", class: { loading: _vm.loading } },
    [
      _c("h2", { domProps: { textContent: _vm._s(_vm.title) } }),
      _vm._v(" "),
      _vm._l(_vm.idItemList, function(item) {
        return _c(
          "div",
          {
            key: item.type,
            staticClass: "id-item",
            class: {
              "id-item--checked": item.selected,
              "id-item--disabled": _vm.loading
            },
            on: {
              click: function($event) {
                return _vm.selectType(item)
              }
            }
          },
          [
            _c("img", {
              staticClass: "id-item__type-icon",
              attrs: { src: item.img, alt: "Ikon af " + item.name }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "id-item__content" }, [
              _c("span", {
                staticClass: "id-item__text",
                domProps: { textContent: _vm._s(item.name) }
              }),
              _vm._v(" "),
              item.description && !_vm.finishedValidation
                ? _c("span", {
                    staticClass: "id-item__small-text",
                    domProps: { textContent: _vm._s(item.description) }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._m(0, true)
          ]
        )
      }),
      _vm._v(" "),
      _vm.finishedValidation
        ? _c("button", {
            staticClass: "btn",
            domProps: { textContent: _vm._s(_vm.translations.finish_btn) },
            on: {
              click: function($event) {
                return _vm.$emit("submit", {})
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("p", {
            staticClass: "error",
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "id-item__validation-icon" }, [
      _c("span", { staticClass: "id-item__validation-icon__line" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-71e649d0", { render: render, staticRenderFns: staticRenderFns })
  }
}