var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-flow-progress" }, [
    _c("div", { staticClass: "order-flow-progress__wrapper" }, [
      _c("div", { staticClass: "order-flow-progress__bar" }, [
        _c("div", {
          staticClass: "order-flow-progress__fill",
          style: { width: _vm.percentComplete + "%" }
        }),
        _vm._v(" "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-dot" }, [
      _c("span", { staticClass: "progress-dot__fill" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2af2af4d", { render: render, staticRenderFns: staticRenderFns })
  }
}