<template>
  <div class="order-flow order-flow--company" :class="{ loading: loading }" v-if="lead && product">
    <order-flow-progress :percentComplete="percentComplete"></order-flow-progress>

    <div class="order-flow__back" v-if="hasNavigated && !lockSlideNumbers">
      <button class="order-flow__back__btn" @click="goToPrevSlide">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 162.86">
          <path
            d="M168,72.43H30.73L87.79,15.36A9,9,0,1,0,75.07,2.64L2.64,75.06c-.21.21-.41.44-.6.67a3.15,3.15,0,0,0-.22.29l-.31.41c-.08.13-.15.26-.23.38l-.22.38,0,0c-.06.12-.11.24-.16.35s-.13.26-.18.4-.1.26-.15.4-.11.28-.15.43-.07.27-.11.41-.08.29-.11.44-.05.32-.07.48L0,80.54a10.58,10.58,0,0,0,0,1.78l.06.38c0,.16,0,.33.07.49s.07.29.11.43.07.29.11.42.1.28.15.42a3.52,3.52,0,0,0,.15.42c.05.13.11.25.17.38s.13.28.2.41l.21.36c.08.13.16.27.25.4a4.61,4.61,0,0,0,.28.38,3.81,3.81,0,0,0,.24.32q.29.34.6.66h0l72.43,72.43A9,9,0,1,0,87.79,147.5L30.73,90.43H168a9,9,0,0,0,0-18Z"
          />
        </svg>
      </button>
    </div>

    <company
      :slide-number="slideNumber"
      :company="companyData"
      @submit="setCompany"
      :slide="slide"
      @slide="setCompanySlide"
      @percent="(val) => (percentComplete = val)"
    ></company>
    <extra
      v-if="slide === 'extra'"
      :extra="extra"
      @submit="setExtra"
      :loading="loading"
      :error="error"
    ></extra>
    <thanks
      v-if="slide === 'thanks'"
      :lead="lead"
      :product="product"
      @submit="$emit('close')"
    ></thanks>
  </div>
</template>

<script>
import Company from "./single-company.vue";
import Extra from "./slides/extra.vue";
import Thanks from "./slides/thanks.vue";
import DataStore from "../../services/datastore";
import OrderFlowProgress from './../order-flow-progress.vue';

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      companyStructure: 0,
      extra: {
        ownerdeal: false,
        ceocontract: false,
        trademark: false,
        debt: false,
        extra: "",
      },
      companyData: {
        companyType: null,
        companyName: "",
        companyByName: [],
        owners: [],
        ceo: null,
        address: null,
        goal: "",
        otherStuff: null,
        extra: {},
        completed: false,
      },
      percentComplete: 0,
      slide: "company-name",
      slideNumber: 1,
      lockSlideNumbers: false,
      hasNavigated: false,
      loading: false,
      error: false,
    };
  },
  mounted() {
    if (!this.hasNavigated) {
      this.$router.replace({
        view: "lead-view",
        params: { view: "company", slide: null },
        query: this.$route.query,
      });
    }
  },
  watch: {
    "$route.params.slide"() {
      let slide = this.$route.params.slide;
      if (this.slide === "thanks" || this.slide === "final-order") {
        return;
      }

      console.log("Slide", slide);
      if (slide) {
        console.log("Set slide", this.slide);
        this.slide = slide;
      } else {
        this.hasNavigated = false;
        this.slide = "company-name";
      }
    },
  },
  methods: {
    setCompanySlide(slide) {
      this.goToSlide(slide);
    },
    setCompany(company) {
      this.companyData = Object.assign({}, company);
      this.goToSlide("extra");
    },
    goToPrevSlide() {
      let nextSlideNumber = this.slideNumber - 1;
      if (!this.lockSlideNumbers && nextSlideNumber > 0) {
        this.slideNumber -= 1;
      }

      this.$router.back();
    },
    navigateToSlide(slide) {
      if (!this.lockSlideNumbers) {
        this.slideNumber += 1;
      }

      this.hasNavigated = slide !== "thanks";

      this.$router.push({
        view: "lead-view",
        params: { view: "company", slide },
        query: this.$route.query
      });
    },
    goToSlide(slide) {
      if (this.isNavigatedFromOrder) {
        //Should be used in future if edit order
      } else {
        if (slide === "thanks") {
          this.lockSlideNumbers = true;
        }

        this.navigateToSlide(slide);
      }
    },
    setExtra(extraOptions) {
      this.$set(this.companyData, "extra", Object.assign({}, extraOptions));
      this.makeOrder();
    },
    makeOrder() {
      if (this.loading || !this.product) return;

      let companyData = Object.assign({}, this.companyData);
      companyData.ceo = companyData.ceo
        .filter((ceo) => ceo.selected)
        .map((ceo) => {
          delete ceo.selected;
          return ceo;
        });

      let finalOrder = {
        data: companyData,
        productid: this.product.id
      };

      this.loading = true;
      this.error = false;

      DataStore.updateCompanyLead(this.$route.params.hash, finalOrder)
        .then((res) => {
          this.loading = false;
          if (res.status === "error") {
            this.error = true;
            return;
          }

          this.$emit("updated", Object.assign({}, this.lead, res.data));
          this.goToSlide("thanks");
        })
        .catch((error) => {
          this.loaidng = false;
          this.error = true;
        });
    },
  },
  computed: {
    isNavigatedFromOrder() {
      return this.$route.query.navigatedFromOrder ? true : false;
    },
    product() {
        let product = this.lead.products.filter((product) => product.id == this.$route.query.productid);
        return (product.length) ? product[0] : null;
    }
  },
  components: {
    Company,
    Extra,
    Thanks,
    OrderFlowProgress
  },
};
</script>
