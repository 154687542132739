<template>
  <modal title="Udfyld kundedata" :show="true" :noScroll="false" @close="onClose">
    <div class="lead-customer-validation">
      <label-select
        title="Type"
        :options="customerTypes"
        :value="intLead.customer_is_company ? '1' : '0'"
        @input="
          (inp) => {
            intLead.customer_is_company = (inp == 1) ? true : false;
          }
        "
      >
      </label-select>
      <inputField
        name="Navn"
        v-model="intLead.customer_name"
        :error="
          errorFields.indexOf('customer_name') > -1 ? 'Skal udfyldes' : ''
        "
      ></inputField>
      <inputField
        name="Adresse"
        v-model="intLead.customer_address"
        :error="
          errorFields.indexOf('customer_address') > -1 ? 'Skal udfyldes' : ''
        "
      ></inputField>
      <div class="two-col">
        <inputField
          class="label-input--postal"
          name="Post nr."
          v-model="intLead.customer_postal"
          input-type="tel"
          @stopTyping="getTownByZip"
          :error="
            errorFields.indexOf('customer_postal') > -1 ? 'Skal udfyldes' : ''
          "
        ></inputField>
        <inputField
          name="By"
          v-model="intLead.customer_town"
          :error="
            errorFields.indexOf('customer_town') > -1 ? 'Skal udfyldes' : ''
          "
        ></inputField>
      </div>
      <inputField
        name="E-mail"
        v-model="intLead.customer_email"
        input-type="email"
        :error="
          errorFields.indexOf('customer_email') > -1
            ? 'Skal udfyldes og være en valid e-mail'
            : ''
        "
      ></inputField>
      <inputField
        name="Tlf"
        v-model="intLead.customer_phone"
        input-type="tel"
        :error="
          errorFields.indexOf('customer_email') > -1 ? 'Skal udfyldes' : ''
        "
      ></inputField>
      <button
        class="btn btn--positive"
        @click="submit"
        :disabled="!isValid || loading"
        :class="{ loading: loading }"
        v-text="(lead.customer_verified) ? 'Rediger' : 'Bekræft'"
      >
      </button>
      <p class="error" v-if="error">
        Der skete en fejl da dine data skulle valideres. Tjek du har udfyldt
        alle felter korrekt...
      </p>
    </div>
  </modal>
</template>

<script>
import InputField from "./../input-field/input-field.vue";
import LabelSelect from "./../input-field/label-select.vue";
import DataStore from "./../../services/datastore";
import Modal from "./../modal/base-modal.vue";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      intLead: {
        customer_name: "",
        customer_address: "",
        customer_postal: "",
        customer_town: "",
        customer_phone: "",
        customer_email: "",
        customer_is_company: false
      },
      customerTypes: ["Privat person", "Virksomhed"],
      loading: false,
      error: false,
      errorFields: [],
    };
  },
  mounted() {
    this.intLead = Object.assign({}, this.intLead, this.lead, {
      customer_phone: (this.lead.customer_phone) ? this.lead.customer_phone.toString() : '',
      customer_postal: (this.lead.customer_postal) ? this.lead.customer_postal.toString() : '',
      phone: (this.lead.phone) ? this.lead.phone.toString() : ''
    });
    
    this.fillMissingData();
  },
  methods: {
    fillMissingData() {
      if (!this.intLead.customer_name) {
        this.intLead.customer_name = this.lead.name;
      }

      if (!this.intLead.customer_phone) {
        this.intLead.customer_phone = this.lead.phone;
      }

      if (!this.intLead.customer_email) {
        this.intLead.customer_email = this.lead.email;
      }
    },
    getTownByZip(value) {
      if (value && value.length === 4 && parseInt(value)) {
        DataStore.getCityFromZipcode(value).then((res) => {
          if (res.data[0]) {
            this.$set(this.intLead, "customer_town", res.data[0].navn);
          }
        });
      }
    },
    submit() {
      if (this.loading) return;

      this.loading = true;
      this.errorFields = [];

      DataStore.verifyLeadCustomerData(this.lead.hash, this.intLead)
        .then((res) => {
          this.loading = false;
          this.$emit("input", Object.assign({}, this.lead, res.data));
          this.$nextTick(() => {
            this.$router.push({ name: "lead-view", view: null, slide: null });
          });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.error = true;

          if (err.response.data && err.response.data.inputs) {
            this.errorFields = err.response.data.inputs;
          }
        });
    },
    onClose() {
      this.$router.push({ name: "lead-view", view: null, slide: null });
    },
  },
  computed: {
    isValid() {
      return this.intLead.customer_name &&
        this.intLead.customer_name.length &&
        this.intLead.customer_address &&
        this.intLead.customer_address.length &&
        this.intLead.customer_postal &&
        this.intLead.customer_postal.length > 0 &&
        this.intLead.customer_town &&
        this.intLead.customer_town.length > 0 &&
        this.intLead.customer_email &&
        this.intLead.customer_email.length > 0 &&
        this.intLead.customer_phone &&
        this.intLead.customer_phone.length > 0
        ? true
        : false;
    },
  },
  components: {
    InputField,
    Modal,
    LabelSelect,
  },
};
</script>

