var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "profile-login" }, [
      _c("div", { staticClass: "profile-login__wrapper" }, [
        _c("h2", { staticClass: "profile-login__header" }, [
          _vm._v("Hov du har ramt et lukket eller gammelt link")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "profile-login__text" }, [
          _vm._v(
            "Forsøger du at få adgang til NEMBLIK skal du bruge det seneste link vi har sendt til din e-mail og telefon."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "profile-login__contact-text" }, [
          _vm._v(
            "Har du ikke modtaget andre links end dette, skal du straks tage fat i os på "
          ),
          _c("a", { attrs: { href: "tel:+4570204161" } }, [
            _vm._v("70 20 41 61")
          ]),
          _vm._v(", så hjælper vi dig videre ;)")
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02e0f058", { render: render, staticRenderFns: staticRenderFns })
  }
}