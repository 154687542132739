<template>
  <slide
    :title="title"
    :orderHelp="helpText"
    class="order-flow__wrapper--6"
    @next="submit()"
    :validated="isValid"
  >
    <template slot="content">
      <div class="order-tiles order-tiles--selection">
        <div
          class="order-tiles__item"
          :class="[
            'order-tiles__item--0',
            { 'order-tiles__item--selected': intSelectedDateRange === 'year' },
          ]"
          @click="intSelectedDateRange = 'year'"
        >
          <span class="order-tiles__item__heading">Kalenderåret</span>
        </div>
        <div
          class="order-tiles__item"
          :class="[
            'order-tiles__item--1',
            {
              'order-tiles__item--selected': intSelectedDateRange === 'custom',
            },
          ]"
          @click="intSelectedDateRange = 'custom'"
        >
          <span class="order-tiles__item__heading">Forskudt</span>
        </div>
      </div>

      <div
        class="order-flow__content__expansion"
        :class="{
          'order-flow__content__expansion--open':
            intSelectedDateRange == 'custom',
        }"
      >
        <div class="input-fields">
          <date-picker
            name="Fra"
            @pickdate="setFromDate"
            :is-ready="true"
            :default-date="startDate"
            :select-past="true"
            :select-weekends="true"
            :disbaleAfterDate="intFinancialYear.end ? intFinancialYear.end : ''"
            format="DD/MM"
          ></date-picker>
          <date-picker
            name="Til"
            @pickdate="setToDate"
            :is-ready="true"
            :default-date="endDate"
            :select-past="true"
            :disbaleBeforeDate="intFinancialYear.start ? intFinancialYear.start : ''"
            :select-weekends="true"
            format="DD/MM"
          ></date-picker>
        </div>
      </div>
    </template>
  </slide>
</template>

<script>
import Slide from "./slide.vue";
import Moment from "moment";
import DatePicker from "./../../datepicker/datepicker.vue";

export default {
  props: {
    companyName: { type: String, default: "virksomhed" },
    selectedDateRange: {
      type: String,
      default() {
        return "";
      },
    },
    financialYear: {
      type: Object,
      default() {
        return { end: "", start: "" };
      },
    },
  },
  data() {
    return {
      intSelectedDateRange: "",
      intFinancialYear: {end: '', start: ''},
      helpText: {
        heading: 'Information',
        paragraphs: [
            'De fleste selskabers regnskabsår følger kalenderåret, 01.01-31.12, hvor det første regnskabsår løber fra stiftelsen til 31. december. Du kan dog vælge et forskudt regnskabsår, hvis du ønsker - f.eks. 01.07-30.06.',
            'Selskabets første regnskabsår kan forlænges op til 18 måneder fra stiftelsesdatoen. Stifter du selskabet i første halvdel af kalenderåret, kan du forlænge selskabets første regnskabsår frem til 30. juni året efter, hvis du har valgt en forskudt regnskabsperiode. Omvendt, hvis du stifter selskabet i sidste halvdel af året, kan du få et langt første regnskabsår ved at lade regnskabsperioden følge kalenderåret – da man så først har regnskabsafslutning 31. december året efter stiftelsen.'
        ]
      }
    };
  },
  mounted() {
    this.intSelectedDateRange = this.selectedDateRange;
    this.intFinancialYear = Object.assign({}, this.financialYear);
  },
  watch: {
    selectedDateRange() {
      this.intSelectedDateRange = this.selectedDateRange;
    },
    financialYear() {
      this.intFinancialYear = Object.assign({}, this.financialYear);
    },
  },
  methods: {
    setFromDate(date) {
      this.$set(this.intFinancialYear, 'start', Moment(date, 'DD/MM').format("YYYY-MM-DD"));
    },
    setToDate(date) {
      this.$set(this.intFinancialYear, 'end', Moment(date, 'DD/MM').format("YYYY-MM-DD"));
    },
    submit() {
      if (this.intSelectedDateRange == "year") {
        this.setFromDate('1/1');
        this.setToDate('31/12');
      }

      this.$emit("submit", Object.assign({}, {
          financialYear: this.intFinancialYear,
          selectedDateRange: this.intSelectedDateRange,
      }));
    },
  },
  computed: {
    startDate() {
      return this.intFinancialYear.start
        ? Moment(this.intFinancialYear.start, "YYYY-MM-DD").format('DD/MM')
        : "";
    },
    endDate() {
      return this.intFinancialYear.end
        ? Moment(this.intFinancialYear.end, "YYYY-MM-DD").format('DD/MM')
        : "";
    },
    title() {
      return "Hvilken regnskabsperiode følger <br/>" + this.companyName;
    },
    isValid() {
        if(this.intSelectedDateRange == 'year') {
            return true;
        } else {
            return this.intFinancialYear.start && this.intFinancialYear.end ? true : false;
        }
    },
  },
  components: {
    Slide,
    DatePicker,
  },
};
</script>