var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-login profile-login--customer",
      class: { loading: _vm.loading }
    },
    [
      _c("div", { staticClass: "profile-login__wrapper" }, [
        _c(
          "div",
          [
            _vm.view == "customerreset"
              ? [
                  _c("h2", { staticClass: "profile-login__header" }, [
                    _vm._v("Vi har nu sendt et link")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "profile-login__text" }, [
                    _vm._v(
                      "\n          Hvis e-mailen '" +
                        _vm._s(_vm.email) +
                        "' genkendes i vores system, vil du modtage\n          en e-mail med et nyt link. "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n          Tjek din e-mail og følg linket. "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          Modtager du ingen e-mail, skal du henvende dig til vores support på\n          info@nemadvokat.dk\n        "
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.view == "resetcustomer"
              ? [
                  _c("h2", { staticClass: "profile-login__header" }, [
                    _vm._v("Velkommen tilbage !")
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("input-field", {
                    attrs: {
                      name: "E-mail",
                      "input-type": "email",
                      error:
                        _vm.fieldErrors.indexOf("email") > -1
                          ? "Skal udfyldes"
                          : ""
                    },
                    on: { enter: _vm.resetCustomer },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm._v(" "),
                  _vm.error
                    ? _c("p", {
                        staticClass: "error",
                        domProps: { innerHTML: _vm._s(_vm.error) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: !_vm.email || _vm.loading },
                      on: { click: _vm.resetCustomer }
                    },
                    [_vm._v("\n          Gensend min adgang\n        ")]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _c("strong", [
        _vm._v(
          "Du er få klik fra dit nemblik og rabatter på fastpris produkter"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v(
        "\n          Indtast nedenfor den e-mailadresse du anvendte ved oprettelsen af\n          din sag hos os. Genkender vi din e-mail i vores system, sender vi et\n          nyt link til "
      ),
      _c("b", [_vm._v("NEMBLIK™")]),
      _vm._v(". "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n          Når du klikker på linket, sender vi en SMS til den mobiltelefon vi\n          har registeret på dit kundekort.\n        "
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ee418fe8", { render: render, staticRenderFns: staticRenderFns })
  }
}