<template>
  <p>Vent venligst mens du vidrestilles...</p>
</template>

<script>
export default {
  mounted() {
    if (this.$route.query.parttype) {
      this.$store.commit("parttype", this.$route.query.parttype);
    } else {
      this.$router.replace({
        path: "/"
      });
    }

    if (this.$route.query.parthash) {
      switch (this.$store.state.parttype) {
        case "customer":
          this.$router.replace({
            name: "profile",
            params: {
              hash: this.$route.query.parthash
            }
          });
          break;
        case "part":
          this.$store
            .dispatch("getpart", {
              hash: this.$route.query.parthash
            })
            .then(res => {
              this.$router.replace({
                name: 'part-profile',
                params: {
                  hash: this.$route.query.parthash
                }
              });
            });
            break;
        default:
          this.$router.replace({
            path: "/"
          });
          break;
      }
    }
  }
};
</script>