<template>
  <div class="login-selector">
    <h2 class="login-selector__heading">Vælg din brugertype</h2>
    <p class="login-selector__text">
      Er du kunde hos os eller tilknyttet en sag som part, vælg nedenfor.
    </p>
    <div class="login-tile-grid">
      <router-link :to="{ name: 'customer-reset-login' }" class="login-tile">
        <img src="/assets/img/icons/icon-client-login.svg" />
        <span class="login-tile__text">Kunde</span>
      </router-link>
      <router-link :to="{ name: 'part-login' }" class="login-tile">
        <img src="/assets/img/icons/icon-login-part.svg" />
        <span class="login-tile__text">Part</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>