<template>
  <div class="kyc-wrapper" :class="{ loading }">
    <h2 v-text="title"></h2>
    <div
      class="id-item"
      :class="{
        'id-item--checked': item.selected,
        'id-item--disabled': loading,
      }"
      v-for="item in idItemList"
      :key="item.type"
      @click="selectType(item)"
    >
      <img
        :src="item.img"
        :alt="'Ikon af ' + item.name"
        class="id-item__type-icon"
      />
      <div class="id-item__content">
        <span class="id-item__text" v-text="item.name"></span>
        <span v-if="item.description && !finishedValidation" class="id-item__small-text" v-text="item.description"></span>
      </div>
      <div class="id-item__validation-icon">
        <span class="id-item__validation-icon__line"></span>
      </div>
    </div>
    <button
      v-if="finishedValidation"
      @click="$emit('submit', {})"
      class="btn"
      v-text="translations.finish_btn"
    ></button>
    <p class="error" v-text="error" v-if="error"></p>
  </div>
</template>

<script>
import DataStore from "./../../services/datastore";

const SHOW_TYPES_FIRST = [2, 3, 4];
const SHOW_TYPES_SECOUND = [1, 2, 3, 4];

const ICON_MAPPING = {
  1: "/assets/img/icons/kyc-id-04.svg",
  2: "/assets/img/icons/kyc-id-01.svg",
  3: "/assets/img/icons/kyc-id-02.svg",
  4: "/assets/img/icons/kyc-id-03.svg",
};

export default {
  props: {
    uid: {
      type: String,
      default: "",
    },
    validationTypes: {
      type: Object|Array,
      default() {
        return {};
      },
    },
    selectedTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    translations: {
      type: Object,
      default() {
        return {};
      },
    },
    orderHash: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  methods: {
    selectType({ type, selected }) {
      if (this.loading || selected) return;

      this.error = "";
      this.loading = true;
      return DataStore.KYCAddValidationMethod(this.uid, { type, current_order_hash: this.orderHash })
        .then(({ data }) => {
          this.$emit("submit", { validationMethod: data });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.error =
            "Der skete en fejl og valideringen kunne ikke oprettes! Prøv igen";
        });
    },
  },
  computed: {
    title() {
      if (this.finishedValidation) {
        return this.translations.title_finish;
      }

      return this.selectedTypes.length < 1
        ? this.translations.title_first_source
        : this.translations.title_secound_source;
    },
    finishedValidation() {
      return this.selectedTypes.length >= 2;
    },
    showTypes() {
      if (this.finishedValidation) {
        return this.selectedTypes.map(({ validation_id }) =>
          parseInt(validation_id.type)
        );
      } else {
        return this.selectedTypes.length > 0 ? SHOW_TYPES_SECOUND : SHOW_TYPES_FIRST;
      }
    },
    idItemList() {
      return Object.keys(this.validationTypes)
        .filter((type) => this.showTypes.indexOf(parseInt(type)) > -1)
        .map((type) => {
          let selected =
            this.selectedTypes.filter(
              ({ validation_id }) =>
                parseInt(validation_id.type) == parseInt(type)
            ).length > 0
              ? true
              : false;

          return {
            name: this.translations.validationNames[type],
            description: this.translations.validationDescriptions[type] || '',
            type,
            img: ICON_MAPPING[type],
            selected,
          };
        });
    },
  },
};
</script>