<template>
  <modal
    :show="true"
    :title="title"
    :no-scroll="true"
    @close="$emit('close')"
    class="base-modal-smaller"
    :class="['base-model-type-' + depositOrder.type]"
  >
    <div class="deposit-modal" v-if="depositOrder.type == 6">
      <h2 class="deposit-modal__heading">
        Tinglysningsafgift – Fast & Variabel
      </h2>
      <p>Vi har nu beregnet den samlede tinglysningsafgift på din sag.</p>
      <p>
        Tinglysningsafgiften udgør
        <span v-price="depositOrder.price"></span>, hvilket bedes indbetalt på
        vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>Vi kan ikke gennemføre tinglysningen før beløbet er modtaget.</p>
      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Der skal i forbindelse med handlen betales to typer
          tinglysningsafgift. En fast afgift på kr. 1.850,00 (2023-tal) og en
          variabel afgift. Der gælder forskellige regler for hvordan den
          variable afgift beregnes.
        </p>
        <p>
          I almindelig fri handel udgør den variable afgift 0,6% af købesummen.
        </p>
        <p>
          Hvis ejerskiftet ikke er sket som almindelig fri handel, f.eks. ved
          familiehandel, skal der kun betales tinglysningsafgift af 85% af
          ejendomsværdien, hvis købesummen er mindre end dette beløb - ellers
          sker beregningen af købesummen.
        </p>
        <p>
          Ved handel af erhvervsejendomme og landbrugsejendomme beregnes den
          variable tinglysningsafgift af enten købesummen eller den offentlige
          ejendomsvurdering, hvad end der er højest af de to.
        </p>
        <p>
          Tinglysningsafgifterne er i alle tilfælde først endelige, når
          handelsværdien på ejendommen er fastsat.
        </p>
        <p>
          Tinglysningsafgiftens størrelse er reguleret af
          tinglysningsafgiftsloven og tilfalder Staten. Vi tilsikrer, at
          tinglysningsafgiften indbetales til Staten, iht. reglerne om
          anvendelse af storkundeordningen.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 3">
      <h2 class="deposit-modal__heading">Tinglysningsafgift – Fast</h2>
      <p>
        I forbindelse med din sag, skal der betales en tinglysningsafgift på
        <span v-price="depositOrder.price"></span>, hvilket bedes indbetalt på
        vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>Vi kan ikke gennemføre tinglysningen før beløbet er modtaget.</p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Tinglysningsafgiftens størrelse er reguleret af
          tinglysningsafgiftsloven og tilfalder Staten. Vi tilsikrer, at
          tinglysningsafgiften indbetales til Staten, iht. reglerne om
          anvendelse af storkundeordningen.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 4">
      <h2 class="deposit-modal__heading">Varemærkegebyr</h2>
      <p>
        I forbindelse med din sag, skal der betales et varemærkegebyr på
        <span v-price="depositOrder.price"></span>, hvilket bedes indbetalt på
        vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>Vi kan ikke gennemføre registreringen før beløbet er modtaget.</p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>Varemærkegebyrets størrelse afhænger af 2 forhold:</p>
        <ol>
          <li>Det ønskede antal registreringsklasser/kategorier, og</li>
          <li>Registreringens geografiske udstrækning.</li>
        </ol>
        <p>
          Varemærkegebyret er i alle tilfælde først endelig fastlagt, når
          registreringen er gennemført.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 5">
      <h2 class="deposit-modal__heading">Retsafgift</h2>
      <p>
        I forbindelse med din sag, skal der betales en retsafgift på
        <span v-price="depositOrder.price"></span>, hvilket bedes indbetalt på
        vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Retsafgiftens størrelse fastsættes og indkræves af retterne, baseret
          på sagens type mv. Vi tilsikrer, at afgiften betales i
          overensstemmelse med deres retningslinjer herom.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 7">
      <h2 class="deposit-modal__heading">Selskabskapital</h2>
      <p>
        Som dokumentation for selskabskapitalen, bedes
        <span v-price="depositOrder.price"></span> indbetalt på vores
        klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>Vi kan ikke gennemføre registreringen før beløbet er modtaget.</p>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 8">
      <h2 class="deposit-modal__heading">Betaling af faktura</h2>
      <p>
        Vi har nu lavet en afregning på din sag. Du finder din faktura delt med
        dig i <router-link :to="{params: {modal: 'documents'}}">Dokumenter</router-link>
      </p>
      <p>
        Det udestående beløb på
        <span v-price="depositOrder.price"></span> bedes indsættes på vores
        driftskonto i Nykredit med reg.nr. 8117 og konto nr. 3707061.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>Fakturaen bedes betalt inden for 7 dage af fakturaens udstedelse.</p>
      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering
          herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en
          kvittering til din e-mail.
        </p>
        <p>
          Har du spørgsmål til den udstedte faktura, kan disse rettes til
          sagsbehandleren på din sag.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 2">
      <h2 class="deposit-modal__heading">Registreringsgebyr</h2>
      <p>
        I forbindelse med din sag, skal der betales et registreringsgebyr på
        <span v-price="depositOrder.price"></span>, hvilket bedes indbetalt på
        vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>
      <p>
        <b
          >NB: Fremgår det af vores Aftalebrev, at vi modregner vores honorar i
          den deponerede selskabskapital, skal du ikke indbetale beløbet.</b
        >
      </p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Registreringsgebyret opkræves og tilfalder Erhvervsstyrelsen, og vil
          blive betalt i overensstemmelse med Erhvervsstyrelsens retningslinjer
          herom.
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 1">
      <h2 class="deposit-modal__heading">Sikkerhed for honorar</h2>
      <p>
        Til sikkerhed for vores honorar bedes
        <span v-price="depositOrder.price"></span> indsættes på vores
        klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering
          herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en
          kvittering til din e-mail.
        </p>
        <p>
          Vi vil høfligt påminde om beløbet indtil deponering er modtaget.
          Krydser vores påmindelser din indbetaling, skal du naturligvis set
          bort fra disse.
        </p>
        <p>
          Se sagens fulde aftalebrev/ordrebekræftelse
          <a href="javascript:void(0)" @click="$emit('showagreementletter')"
            >her</a
          >
        </p>
      </div>
    </div>

    <div class="deposit-modal" v-if="depositOrder.type == 1.1">
      <h2 class="deposit-modal__heading">Sikkerhedsstillelse</h2>
      <p>
        Til sikkerhed for vores honorar bedes
        <span v-price="depositOrder.price"></span> indsættes på vores
        klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.
      </p>
      <p>Navngiv venligst overførslen {{ caseid }}</p>

      <div class="deposit-info">
        <h3 class="deposit-info__heading">Praktisk information</h3>
        <img src="/assets/img/icons/icon-info.svg" class="deposit-info__icon" />
        <p>
          Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering
          herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en
          kvittering til din e-mail.
        </p>
        <p>
          Vi vil høfligt påminde om beløbet indtil deponering er modtaget.
          Krydser vores påmindelser din indbetaling, skal du naturligvis set
          bort fra disse.
        </p>
        <p>
          Se sagens fulde aftalebrev/ordrebekræftelse
          <a href="javascript:void(0)" @click="$emit('showagreementletter')"
            >her</a
          >
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";

export default {
  props: {
    caseid: { type: String, default: "" },
    depositOrder: { type: Object, default: null },
  },
  computed: {
    title() {
      if(this.depositOrder && this.depositOrder.type == 8) {
        return this.depositOrder.typeText.replace('Udestående', 'Betaling');
      }

      return 'Deponering';
    }
  },
  components: {
    Modal,
  },
};
</script>
