<template>
  <div class="faq-item" :class="{ 'faq-item--open': unfold }">
    <div class="faq-item__header" @click="unfold = !unfold">
      <span v-text="title"></span>
      <div class="faq-item__arrow">
        <div class="faq-item__arrow__icon"></div>
      </div>
    </div>
    <div class="faq-item__body">
      <p v-for="(paragraph, key) in text" :key="key" v-html="paragraph"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, defualt: "" },
    text: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      unfold: false,
    };
  },
};
</script>