<template>
  <div
    class="order-item"
    :class="{
      'multiple-letters': order.multiple_letters, 
      'active': active,
      'new-messages': (newMessageCount > 0),
      'archived': orderState == 'archived'
      }"
    v-if="order.caseid"
    @click="showOrder"
  >
    <div class="order-item__icon">
      <img src="/assets/img/icons/icon-case.svg"/>
    </div>
    <div class="order-item__content">
      <span v-text="order.caseid" class="order-item__caseid"></span>
      <h2 class="order-item__title" v-text="order.key_field"></h2>
    </div>
    <span v-text="newMessageCount" v-if="newMessageCount" class="order-item__msg-counter"></span>
  </div>
</template>

<script>
import Moment from "moment";

export default {
  props: {
    order: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      moment: Moment
    };
  },
  methods: {
    showOrder() {
      let name = (this.$store.state.parttype == 'part') ? 'part-case' : 'case';
      let modal = (this.$store.state.parttype == 'part') ? 'messages' : null;
      this.$router.push({
        name,
        params: {
          orderhash: this.order.main_order_hash,
          modal
        }
      });
    }
  },
  computed: {
    orderState() {
      return (this.order.order_state) ? this.order.order_state : null;
    },
    active() {
      return this.$route.params.orderhash == this.order.main_order_hash;
    },
    newMessageCount() {
      return this.$store.state.parttype == 'part' ? this.order.part_message_count : this.order.new_messages_nem;
    },
    responsibleName() {
      return (this.order.responsible) ? this.order.responsible.fullname : '';
    }
  }
};
</script>
