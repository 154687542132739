var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-menu" },
    [
      _vm.lead.contact_state != 4 &&
      typeof _vm.lead.customer_name !== "undefined"
        ? _c(
            "a",
            {
              staticClass: "case-menu__item case-menu__item--tasks",
              class: {
                "case-menu__item--verified": _vm.lead.customer_verified
              },
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.fillCustomerData }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "case-menu__item__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 33.07 33.07"
                  }
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5px"
                    },
                    attrs: {
                      d:
                        "M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z"
                    }
                  }),
                  _c("path", {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      d:
                        "M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                    }
                  }),
                  _c("circle", {
                    staticStyle: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5px"
                    },
                    attrs: { cx: "16.53", cy: "13.56", r: "2.84" }
                  }),
                  _c("path", {
                    staticStyle: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5px"
                    },
                    attrs: {
                      d:
                        "M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z"
                    }
                  }),
                  _c("rect", {
                    staticStyle: { fill: "none" },
                    attrs: { width: "33.07", height: "33.07" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "case-menu__item__text" }, [
                _vm._v("Kundedata")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lead.contact_state != 4 && _vm.containsCaseData
        ? _c(
            "router-link",
            {
              staticClass: "case-menu__item",
              class: { "case-menu__item--verified": _vm.verifiedCaseData },
              attrs: { to: { name: "lead-view", params: { view: "products" } } }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "case-menu__item__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 33.07 33.07"
                  }
                },
                [
                  _c("path", {
                    staticStyle: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5px"
                    },
                    attrs: {
                      d:
                        "M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z"
                    }
                  }),
                  _c("rect", {
                    staticStyle: { fill: "none" },
                    attrs: { width: "33.07", height: "33.07" }
                  }),
                  _c("path", {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      d:
                        "M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                    }
                  }),
                  _c("path", {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      d:
                        "M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                    }
                  }),
                  _c("path", {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      d:
                        "M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                    }
                  }),
                  _c("circle", {
                    staticStyle: {
                      fill: "none",
                      stroke: "#fff",
                      "stroke-miterlimit": "10",
                      "stroke-width": "1.5px"
                    },
                    attrs: { cx: "16.53", cy: "4.38", r: "2.08" }
                  }),
                  _c("path", {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      d:
                        "M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "case-menu__item__text" }, [
                _vm._v("Sagsdata")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "case-menu__item",
          attrs: { to: { name: "lead-view", params: { view: "kommentar" } } }
        },
        [
          _c(
            "svg",
            {
              staticClass: "case-menu__item__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 33.07 33.07"
              }
            },
            [
              _c("rect", {
                staticStyle: { fill: "none" },
                attrs: { width: "33.07", height: "33.07" }
              }),
              _c("path", {
                staticStyle: {
                  fill: "none",
                  stroke: "#fff",
                  "stroke-linejoin": "round",
                  "stroke-width": "1.5px"
                },
                attrs: {
                  d:
                    "M29.16,9.72v13.62c0,1,0,1-1,1H4.91q-1,0-1-1V9.72c0-1,.07-1,1-1H28.16c1,0,1,0,1,1Z"
                }
              }),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d:
                    "M28.81,9.61c-.24-.33-.61-.48-.83-.32l-11.45,8.15L5.09,9.29c-.21-.16-.58-.02-.83,.32-.24,.33-.27,.74-.05,.9h.01l12.07,8.61c.07,.05,.16,.06,.25,.05,.09,0,.18,0,.25-.05l12.07-8.6h.01c.21-.17,.19-.57-.05-.9Z"
                }
              }),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d:
                    "M4.22,23.06h-.01c-.21,.17-.19,.57,.05,.9,.24,.33,.61,.48,.83,.32l7.95-5.66-1.29-.92-7.53,5.36Z"
                }
              }),
              _c("path", {
                staticStyle: { fill: "#fff" },
                attrs: {
                  d:
                    "M28.85,23.06l-7.53-5.36-1.29,.92,7.95,5.66c.21,.16,.58,.02,.83-.32,.24-.33,.27-.74,.05-.9h-.01Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "case-menu__item__text" }, [
            _vm._v("Send kommentar")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-333c1dcd", { render: render, staticRenderFns: staticRenderFns })
  }
}