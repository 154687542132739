<template>
  <div class="profile-login" :class="{loading}">
    <div class="profile-login__wrapper">
      <h2 class="profile-login__header profile-login__header--center" v-text="status"></h2>
      <p class="profile-login__text profile-login__text--center" v-if="success">
        Du blev successfuldt logget ud.
      </p>
      <p class="profile-login__text profile-login__text--center" v-if="error">
        Der skete en fejl og du blev ikke logget korrekt ud. Klik
        <a href="javascript:void(0)" @click="logOut">her</a> for at forsøge
        igen.
      </p>
    </div>
  </div>
</template>

<script>
import DataStore from "./../services/datastore";
export default {
  data() {
    return {
      loading: false,
      success: false,
      error: false,
    };
  },
  mounted() {
    this.logOut();
  },
  methods: {
    logOut() {
      if (this.loading) return;
      this.loading = true;
      DataStore.logout()
        .then((res) => {
          this.loading = false;
          if (res.data.status === "ok") {
            this.$store.commit("part", {});
            this.success = true;
          } else {
            this.error = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.error = true;
        });
    },
  },
  computed: {
    status() {
      if (this.loading) {
        return "Logger dig ud....";
      } else if (this.success) {
        return "Du er nu logget af!";
      } else if (this.error) {
        return "Der skete en fejl og du blev ikke logget af!";
      }
    },
  },
};
</script>