var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "base-modal-smaller",
      class: ["base-model-type-" + _vm.depositOrder.type],
      attrs: { show: true, title: _vm.title, "no-scroll": true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.depositOrder.type == 6
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("\n      Tinglysningsafgift – Fast & Variabel\n    ")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi har nu beregnet den samlede tinglysningsafgift på din sag."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Tinglysningsafgiften udgør\n      "),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                ", hvilket bedes indbetalt på\n      vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi kan ikke gennemføre tinglysningen før beløbet er modtaget."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Der skal i forbindelse med handlen betales to typer\n        tinglysningsafgift. En fast afgift på kr. 1.850,00 (2023-tal) og en\n        variabel afgift. Der gælder forskellige regler for hvordan den\n        variable afgift beregnes.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        I almindelig fri handel udgør den variable afgift 0,6% af købesummen.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Hvis ejerskiftet ikke er sket som almindelig fri handel, f.eks. ved\n        familiehandel, skal der kun betales tinglysningsafgift af 85% af\n        ejendomsværdien, hvis købesummen er mindre end dette beløb - ellers\n        sker beregningen af købesummen.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ved handel af erhvervsejendomme og landbrugsejendomme beregnes den\n        variable tinglysningsafgift af enten købesummen eller den offentlige\n        ejendomsvurdering, hvad end der er højest af de to.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Tinglysningsafgifterne er i alle tilfælde først endelige, når\n        handelsværdien på ejendommen er fastsat.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Tinglysningsafgiftens størrelse er reguleret af\n        tinglysningsafgiftsloven og tilfalder Staten. Vi tilsikrer, at\n        tinglysningsafgiften indbetales til Staten, iht. reglerne om\n        anvendelse af storkundeordningen.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 3
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Tinglysningsafgift – Fast")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      I forbindelse med din sag, skal der betales en tinglysningsafgift på\n      "
              ),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                ", hvilket bedes indbetalt på\n      vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi kan ikke gennemføre tinglysningen før beløbet er modtaget."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Tinglysningsafgiftens størrelse er reguleret af\n        tinglysningsafgiftsloven og tilfalder Staten. Vi tilsikrer, at\n        tinglysningsafgiften indbetales til Staten, iht. reglerne om\n        anvendelse af storkundeordningen.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 4
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Varemærkegebyr")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      I forbindelse med din sag, skal der betales et varemærkegebyr på\n      "
              ),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                ", hvilket bedes indbetalt på\n      vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi kan ikke gennemføre registreringen før beløbet er modtaget."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v("Varemærkegebyrets størrelse afhænger af 2 forhold:")
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "Det ønskede antal registreringsklasser/kategorier, og"
                  )
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Registreringens geografiske udstrækning.")])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Varemærkegebyret er i alle tilfælde først endelig fastlagt, når\n        registreringen er gennemført.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 5
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Retsafgift")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      I forbindelse med din sag, skal der betales en retsafgift på\n      "
              ),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                ", hvilket bedes indbetalt på\n      vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Retsafgiftens størrelse fastsættes og indkræves af retterne, baseret\n        på sagens type mv. Vi tilsikrer, at afgiften betales i\n        overensstemmelse med deres retningslinjer herom.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 7
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Selskabskapital")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      Som dokumentation for selskabskapitalen, bedes\n      "
              ),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                " indbetalt på vores\n      klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi kan ikke gennemføre registreringen før beløbet er modtaget."
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 8
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Betaling af faktura")
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _vm._v(
                  "\n      Vi har nu lavet en afregning på din sag. Du finder din faktura delt med\n      dig i "
                ),
                _c(
                  "router-link",
                  { attrs: { to: { params: { modal: "documents" } } } },
                  [_vm._v("Dokumenter")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Det udestående beløb på\n      "),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                " bedes indsættes på vores\n      driftskonto i Nykredit med reg.nr. 8117 og konto nr. 3707061.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Fakturaen bedes betalt inden for 7 dage af fakturaens udstedelse."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering\n        herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en\n        kvittering til din e-mail.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Har du spørgsmål til den udstedte faktura, kan disse rettes til\n        sagsbehandleren på din sag.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 2
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Registreringsgebyr")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      I forbindelse med din sag, skal der betales et registreringsgebyr på\n      "
              ),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                ", hvilket bedes indbetalt på\n      vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [
                _vm._v(
                  "NB: Fremgår det af vores Aftalebrev, at vi modregner vores honorar i\n        den deponerede selskabskapital, skal du ikke indbetale beløbet."
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Registreringsgebyret opkræves og tilfalder Erhvervsstyrelsen, og vil\n        blive betalt i overensstemmelse med Erhvervsstyrelsens retningslinjer\n        herom.\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 1
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Sikkerhed for honorar")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Til sikkerhed for vores honorar bedes\n      "),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                " indsættes på vores\n      klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering\n        herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en\n        kvittering til din e-mail.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Vi vil høfligt påminde om beløbet indtil deponering er modtaget.\n        Krydser vores påmindelser din indbetaling, skal du naturligvis set\n        bort fra disse.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Se sagens fulde aftalebrev/ordrebekræftelse\n        "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("showagreementletter")
                      }
                    }
                  },
                  [_vm._v("her")]
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.depositOrder.type == 1.1
        ? _c("div", { staticClass: "deposit-modal" }, [
            _c("h2", { staticClass: "deposit-modal__heading" }, [
              _vm._v("Sikkerhedsstillelse")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Til sikkerhed for vores honorar bedes\n      "),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.depositOrder.price,
                    expression: "depositOrder.price"
                  }
                ]
              }),
              _vm._v(
                " indsættes på vores\n      klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Navngiv venligst overførslen " + _vm._s(_vm.caseid))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "deposit-info" }, [
              _c("h3", { staticClass: "deposit-info__heading" }, [
                _vm._v("Praktisk information")
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "deposit-info__icon",
                attrs: { src: "/assets/img/icons/icon-info.svg" }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering\n        herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en\n        kvittering til din e-mail.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Vi vil høfligt påminde om beløbet indtil deponering er modtaget.\n        Krydser vores påmindelser din indbetaling, skal du naturligvis set\n        bort fra disse.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Se sagens fulde aftalebrev/ordrebekræftelse\n        "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("showagreementletter")
                      }
                    }
                  },
                  [_vm._v("her")]
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8ac693de", { render: render, staticRenderFns: staticRenderFns })
  }
}