var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--10",
      attrs: {
        title: "Selskabets data er modtaget, bolden er ved os!",
        validated: false
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("div", { staticClass: "order-flow__text-block" }, [
            _c("p", [
              _vm._v(
                "\n        Du kan fra dit overblik udfylde data på evt. andre produkter du har\n        bestilt. Du kan også skrive til os - hvis der er noget du har glemt,\n        eller booke et møde hvis du gerne vil tale med os.\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c("final-order", { attrs: { lead: _vm.lead, product: _vm.product } })
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "actions" }, [
        _c(
          "button",
          { staticClass: "btn btn--show", on: { click: _vm.submit } },
          [_vm._v("Luk")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-26209904", { render: render, staticRenderFns: staticRenderFns })
  }
}