var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.version == "1.0"
    ? _c("div", { staticClass: "agreement-letter" }, [
        _c("div", { staticClass: "agreement-letter__header" }, [
          _c(
            "div",
            {
              staticClass: "agreement-letter__ribbon",
              class: { "agreement-letter__ribbon--new": !_vm.isOld }
            },
            [
              !_vm.isOld ? _c("p", [_vm._v("Gældende")]) : _vm._e(),
              _vm._v(" "),
              _vm.isOld ? _c("p", [_vm._v("Udløbet")]) : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "agreement-letter__client-info" }, [
            _c("span", {
              domProps: { textContent: _vm._s(_vm.letterData.fullname) }
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.letterData.address) }
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.letterData.postal) +
                  " " +
                  _vm._s(_vm.letterData.city)
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "agreement-letter__agreement-info" }, [
            _c("span", [_vm._v(_vm._s(_vm.letterData.date))]),
            _vm._v(" "),
            _c("span", [_vm._v("Sagsnr.: " + _vm._s(_vm.letterData.caseid))])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "agreement-letter__body" },
          [
            _c("h2", [_vm._v("Kære " + _vm._s(_vm.letterData.fullname))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Vi bekræfter herved vores assistance i forbindelse med " +
                  _vm._s(_vm.letterData.producttxt) +
                  _vm._s(_vm.addonsStr) +
                  "."
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "agreement-letter__grid" },
              _vm._l(_vm.letterData.contract_lines, function(line) {
                return line
                  ? _c("div", { staticClass: "agreement-checkbox" }, [
                      _c("span", { staticClass: "agreement-checkbox__check" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "agreement-checkbox__text" }, [
                        _vm._v(_vm._s(line))
                      ])
                    ])
                  : _vm._e()
              }),
              0
            ),
            _vm._v(" "),
            _vm.letterData.notice
              ? _c("p", {
                  domProps: { textContent: _vm._s(_vm.letterData.notice) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.customerDepartmentTxt) }
              }),
              _vm._v(
                " er underlagt hvidvaskingslovens regler omkring identifikation af kundeforhold. Du skal som ny kunde CPR-validere dig via denne grønne knap på din sagsoversigt. Dette skal kun ske én gang."
              )
            ]),
            _vm._v(" "),
            _vm.letterData.responsible
              ? _c("p", [
                  _vm._v(
                    "Din sagsbehandling vil blive varetaget af " +
                      _vm._s(_vm.letterData.responsible) +
                      " "
                  ),
                  _vm.letterData.assistant
                    ? _c("span", [
                        _vm._v(" og " + _vm._s(_vm.letterData.assistant))
                      ])
                    : _vm._e(),
                  _vm._v(".")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.price_method > 0
              ? _c("h3", [_vm._v("Pris")])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.price_method == 1
              ? _c(
                  "p",
                  [
                    _vm._v(
                      "Vores honorar for denne opgave udgør en fast aftalt pris på "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "price",
                          rawName: "v-price",
                          value: _vm.letterData.price,
                          expression: "letterData.price"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c("b", [_vm._v("inkl. moms.")]),
                    _vm._v(" "),
                    _vm.letterData.discountPercent > 0
                      ? _c("span", [
                          _vm._v(
                            "Rabat " +
                              _vm._s(_vm.letterData.discountPercent) +
                              "%."
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" Moms udgør heraf "),
                    _c("span", {
                      directives: [
                        {
                          name: "price",
                          rawName: "v-price",
                          value: _vm.vat,
                          expression: "vat"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _vm.letterData.other_case
                      ? [_vm._v("Afregning sker på anden sag.")]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.letterData.against_capital
                      ? [
                          _vm._v(
                            "Vores honorar og gebyr til Erhvervsstyrelsen modregnes i den indbetalte selskabskapital."
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.price_method == 2
              ? _c("p", [
                  _vm._v(
                    "\n            Vores honorar for denne opgave afregnes som aftalt efter medgået tid. Prisen udgør kr. 1.250,00 - 3.750,00 "
                  ),
                  _c("b", [_vm._v("inkl. moms")]),
                  _vm._v(
                    " i timen. Enhver opgave vil blive tilrettelagt således, at vi inddrager personale med lavere timetakster, til at bistå med arbejde, hvor det skønnes hensigtsmæssigt. Det er altid muligt, at få oplyst sagens løbende omkostninger.\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tax > 0
              ? _c("p", [
                  _vm._v("Afgifter/gebyrer udgør "),
                  _c("span", {
                    directives: [
                      {
                        name: "price",
                        rawName: "v-price",
                        value: _vm.letterData.tax,
                        expression: "letterData.tax"
                      }
                    ]
                  }),
                  _vm._v(", momsfrit.")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h3", [_vm._v("Deponering")]),
            _vm._v(" "),
            _vm.letterData.deposit_method === 1
              ? [
                  _c("p", [
                    _vm._v(
                      "Til sikkerhed for vores honorar og kendte omkostninger bedes "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "price",
                          rawName: "v-price",
                          value: _vm.letterData.deposit_price,
                          expression: "letterData.deposit_price"
                        }
                      ]
                    }),
                    _vm._v(
                      " indsættes på vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677. Navngiv venligst overførslen " +
                        _vm._s(_vm.letterData.caseid) +
                        "."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en kvittering til din e-mail."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Vi vil høfligt påminde om beløbet indtil deponering er modtaget. Krydser vores påmindelser din indbetaling, skal du naturligvis set bort fra disse."
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.deposit_method === 0
              ? _c("p", [
                  _vm._v(
                    "Efter aftale skal der ikke foretages deponering forud for sagens opstart."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tinglysning && _vm.letterData.tinglysning !== "none"
              ? _c("h3", [_vm._v("Tinglysning")])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tinglysning === "TL"
              ? [
                  _c("p", [
                    _vm._v(
                      "Der skal i forbindelse med handlen betales en fast tinglysningsafgift på kr. 1.750,00 til staten. Afgiften reguleres af tinglysningsafgiftsloven. Afgiften indkræves sammen med vores honorar. Dette er indsat under pris ovenfor."
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tinglysning === "TLPLUS"
              ? [
                  _c("p", [
                    _vm._v(
                      "Der skal i forbindelse med handlen betales to typer tinglysningsafgift. En fast afgift på kr. 1.750,00 og en variable afgift. Der gælder forskellige regler for hvordan den variable afgift beregnes."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "I almindelig fri handel udgør den variable afgift 0,6% af købesummen."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Hvis ejerskiftet ikke er sket som almindelig fri handel, f.eks. ved familiehandel, skal der kun betales tinglysningsafgift af 85% af ejendomsværdien, hvis købesummen er mindre end dette beløb - ellers sker beregningen af købesummen."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Ved handel af erhvervsejendomme og  landbrugsejendomme beregnes den variable tinglysningsafgift af enten købesummen eller den offentlige ejendomsvurdering, hvad end der er højest af de to."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Tinglysningsafgifterne er i alle tilfælde først endelige, når handelsværdien på ejendommen er fastsat. Afgifterne reguleres af tinglysningsafgiftsloven."
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tingopkrav && _vm.letterData.tingopkrav === "TLNEM"
              ? [
                  _c("p", [
                    _vm._v("Tinglysningsafgiften opkræves af "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.customerDepartmentTxt) }
                    }),
                    _vm._v(
                      ", senest en uge før skødets tinglysning. Afgiften opkræves særskilt pr. e-mail og i dit "
                    ),
                    _c("b", [_vm._v("NEM")]),
                    _vm._v("BLIK.")
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tingopkrav && _vm.letterData.tingopkrav === "TLMOD"
              ? [
                  _c("p", [
                    _vm._v(
                      "Tinglysningsafgiften opkræves i følge aftale, ikke af "
                    ),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.customerDepartmentTxt) }
                    }),
                    _vm._v(" i denne sag.")
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.tinglysning && _vm.letterData.tinglysning !== "none"
              ? [
                  _c("h3", [_vm._v("Forbehold ved debitorskifte")]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.customerDepartmentTxt) }
                    }),
                    _vm._v(
                      " tager forbehold for ekstra afgift, i det tilfælde, at der i forbindelse med handlen skal ske et ejerskifte af sikkerheden til ejerforeningen. Dette udløser en tinglysningsafgift til tinglysningsretten på kr. 1.750 pr. ekspedition."
                    )
                  ]),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Forbehold for bopælspligt")]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.customerDepartmentTxt) }
                    }),
                    _vm._v(
                      " tager forbehold for ekstra afgift, i det tilfælde, du ikke opfylder reglerne om bopælspligt. Dette kan navnlig være relevant, såfremt du har været udrejst fra Danmark, og/eller er indrejst til Danmark, og i den forbindelse har fået tildelt et CPR-nr. indenfor de seneste 5 år. I sådant tilfælde, skal dette oplyses, således, at vi i anmeldelsen kan afgive behørig erklæring omkring din tilladelse til at erhverve fast ejendom. Manglende erklæring kan medføre en ekstra tinglysningsafgift på kr. 1.750,00."
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.nemreg_price_data
              ? [
                  _c("h3", [_vm._v("Selskabskapital")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Selskabet stiftes med en kapital på "),
                    _c("span", {
                      directives: [
                        {
                          name: "price",
                          rawName: "v-price",
                          value: _vm.letterData.nemreg_price_data.capital,
                          expression: "letterData.nemreg_price_data.capital"
                        }
                      ]
                    }),
                    _vm._v(
                      " til kurs " +
                        _vm._s(_vm.letterData.nemreg_price_data.exchange_rate) +
                        ", hvilket svarer til et samlet indskud på "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "price",
                          rawName: "v-price",
                          value: _vm.letterData.nemreg_price_data.deposit,
                          expression: "letterData.nemreg_price_data.deposit"
                        }
                      ]
                    })
                  ]),
                  _vm._v(" "),
                  _vm.letterData.nemreg_price_data.type === "deponering"
                    ? _c("p", [
                        _vm._v(
                          "Dokumentation for selskabskapitalen sker ved deponering af det samlede indskud på "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.customerDepartmentTxt)
                          }
                        }),
                        _vm._v(
                          "S klientkonto. Se vejledning til indbetaling under afsnittet Deponering ovenfor."
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.letterData.nemreg_price_data.type === "rullende_kapital"
                    ? _c("p", [
                        _vm._v(
                          "Selskabskapitalen dokumenteres ved indskud af indestående kapital fra et holdingselskab på samme ordre. Dette kaldes også ”at rulle kapitalen”. "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.customerDepartmentTxt)
                          }
                        }),
                        _vm._v(
                          " tilsikrer, at kapitalen flyttes fra holdingselskabets sag til nærværende sag i forbindelse med stiftelsen."
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.letterData.nemreg_price_data.type === "vaerdier"
                    ? _c("p", [
                        _vm._v(
                          "Selskabskapitalen dokumenteres ved, at fremsende gyldig vurderingsberetning på de indskudte aktiver. Vurderingsberetningen skal være udarbejdet af en uvildig registreret revisor."
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.letterData.nemreg_price_data.type === "bank"
                    ? _c("p", [
                        _vm._v(
                          "Selskabskapitalen dokumenteres ved at fremsende en bankposteringsoversigt, der viser at selskabskapitalen inklusiv eventuelt tillæg af overkurs, indestår på en erhvervskonto tilhørende selskabet under stiftelse. Posteringsoversigten skal være stemplet og underskrevet af banken. Den indeståede kapital skal være tilstede på erhvervskontoen med registreringstidspunktet."
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.letterData.nemreg_price_data.type === "erklaering"
                    ? _c("p", [
                        _vm._v(
                          "Selskabskapitalen dokumenteres ved, at stifter(e) underskriver en af "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.customerDepartmentTxt)
                          }
                        }),
                        _vm._v(
                          " tilsendt egen-erklæring. Ved underskrift af erklæringen, indestår stifter for, at selskabskapitalen er tilstede ved stiftelsen."
                        )
                      ])
                    : _vm._e()
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.insurance
              ? _c("h3", [_vm._v("Forsikringsforbehold")])
              : _vm._e(),
            _vm._v(" "),
            _vm.letterData.insurance
              ? _c("p", [
                  _vm._v(
                    "Det skal afklares hvorvidt der retshjælp i jeres indboforsikring. Kan der opnås tilsagn fra dit forsikringsselskab til retshjælpsdækning, vil honorar for de indenretlige skridt, blive opkrævet hos dit forsikringsselskab. Udenretlige omkostninger der ikke er dækket af din retshjælpsforsikring opkræves direkte hos dig."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h3", [_vm._v("Generelle forretningsbetingelser")]),
            _vm._v(" "),
            _vm._m(0)
          ],
          2
        )
      ])
    : _vm.version === "2.0"
    ? _c("div", {
        ref: "agreementletter",
        staticClass: "agreement-letter",
        domProps: { innerHTML: _vm._s(_vm.letterData.letterstring) }
      })
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Der henvises i øvrigt til vores almindelige forretningsbetingelser, der findes "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.nemadvokat.dk/om-nemadvokat/forretningsbetingelser/",
            target: "_blank"
          }
        },
        [_vm._v("her")]
      ),
      _vm._v(".")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-891e33e2", { render: render, staticRenderFns: staticRenderFns })
  }
}