<template>
    <div class="profile-login" :class="{'loading': sendingAuth || validatingAuthCode || emailValidating}">
        <div class="profile-login__wrapper">
            <template v-if="emailNotValidated">
                <h2 class="profile-login__header">Din e-mail er endnu ikke blevet valideret</h2>
                <p class="profile-login__text">Din email er ikke valideret! Valider venligst din e-mail via det unikke link du har fået i din mail da du blev oprettet.</p>
                <p class="profile-login__contact-text"><a href="https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/" target="_blank" class="profile-login__contact-link">Kontakt os her</a> hvis du har sprøgsmål.</p>
            </template>

            <template v-if="emailAlreadyValidated">
                <h2 class="profile-login__header">Din validering er gennemført</h2>
                <p class="profile-login__text">Du er valideret til at kunne benytte <b>NEM</b>BLIK.</p>
                <p class="profile-login__text">Du har modtaget et link på sms, samt email. Det er dette link du skal bruge for at kunne tilgå dit <b>NEM</b>BLIK. Hver gang du klikker på linket vil du modtage en unik SMS-kode der giver dig adgang. Vi sender et link til dig hver gang der er nyt i din sag.</p>
                <p class="profile-login__text">Har du mistet linket kan du sende en mail til <a class="profile-login__contact-link" href="mailto:info@nemadvokat.dk">info@nemadvokat.dk</a> eller ringe på <a class="profile-login__contact-link" href="tel:70204161">70204161</a> og anmode om at få tilsendt dit link til NEMblik.</p>
            </template>

            <template v-if="!emailNotValidated && !emailAlreadyValidated">
                <template v-if="!showEnterCode">
                    <h2 class="profile-login__header profile-login__header--no-margin">Grib din telefon</h2>
                    <p class="profile-login__text">Klik på ‘Send kode’ nedenfor og vi sender en bekræftelseskode til det mobilnummer der er tilknyttet din sag. <br/> <br/> Koden virker i 30 minutter, hvorefter der skal bestilles en ny kode for login. </p>
                    <button class="btn" @click="sendAuthCode">Send kode</button>
                </template>

                <template v-else-if="!loggingIn">
                    <h2 class="profile-login__header profile-login__header--no-margin">Indtast SMS-kode</h2>
                    <p class="profile-login__text">Vi har sendt dig en SMS med en engangskode til dit NEMBLIK™. Koden er ved dig indenfor 30 sekunder. <br/> <br/> Indtast den i boksene nedenfor og tryk videre.</p>
                    <code-input
                        title=""
                        :fields="6"
                        @complete="onCodeCompletion"
                        @change="onCodeChange"
                    ></code-input>
                    
                    <p class="error" v-if="error" v-text="error"></p>
                    <p class="profile-login__contact-text">Har du ikke modtaget SMS kode? <a href="javascript:void" class="profile-login__contact-link" @click="sendAuthCode">Gensend ny kode</a> eller <a href="https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/" target="_blank" class="profile-login__contact-link">Kontakt os her.</a></p>
                </template>

                <template v-else>
                    <div class="loading"></div>
                    <h2 class="profile-login__header profile-login__header--center">Succes!</h2>
                </template>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Datastore from '../../services/datastore.js';
    import CodeInput from './../input-field/codeinput.vue';

    export default{
        data() {
            return {
                emailValidating: false,
                sendingAuth: false,
                showEnterAuthCode: false,
                validatingAuthCode: false,
                emailNotValidated: false,
                emailAlreadyValidated: false,
                error: null,
                authCode: '',
                showEnterCode: false,
                emailValidated: false,
                loggingIn: false
            }
        },
        mounted() {
            if(this.$route.query.validateemail) {
                this.validateEmail();
            }
        },
        methods: {
            onCodeCompletion(code) {
                this.authCode = code;
                this.validateAuthCode();
            },
            onCodeChange(code) {
                this.authCode = code;
            },
            validateEmail() {
                if(this.emailValidating)
                    return;
                
                this.emailValidating = true;
                this.error = null;                
                Datastore.customerValidate(this.customerHash, 'email_validate').then((res) => {
                    this.emailValidating = false;
                    if(res.data.hash) {
                        this.$router.replace({
                            path: '/profil/' + res.data.hash + '/login',
                            query: {}
                        });
                        this.emailValidated = true;
                    } else {
                        switch(res.data.code) {
                            case 'not_found':                            
                            case 'no_response':
                            case 'email_already_validated':
                                this.emailAlreadyValidated = true;
                                this.error = 'Din e-mail er allerede blevet valideret! Hvis det ikke var dig kontakt venligst NEMAdvokat.';
                                return;
                            default:
                                this.error = 'Der skete en fejl under valideringen og din mail blev ikke valideret!'
                                return;
                        }
                    }
                }).catch((error) => {
                    console.error(error);                    
                    this.emailValidating = false;
                    this.error = 'Der skeete en fejl med forbindelsen - tjek at du har net forbindelsen!';
                })
            },
            sendAuthCode() {
                if(this.sendingAuth)
                    return;
                
                this.error = null;
                this.sendingAuth = true;
                this.showEnterCode = true;
                Datastore.customerValidate(this.customerHash, 'phone_auth').then((res) => {
                    this.sendingAuth = false;
                    if(res.data.status === 'ok') {
                        this.showEnterAuthCode = true;
                    } else {
                        switch(res.data.code) {
                            case 'not_found':
                            case 'no_response':
                                this.$router.replace('/');
                                return;
                            case 'customer_email_not_valid':
                                this.emailNotValidated = true;
                                this.error = 'Din email er ikke valideret! Valider venligst din e-mail via det unikke link du har fået i din mail da du blev oprettet';
                                return;
                            default:
                                this.error = 'Der skete desværre en fejl og en kode blev ikke sendt! Prøv igen eller kontakt venligst NEMAdvokat'
                                return;
                        }
                    }
                }).catch((error) => {
                    console.error(error);
                    this.sendingAuth = false;
                    this.error = 'Der skeete en fejl under forbindelsen - tjek at du har net forbindelse!';
                });
            },
            validateAuthCode() {
                if(this.validatingAuthCode)
                    return;
                
                this.error = null;
                this.validatingAuthCode = true;
                Datastore.customerValidate(this.customerHash, 'phone_validate', {code: this.authCode}).then((res) => {
                    this.validatingAuthCode = false;
                    if(res.data.status === 'ok') {
                        this.loggingIn = true;
                        
                        setTimeout(() => {
                            if(this.orderHash) {
                                    this.$router.push({
                                        name: 'case',
                                        params: {
                                            hash: this.customerHash,
                                            orderhash: this.orderHash
                                        }
                                    });
                                } else if(this.emailValidated) { //If user just has validated e-mail we welcome them
                                    this.$router.push({
                                        name: 'welcome',
                                        params: {
                                            hash: this.customerHash
                                        }
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'profile',
                                        params: {
                                            hash: this.customerHash
                                        }
                                    });
                                }
                        }, 1000);
                    } else {
                        switch(res.data.code) {
                            case 'not_found':                            
                            case 'no_response':
                                this.$router.replace('/');
                                return;
                            case 'email_not_validated':
                                this.error = 'Din email er ikke valideret! Valider venligst din e-mail via det unikke link du har fået i din mail da du blev oprettet';
                                return;
                            case 'wrong_code':
                                this.error = 'Du har ikke indtastet den korrekte kode!'
                                return;
                            default:
                                this.error = 'Der skete en fejl under valideringen! Prøv at gensend din kode eller kontakt NEMAdvokat'
                                return;
                        }
                    }
                }).catch((error) => {
                    this.validatingAuthCode = false;
                    this.error = 'Der skeete en fejl under forbindelsen - tjek at du har net forbindelse!';
                });
            }
        },
        computed: {
            orderHash() {
                return (this.$route.query.orderhash) ? this.$route.query.orderhash.replace(/\//, '') : null;
            },
            customerHash() {
              return this.$route.params.hash;
            },
            validAuthCode() {
                return this.authCode.toString().length == 6;
            }
        },
        components: {
            CodeInput
        }
    }
</script>