var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { loading: _vm.loading } },
    [
      _vm.order
        ? [
            _c("case-menu", { attrs: { order: _vm.order } }),
            _vm._v(" "),
            !_vm.shownModalName && _vm.partType == "customer"
              ? _c("overview", {
                  attrs: { order: _vm.order, customer: _vm.customer }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.shownModalName == "messages"
              ? _c("messages", {
                  attrs: {
                    hash: _vm.orderHash,
                    "part-hash": _vm.partHash,
                    customer: _vm.customer,
                    order: _vm.order
                  },
                  on: { messageread: _vm.onMessageRead }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.shownModalName == "documents"
              ? _c("documents", {
                  attrs: {
                    hash: _vm.orderHash,
                    "part-hash": _vm.partHash,
                    "order-active": _vm.orderActive
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.shownModalName == "economy"
              ? _c("economy", { attrs: { order: _vm.order } })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7fc41e7d", { render: render, staticRenderFns: staticRenderFns })
  }
}