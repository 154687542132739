<template>
  <div class="create-profile-view" :class="{ loading: loading }">
    <div class="lead-section">
      <div v-if="!creatingOrder && !lead" class="main-content__wrapper">
        <div class="lead-header lead-header--two-steps">
          <div class="lead-header__wrapper">
            <h2 class="lead-header__heading" v-html="pageHeader"></h2>
            <p class="lead-header__sub-heading" v-html="pageSubHeading"></p>
          </div>
        </div>
        <div class="lead-form">
          <div class="lead-form__fields">
            <input-field
              name="Fulde navn"
              @input="
                (val) => {
                  this.name = val;
                }
              "
              :value="name"
              :error="nameError"
            ></input-field>
            <input-field
              name="Telefon"
              @input="
                (val) => {
                  this.phone = val;
                }
              "
              :value="phone"
              input-type="tel"
              :error="phoneError"
              mask="99999999"
            ></input-field>
            <input-field
              name="E-mail"
              @input="
                (val) => {
                  this.email = val;
                }
              "
              :value="email"
              input-type="email"
              :error="emailError"
            ></input-field>
            <p
              class="lead-form__paragraph"
              v-for="(paragraph, key) in pageParagraphs"
              :key="key"
              v-html="paragraph"
            ></p>
            <p class="error" v-show="error">
              Der skete en fejl under oprettelsen.. Tjek du har udfyldt alle
              felter korrekt...
            </p>
          </div>
          <a
            href="javascript:void(0)"
            class="btn btn--positive"
            @click="createNewCase"
            :disabled="loading"
            :class="{ loading: loading }"
            >Videre</a
          >
          <a :href="policyLink" class="datapolicy-link" target="_blank"
            >Se hvordan vi håndterer dine data</a
          >
        </div>
      </div>

      <template v-if="creatingOrder || lead">
        <div
          class="main-content__wrapper"
          :class="{ 'creating-animation--loading': creatingOrder }"
        >
          <div class="lead-header lead-header--two-steps">
            <div class="lead-header__wrapper">
              <h2 class="lead-header__heading" v-if="lead && !creatingOrder">
                Så er vi klar
              </h2>
              <h2 class="lead-header__heading" v-if="creatingOrder">
                Hæng på mens vi samler et overblik til dig...
              </h2>
              <img
                src="../../img/rotate-loading.svg"
                class="lead-header__loading"
                v-if="creatingOrder"
              />
              <img
                src="../../img/icons/icon-check.svg"
                class="lead-header__ready"
                v-if="lead && !creatingOrder"
              />
            </div>
            <div class="lead-form">
              <router-link
                v-if="lead && !creatingOrder"
                class="btn btn--positive"
                :to="{
                  name: 'lead-view',
                  params: {
                    hash: this.lead.hash,
                  },
                }"
                replace
                >Gå videre</router-link
              >
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="trust-block">
          <div class="trust-block__content">
            <div class="trust-block__content__text">
              <h2 v-text="pageTrustHeader"></h2>
              <p v-html="pageTrustContent"></p>
            </div>
            <div class="trust-block__content__image">
              <img ref="campaignImage" :src="campaignImageSource" />
            </div>
          </div>
        </div>

        <div class="nem-footer">
          <div class="nem-footer__content">
            <a
              href="http://www.advokatnoeglen.dk/sog.aspx?s=1&t=0&firm=Nemadvokat"
              class="nem-footer__link"
            >
              <img
                src="../../img/advokatsamfundet-logo.png"
                alt="Advokatsamfundet"
              />
            </a>
            <a
              href="http://www.advokatnoeglen.dk/sog.aspx?s=1&t=0&firm=Nemadvokat"
              class="nem-footer__link"
            >
              <img
                src="../../img/borsen_gazelle-logo.png"
                alt="Børsen Gazelle"
              />
            </a>
            <a
              href="https://dk.trustpilot.com/review/nemadvokat.dk?utm_medium=trustboxes&utm_source=Mini"
              class="nem-footer__link"
            >
              <img
                src="../../img/trustpilot-review-logo.png"
                alt="Trustpilot"
              />
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script type="text/javascript">
import IntroHeader from "../intro-header/intro-header-text.vue";
import InputField from "../input-field/input-field.vue";
import DataStore from "../../services/datastore";

const SuportTeamImg = require("./../../img/support-team.png");

export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      contact_time: null,
      type: "koberpakken",
      loading: false,
      errors: [],
      error: false,
      step: 0,
      selectTime: false,
      campaign: null,
      campaignContent: [],
      campaignImageSource: SuportTeamImg,
      products: [],
      productLoadError: false,
      lead: null,
      creatingOrder: false,
    };
  },
  mounted() {
    this.addFbCode();
    this.loadData();
  },
  watch: {
    "$route.params.product"() {
      this.loadData();
    },
  },
  methods: {
    addFbCode() {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "813216702148323");
      fbq("track", "PageView");
    },
    loadData() {
      window.messageBus.connectIntercom();
      if (this.$route.params.source && this.$route.params.campaign) {
        this.loadCampaign();
      } else {
        this.loadProducts();
      }
    },
    loadCampaign() {
      this.loading = true;

      DataStore.campaignProduct(
        this.$route.params.source,
        this.$route.params.campaign,
        this.callmePage ? 2 : 1
      )
        .then((resp) => {
          this.loading = false;
          this.campaign = resp.data;
          this.loadCampaignContent();
        })
        .catch((error) => {
          return (window.location = "/");
        });
    },
    loadCampaignContent() {
      this.loading = true;
      this.loadCampaignImage();
      DataStore.campaignContent(this.campaign.id, {
        content_area_name: [
          "header",
          "teaser",
          "description",
          "footer_header",
          "footer_description",
        ],
      })
        .then((res) => {
          this.campaignContent = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    loadCampaignImage() {
      this.$refs.campaignImage.onerror = () => {
        this.$refs.campaignImage.src = SuportTeamImg;
      };

      this.$refs.campaignImage.src = DataStore.campaignContentIamge(
        this.campaign.id,
        "footer_image"
      );
    },
    loadProducts() {
      this.loading = true;

      this.type = this.$route.params.product
        ? this.$route.params.product
        : "koberpakken";
      DataStore.productList()
        .then((data) => {
          this.loading = false;
          this.products = data.data;

          if (!this.product) {
            return (window.location = "/");
          }
        })
        .catch((error) => {
          this.productLoadError = true;
          this.loading = false;
        });
    },
    createNewCase() {
      if (!this.creatingOrder) {
        this.creatingOrder = true;
        this.errors = [];
        this.error = false;
        let timer = null;
        let callback = null;

        let params = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          contact_time: this.contact_time,
          order_type: this.callmePage ? 2 : 1,
        };

        if (!this.campaign) {
          params = Object.assign({}, params, {
            type: this.product.type,
            system: this.product.system,
          });
        } else {
          params = Object.assign({}, params, {
            campaign_id: this.campaign.id,
            system: "nemgen",
            type: this.product.type,
          });
        }

        timer = setTimeout(() => {
          timer = null;
          if (callback) {
            callback();
          }
        }, 2000);

        DataStore.createLead(params)
          .then((res) => {
            if (timer) {
              callback = () => {
                this.creatingOrder = false;
              };
            } else {
              this.creatingOrder = false;
            }

            this.lead = res.data;
            let path = "";
            if (this.campaign) {
              path = "/" + this.$route.params.source;
              path += this.callmePage ? "/ring-mig-op/" : "/start-sag/";
              path += this.$route.params.campaign + "/kvittering";
            } else {
              path = this.callmePage ? "/ring-mig-op/" : "/start-sag/";
              path += this.type + "/kvittering";
            }

            this.$router.push({
              path,
            });
          })
          .catch((error) => {
            this.creatingOrder = false;
            this.error = true;
            if (error.response && error.response.data.code == "bad_request") {
              this.errors = error.response.data.inputs;
            }
          });
      }
    },
  },
  computed: {
    pageHeader() {
      let campaignHeader = this.campaignContent.filter(
        (content) => content.content_area_name == "header"
      );
      if (campaignHeader.length) {
        return campaignHeader[0].content.replace("\n", "<br/>");
      } else if (this.callmePage) {
        let str = "Gratis opkald om";
        str += this.product ? "<br/>" + this.product.title : "";
        return str;
      } else {
        let str = "Opret ny sag vedrørende";
        str += this.product ? "<br/>" + this.product.title : "";
        return str;
      }
    },
    pageSubHeading() {
      let campaignSubHeading = this.campaignContent.filter(
        (content) => content.content_area_name == "teaser"
      );
      if (campaignSubHeading.length) {
        return campaignSubHeading[0].content.replace("\n", "<br/>");
      } else {
        return "Indtast dine kontaktoplysninger nedenfor";
      }
    },
    pageParagraphs() {
      let campaignParagraphs = this.campaignContent.filter(
        (content) => content.content_area_name == "description"
      );
      if (campaignParagraphs.length) {
        return campaignParagraphs[0].content.split("\n");
      } else if (this.callmePage) {
        let departmentString =
          this.product && this.product.department === "bolig"
            ? "boligadvokater"
            : "advokater";
        return [
          "Vore kompetente " +
            departmentString +
            " sidder klar til at ringe med gratis rådgivning, så vi i fællesskab sikre at vi finder den rigtige løsning til dig.",
        ];
      } else {
        return [
          "Din henvendelse er gangske uforpligtene for dig. Du får på de næste sider mulighed for at sætte flere ord på din henvendelse.",
        ];
      }
    },
    pageTrustHeader() {
      let trustHeader = this.campaignContent.filter(
        (content) => content.content_area_name == "footer_header"
      );
      if (trustHeader.length) {
        return trustHeader[0].content;
      } else {
        return "Vi ringer til dig og bekræfter";
      }
    },
    pageTrustContent() {
      let trustContent = this.campaignContent.filter(
        (content) => content.content_area_name == "footer_description"
      );
      if (trustContent.length) {
        return trustContent[0].content.replace("\n", "<br/>");
      } else {
        return "Efter du har udfyldt bestillingen her på siden, bliver du gratis ringet op af en vores advokater eller juridiske medarbejdere. Sammen gennemgår vi din ordre og først når vi er enige sender vi dig en ordrebekræftelse. Først når opgaven er færdig og bolighandlen er endelig, sender vi en regning.";
      }
    },

    policyLink() {
      if (this.product && this.product.nemRegProduct) {
        if (
          [
            "stiftelse-af-aps",
            "stiftelse-af-ivs",
            "stiftelse-af-holding-ivs",
            "stiftelse-af-holding-aps",
          ].indexOf(this.product.type) > -1
        ) {
          return "https://www.nemadvokat.dk/datapolitikreg/";
        }
      }

      return "https://www.nemadvokat.dk/datapolitik/";
    },
    callmePage() {
      return this.$route.meta.callMe === true;
    },
    nameError() {
      return this.errors.indexOf("name") > -1 ? "Navn skal udfyldes" : null;
    },
    phoneError() {
      return this.errors.indexOf("phone") > -1
        ? "Tlf. ikke udfyldt korrekt"
        : null;
    },
    emailError() {
      return this.errors.indexOf("email") > -1
        ? "E-mail ikke udfyldt korrekt"
        : null;
    },
    product() {
      if (this.campaign) {
        let activeProducts = this.campaign.products.filter(
          (product) => !product.related
        );
        return Object.assign({}, activeProducts[0], {
          title: this.campaign.name,
        });
      }

      let product = this.products.filter((product) => {
        return product.type === this.type;
      });

      if (product[0]) {
        return product[0];
      }

      return null;
    },
  },
  components: {
    "intro-header": IntroHeader,
    "input-field": InputField,
  },
};
</script>