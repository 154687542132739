var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Din kommentar", show: true, noScroll: false },
      on: { close: _vm.onClose }
    },
    [
      _vm.comment
        ? _c("div", { staticClass: "lead-info" }, [
            _c("p", { domProps: { textContent: _vm._s(_vm.comment.comment) } }),
            _vm._v(" "),
            !_vm.comment
              ? _c("p", [_vm._v("Din kommentar kunne ikke findes")])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b7ef84b6", { render: render, staticRenderFns: staticRenderFns })
  }
}