var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-overview__block" },
    [
      _c("h3", [_vm._v("Indbetalinger")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "case-deposit" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.depositOrders, function(depositOrder) {
              return _c(
                "div",
                { key: depositOrder.id, staticClass: "case-deposit__line" },
                [
                  _c("p", {
                    domProps: { textContent: _vm._s(depositOrder.typeText) }
                  }),
                  _vm._v(" "),
                  _c("p", {
                    directives: [
                      {
                        name: "price",
                        rawName: "v-price",
                        value: depositOrder.price,
                        expression: "depositOrder.price"
                      }
                    ]
                  }),
                  _vm._v(" "),
                  depositOrder.status != 2
                    ? _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showDeposit(depositOrder)
                            }
                          }
                        },
                        [_vm._v("Betal nu")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  depositOrder.status == 2
                    ? _c("p", [_vm._v("Modtaget")])
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Betaling af tinglysningsgebyr",
            show: _vm.showTinlgysningModal
          },
          on: {
            close: function($event) {
              _vm.showTinlgysningModal = false
            }
          }
        },
        [
          _c("registration-fee", {
            attrs: { order: _vm.order },
            on: { showletter: _vm.showCustomerLetter }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDepositOrder
        ? _c("deposit-modal", {
            attrs: {
              caseid: _vm.order.caseid,
              "deposit-order": _vm.showDepositOrder
            },
            on: {
              close: function($event) {
                _vm.showDepositOrder = null
              },
              showagreementletter: _vm.showCustomerLetter
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "case-deposit__line" }, [
      _c("p", [
        _vm._v(
          "Her fremgår sagens økonomi. Manglende betalinger her kan bremse sagen. Bemærk der kan gå op til 24 timer før vi registrerer din betaling"
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-530e2b83", { render: render, staticRenderFns: staticRenderFns })
  }
}