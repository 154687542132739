<template>
  <slide
    :title="title"
    :orderHelp="helpText"
    class="order-flow__wrapper--6"
    @next="submit()"
    :validated="isValid"
  >
    <template slot="content">
      <div class="order-tiles order-tiles--selection">
        <div
          class="order-tiles__item"
          :class="[
            'order-tiles__item--0',
            { 'order-tiles__item--selected': customCapital === false },
          ]"
          @click="customCapital = false"
        >
          <span class="order-tiles__item__heading" v-price="standardCapital"></span>
        </div>
        <div
          class="order-tiles__item"
          :class="[
            'order-tiles__item--1',
            {
              'order-tiles__item--selected': customCapital === true,
            },
          ]"
          @click="customCapital = true"
        >
          <span class="order-tiles__item__heading">Anden</span>
        </div>
      </div>

      <div
        class="order-flow__content__expansion"
        :class="{
          'order-flow__content__expansion--open': customCapital,
        }"
      >
        <div class="input-fields">
          <div class="label-input">
            <money v-model="intCapital" v-bind="money"></money>
          </div>
        </div>
      </div>
    </template>
  </slide>
</template>

<script>
import Slide from "./slide.vue";
import { Money } from "v-money";

export default {
  props: {
    companyName: { type: String, default: "virksomhed" },
    capital: { type: Number, default: 0 },
  },
  data() {
    return {
      intCapital: 0,
      customCapital: null,
      standardCapital: 40000,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " kr.",
        precision: 2,
        masked: false,
      },
      helpText: {
        heading: 'Information',
        paragraphs: [
          'De fleste anpartsselskaber stiftes med kr. 40.000,00, som er den lovpligtige kapitalmængde, men du kan skyde mere ind, hvis dit selskab f.eks. har behov for et særligt stort kapitalberedskab i starten eller det kræves af andre. En kapital på mere end 40.000,00 kr. kan dog påvirke prisen og ekspeditionstiden.'
        ]
      }
    };
  },
  mounted() {
    this.checkCapital();
  },
  watch: {
    capital() {
      this.checkCapital();
    },
  },
  methods: {
    checkCapital() {
      if (this.capital && this.capital != this.standardCapital) {
        this.customCapital = true;
        this.intCapital = this.capital;
      } else if (this.capital == this.standardCapital) {
        this.customCapital = false;
      }
    },
    submit() {
      this.$emit(
        "submit",
        Object.assign(
          {},
          {
            capital: this.customCapital ? this.intCapital : this.standardCapital,
          }
        )
      );
    },
  },
  computed: {
    title() {
      return "Hvad er kapitalen for <br/>" + this.companyName;
    },
    isValid() {
      return this.customCapital != null || this.capital > 0 ? true : false;
    },
  },
  components: {
    Slide,
    Money,
  },
};
</script>