<template>
  <div class="case-economy">
    <case-deposits
      :order="order"
      @showcustomerletter="onShowContract"
      @showdeposits="shoeDepositData"
    ></case-deposits>

    <modal
      v-if="showDepositModal"
      title="Deponering af honorar"
      @close="onCloseContractModal"
      :show="true"
      :class="{ loading: aftaleLoading }"
    >
      <deposit
        v-if="letterData"
        @showletter="onShowContract"
        :letterData="letterData"
      ></deposit>
      <p v-if="aftaleError" class="error">
        Deponeringsdata kunne ikke hentes...
      </p>
      <p v-show="aftaleLoading">Henter deponerings info...</p>
    </modal>

    <contract-table-modal
      v-if="showContractTableModal"
      @close="showContractTableModal = false"
      :order="order"
      :customer="customer"
    ></contract-table-modal>
  </div>
</template>

<script>
import CaseDeposits from "./case-deposits.vue";
import Deposit from "./../contract/deposit.vue";
import Modal from "../modal/base-modal.vue";
import ContractTableModal from "./../contract/contract-table-modal.vue";

export default {
  props: {
    order: { type: Object, default: null },
    customer: { type: Object, default: null },
  },
  data() {
    return {
      showDepositModal: false,
      aftalebrevData: null,
      aftaleLoading: false,
      showContractTableModal: false,
      aftaleError: "",
    };
  },
  methods: {
    onCloseContractModal() {
      this.showDepositModal = false;
    },
    onShowContract() {
      this.showDepositModal = false;
      this.showContractTableModal = true;
    },
    shoeDepositData() {
      if (this.aftaleLoading) return;

      this.aftaleError = "";
      this.showDepositModal = true;
      this.aftaleLoading = true;
      this.aftalebrevData = null;

      DataStore.getAftaleBrev(this.order.main_order_hash)
        .then((data) => {
          this.aftaleLoading = false;
          if (data.data.status === "error") {
            this.aftaleError = "Aftalebrevet du søger eksistrer ikke...";
          } else {
            this.aftalebrevData = data.data;
          }
        })
        .catch((error) => {
          this.aftaleLoading = false;
          this.aftaleError =
            "Aftalebrevet kunne ikke blive hentet... Prøv igen...";
        });
    },
  },
  computed: {
    letterData() {
      if (!this.aftalebrevData) {
        return null;
      }

      return this.aftalebrevData[0] ? this.aftalebrevData[0].letter_data : null;
    },
  },
  components: {
    CaseDeposits,
    Deposit,
    Modal,
    ContractTableModal,
  },
};
</script>