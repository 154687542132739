var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-login profile-login--part",
      class: {
        loading:
          _vm.validatingLogin || _vm.emailValidating || _vm.settingPassword
      }
    },
    [
      _c("div", { staticClass: "profile-login__wrapper" }, [
        _c(
          "div",
          [
            _vm.validateEmailView
              ? [
                  _c("h2", { staticClass: "profile-login__header" }, [
                    _vm._v("Validerer din e-mail")
                  ]),
                  _vm._v(" "),
                  _vm.emailValidating
                    ? _c("p", { staticClass: "profile-login__text" }, [
                        _vm._v("Din e-mail valideres, vent venligst...")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "profile-login__text" }),
                  _vm._v(" "),
                  _vm.error
                    ? _c("p", {
                        staticClass: "error",
                        domProps: { innerHTML: _vm._s(_vm.error) }
                      })
                    : _vm._e()
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.setPasswordView
              ? [
                  _c("h2", { staticClass: "profile-login__header" }, [
                    _vm._v("Din validering er gennemført!")
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("p", { staticClass: "profile-login__text" }, [
                    _vm._v(
                      "Indtast venligst den adgangskode som du ønsker at bruge til at logge ind med."
                    )
                  ]),
                  _vm._v(" "),
                  _c("input-field", {
                    attrs: {
                      name: "Kodeord",
                      "input-type": "password",
                      error:
                        _vm.fieldErrors.indexOf("password") > -1
                          ? "Skal udfyldes"
                          : ""
                    },
                    on: { enter: _vm.setPassword },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _vm._v(" "),
                  _c("input-field", {
                    attrs: {
                      name: "Gentag Kodeord",
                      "input-type": "password",
                      error:
                        _vm.fieldErrors.indexOf("confirm_password") > -1
                          ? "Skal udfyldes"
                          : ""
                    },
                    on: { enter: _vm.setPassword },
                    model: {
                      value: _vm.confirm_password,
                      callback: function($$v) {
                        _vm.confirm_password = $$v
                      },
                      expression: "confirm_password"
                    }
                  }),
                  _vm._v(" "),
                  _vm.error
                    ? _c("p", {
                        staticClass: "error",
                        domProps: { innerHTML: _vm._s(_vm.error) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled:
                          !_vm.password ||
                          !_vm.confirm_password ||
                          _vm.password != _vm.confirm_password ||
                          _vm.settingPassword
                      },
                      on: { click: _vm.setPassword }
                    },
                    [_vm._v("Gem")]
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.validateEmailView && !_vm.setPasswordView
              ? [
                  _c("h2", { staticClass: "profile-login__header" }, [
                    _vm._v("Part Login")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "profile-login__text" }, [
                    _vm._v("Log ind med din e-mail og selvvalgt kodeoerd.")
                  ]),
                  _vm._v(" "),
                  _c("input-field", {
                    attrs: {
                      name: "E-mail",
                      "input-type": "email",
                      error:
                        _vm.fieldErrors.indexOf("email") > -1
                          ? "Skal udfyldes"
                          : ""
                    },
                    on: { enter: _vm.validateLogin },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _vm._v(" "),
                  _c("input-field", {
                    attrs: {
                      name: "Kodeord",
                      "input-type": "password",
                      error:
                        _vm.fieldErrors.indexOf("password") > -1
                          ? "Skal udfyldes"
                          : ""
                    },
                    on: { enter: _vm.validateLogin },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _vm._v(" "),
                  _vm.error
                    ? _c("p", {
                        staticClass: "error",
                        domProps: { innerHTML: _vm._s(_vm.error) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: {
                        disabled:
                          !_vm.email || !_vm.password || _vm.validatingLogin
                      },
                      on: { click: _vm.validateLogin }
                    },
                    [_vm._v("Log ind")]
                  ),
                  _vm._v(" "),
                  _vm._m(1)
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v("\n          Du er valideret til at kunne benytte\n          "),
      _c("b", [_vm._v("NEM")]),
      _vm._v("BLIK.\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__contact-text" }, [
      _vm._v("\n          Har du glemt din adgangskode?\n          "),
      _c(
        "a",
        {
          staticClass: "profile-login__contact-link",
          attrs: {
            href: "https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/",
            target: "_blank"
          }
        },
        [_vm._v("Kontakt os her.")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02e346a9", { render: render, staticRenderFns: staticRenderFns })
  }
}