var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kyc-wrapper" },
    [
      _c("h2", { domProps: { textContent: _vm._s(_vm.translations.title) } }),
      _vm._v(" "),
      _vm._l(_vm.translations.text, function(text, key) {
        return _c("p", { key: key, domProps: { textContent: _vm._s(text) } })
      }),
      _vm._v(" "),
      _c("button", {
        staticClass: "btn",
        class: { loading: !_vm.ready },
        attrs: { disabled: !_vm.ready },
        domProps: { textContent: _vm._s(_vm.translations.submit) },
        on: {
          click: function($event) {
            return _vm.$emit("submit")
          }
        }
      }),
      _vm._v(" "),
      _c("faq-item", {
        attrs: {
          title: _vm.translations.faq.title,
          text: _vm.translations.faq.text
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58e31cf7", { render: render, staticRenderFns: staticRenderFns })
  }
}