var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Udfyld kundedata", show: true, noScroll: false },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        { staticClass: "lead-customer-validation" },
        [
          _c("label-select", {
            attrs: {
              title: "Type",
              options: _vm.customerTypes,
              value: _vm.intLead.customer_is_company ? "1" : "0"
            },
            on: {
              input: function(inp) {
                _vm.intLead.customer_is_company = inp == 1 ? true : false
              }
            }
          }),
          _vm._v(" "),
          _c("inputField", {
            attrs: {
              name: "Navn",
              error:
                _vm.errorFields.indexOf("customer_name") > -1
                  ? "Skal udfyldes"
                  : ""
            },
            model: {
              value: _vm.intLead.customer_name,
              callback: function($$v) {
                _vm.$set(_vm.intLead, "customer_name", $$v)
              },
              expression: "intLead.customer_name"
            }
          }),
          _vm._v(" "),
          _c("inputField", {
            attrs: {
              name: "Adresse",
              error:
                _vm.errorFields.indexOf("customer_address") > -1
                  ? "Skal udfyldes"
                  : ""
            },
            model: {
              value: _vm.intLead.customer_address,
              callback: function($$v) {
                _vm.$set(_vm.intLead, "customer_address", $$v)
              },
              expression: "intLead.customer_address"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "two-col" },
            [
              _c("inputField", {
                staticClass: "label-input--postal",
                attrs: {
                  name: "Post nr.",
                  "input-type": "tel",
                  error:
                    _vm.errorFields.indexOf("customer_postal") > -1
                      ? "Skal udfyldes"
                      : ""
                },
                on: { stopTyping: _vm.getTownByZip },
                model: {
                  value: _vm.intLead.customer_postal,
                  callback: function($$v) {
                    _vm.$set(_vm.intLead, "customer_postal", $$v)
                  },
                  expression: "intLead.customer_postal"
                }
              }),
              _vm._v(" "),
              _c("inputField", {
                attrs: {
                  name: "By",
                  error:
                    _vm.errorFields.indexOf("customer_town") > -1
                      ? "Skal udfyldes"
                      : ""
                },
                model: {
                  value: _vm.intLead.customer_town,
                  callback: function($$v) {
                    _vm.$set(_vm.intLead, "customer_town", $$v)
                  },
                  expression: "intLead.customer_town"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("inputField", {
            attrs: {
              name: "E-mail",
              "input-type": "email",
              error:
                _vm.errorFields.indexOf("customer_email") > -1
                  ? "Skal udfyldes og være en valid e-mail"
                  : ""
            },
            model: {
              value: _vm.intLead.customer_email,
              callback: function($$v) {
                _vm.$set(_vm.intLead, "customer_email", $$v)
              },
              expression: "intLead.customer_email"
            }
          }),
          _vm._v(" "),
          _c("inputField", {
            attrs: {
              name: "Tlf",
              "input-type": "tel",
              error:
                _vm.errorFields.indexOf("customer_email") > -1
                  ? "Skal udfyldes"
                  : ""
            },
            model: {
              value: _vm.intLead.customer_phone,
              callback: function($$v) {
                _vm.$set(_vm.intLead, "customer_phone", $$v)
              },
              expression: "intLead.customer_phone"
            }
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn btn--positive",
            class: { loading: _vm.loading },
            attrs: { disabled: !_vm.isValid || _vm.loading },
            domProps: {
              textContent: _vm._s(
                _vm.lead.customer_verified ? "Rediger" : "Bekræft"
              )
            },
            on: { click: _vm.submit }
          }),
          _vm._v(" "),
          _vm.error
            ? _c("p", { staticClass: "error" }, [
                _vm._v(
                  "\n      Der skete en fejl da dine data skulle valideres. Tjek du har udfyldt\n      alle felter korrekt...\n    "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52c5f972", { render: render, staticRenderFns: staticRenderFns })
  }
}