var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "welcome-view" }, [
    _c("h3", { staticClass: "welcome-view__title" }, [
      _vm._v("\n    Velkommen,\n    "),
      _c("span", { domProps: { textContent: _vm._s(_vm.name) } })
    ]),
    _vm._v(" "),
    _vm.partType == "customer"
      ? _c("div", { staticClass: "welcome-view__text" }, [
          _c("p", [
            _vm._v(
              "Vi har nu bekræftet din e-mail og telefon som sikrer enheder for den videre kommunikation i vores sagsbehandling."
            )
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Vi skifter koderne hele tiden, du skal bare sørge for at have adgang til din e-mail og telefon."
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.partType == "part"
      ? _c("div", { staticClass: "welcome-view__text" }, [
          _c("p", [
            _vm._v(
              "Vi har nu bekræftet din e-mail som sikrer enheder for den videre kommunikation i vores sagsbehandling."
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("button", { staticClass: "btn", on: { click: _vm.goToCases } }, [
      _vm._v("Gå til sager")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Når der er nyt i din sag, sender vi dig en e-mail med et link til "
      ),
      _c("b", [_vm._v("NEMBLIK")]),
      _vm._v(
        ". Dette link kan kun åbnes ved at anvende den SMS-kode som vi samtidig sender til din mobil."
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-324fea0a", { render: render, staticRenderFns: staticRenderFns })
  }
}