<template>
    <slide :title="title" :orderHelp="helpText" class="order-flow__wrapper--7" @next="submit()" :validated="isValid">
        <template slot="content">
                    <div class="label-input">
                        <textarea name="" id="purpose" cols="30" rows="10" v-model="intCompanyGoal" :class="{'has-text': hasText}"></textarea>
                        <label for="purpose">Selskabets formål er at...</label>
                    </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
import inputFieldVue from '../../input-field/input-field.vue';

export default {
  props: {
      companyName: {type: String, default: 'virksomhed'},
      companyGoal: {type: String, default: ''}
  },
  data() {
      return {
          intCompanyGoal: '',
          helpText: {
            heading: 'Information',
            paragraphs: [
                'Beskriv formålet så godt og enkelt som du kan, så hjælper vi derfra.'
            ]
          }
      }
  },
  mounted() {
      if(this.companyGoal) {
          this.intCompanyGoal = this.companyGoal;
      }
  },
  watch: {
      companyGoal() {
          this.intCompanyGoal = this.companyGoal;
      }
  },
  methods: {
      submit() {
        this.$emit('submit', this.intCompanyGoal);  
      }
  },
  computed: {
    hasText() {
        return (this.intCompanyGoal && this.intCompanyGoal.length) ? true : false;
    }, 
    title() {
        return 'Hvad er formålet i selskabet <br/>' + this.companyName;
    },
    isValid() {
        return true;
    }  
  },
  components: {
    'slide': Slide,
    'inputfield': inputFieldVue
  }
}
</script>