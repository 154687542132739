<template>
  <div class="kyc-wrapper" :class="{loading}">
    <h2 v-text="translations.title"></h2>
    <p v-text="translations.text"></p>
    <p class="kyc-wrapper__small-text">
      {{ translations.esignatur_info }}
      <a
        v-if="validationMethod.sign_url"
        :href="validationMethod.sign_url"
        v-text="translations.esignatur_btn"
        class="kyc-wrapper__link kyc-wrapper__link--inline"
      ></a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    translations: {
      type: Object,
      defualt() {
        return {};
      },
    },
    validationMethod: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    if (this.validationMethod.sign_url) {
      setTimeout(() => {
        window.location = this.validationMethod.sign_url;
      }, 150);
    }

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>