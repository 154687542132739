var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kyc-wrapper" }, [
    _c("h2", { domProps: { textContent: _vm._s(_vm.title) } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nem-dropzone" },
      [
        _c("vue-dropzone", {
          ref: "dropzone",
          attrs: { id: "dropzone", options: _vm.dropzoneOptions },
          on: {
            "vdropzone-queue-complete": _vm.finishUploading,
            "vdropzone-file-added": _vm.onFileAdd,
            "vdropzone-files-added": _vm.onFileAdd,
            "vdropzone-max-files-exceeded": _vm.onMaxFiles
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.error
      ? _c("p", {
          staticClass: "error",
          domProps: { textContent: _vm._s(_vm.error) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("button", {
      staticClass: "btn",
      class: { loading: _vm.uploadingFiles },
      attrs: { disabled: _vm.uploadingFiles || !_vm.queueSize },
      domProps: { textContent: _vm._s(_vm.translations.upload_btn) },
      on: { click: _vm.submit }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a3741de2", { render: render, staticRenderFns: staticRenderFns })
  }
}