<template>
  <div class="input-fields">
    <div class="input-fields__two">
      <date-picker
        name="Dato"
        @pickdate="updateDate"
        :is-ready="true"
        :default-date="startDate"
        format="DD-MM-YYYY"
      ></date-picker>

      <div class="drop-down" style="margin: 0">
        <label for="hour">Kl.</label>
        <select id="hour" v-model="setTimeHour" class="drop-down__select">
          <option v-for="hour in hours" :value="hour.key">
            {{ hour.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../datepicker/datepicker.vue";
import MomentObj from "moment";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      setDate: "",
      setTimeHour: "09",
    };
  },
  watch: {
    setDate() {
      this.selelectTime();
    },
    setTimeHour() {
      this.selelectTime();
    },
    value() {
      let dateObj = MomentObj(this.value);
      this.setDate = dateObj.format('"DD-MM-YYYY');
      this.setTimeHour = dateObj.format("HH");
    },
  },
  mounted() {
    this.setDate = this.startDate;
    this.setTimeHour = this.hours[0].key;
  },
  methods: {
    updateDate(val) {
      this.setDate = val;
      this.setTimeHour = this.hours[0].key;
    },
    selelectTime() {
      let date = this.setDate + " " + this.setTimeHour + ":00";
      let time = MomentObj(date, "DD-MM-YYYY HH:mm");
      this.$emit("input", time);
    },
  },
  computed: {
    startDate() {
      let startDay = MomentObj();
      if (startDay.isAfter(MomentObj(startDay).hour(16).minute(0).second(0))) {
        startDay = startDay.add(1, "days");
      }

      if (startDay.isoWeekday() > 4) {
        startDay = startDay.add(1, "weeks").isoWeekday(1);
      }

      return startDay.format("DD-MM-YYYY");
    },
    hours() {
      let hours = [];
      let hourSet = [
        {
          value: "09-11",
          key: "09",
          hourCheck: 10,
        },
        {
          value: "11-13",
          key: "11",
          hourCheck: 12,
        },
        {
          value: "13-15",
          key: "13",
          hourCheck: 14,
        },
        {
          value: "15-17",
          key: "15",
          hourCheck: 16,
        },
      ];

      for (let i in hourSet) {
        let hourData = hourSet[i];
        let m = MomentObj(
          this.setDate + " " + parseInt(hourData.hourCheck) + ":00",
          "DD-MM-YYYY HH:mm"
        );
        if (m.isAfter(MomentObj())) {
          hours.push({
            key: hourData.key,
            value: hourData.value,
          });
        }
      }

      return hours;
    },
  },
  components: {
    DatePicker,
  },
};
</script>