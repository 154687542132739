<template>
  <div>
    <msg-item
      v-for="message in msgList"
      :message="message"
      :part="part"
      :key="message.id"
    ></msg-item>
  </div>
</template>

<script>
import MsgItem from "./msg-item.vue";

export default {
  props: {
    messages: {
      type: Array,
      default() {
        return [];
      }
    },
    part: { type: Object, default: null },
    reverse: {type: Boolean, default :false}
  },
  computed: {
    msgList() {
      let messages = Object.assign([ ], this.messages);
      if(this.reverse) {
        messages.reverse();
      }

      return messages;
    }
  },
  components: {
    MsgItem
  }
};
</script>