<template>
  <div class="case-overview">
    <div class="case-overview__block">
      <div class="case-product">
        <p class="case-product__heading" v-text="order.producttitle"></p>
        <p class="case-product__text" v-text="order.key_field"></p>
      </div>
    </div>
    <case-tasks :order="order" :customer="customer"></case-tasks>
    <case-responsible :order="order"></case-responsible>
  </div>
</template>

<script>
import CaseResponsible from "./case-repsonsible.vue";
import CaseTasks from "./case-tasks.vue";

export default {
  props: {
    order: { type: Object, default: null },
    customer: { type: Object, default: null },
  },
  components: {
    CaseResponsible,
    CaseTasks,
  },
};
</script>