var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--6",
      attrs: { title: _vm.title, validated: _vm.isValid },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "order-tiles",
            class: { "order-tiles--selection": _vm.isValid }
          },
          _vm._l(_vm.addressList, function(owner, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "order-tiles__item",
                class: [
                  "order-tiles__item--" + key,
                  {
                    "order-tiles__item--selected": _vm.selectedOwnerKey === key
                  }
                ],
                on: {
                  click: function($event) {
                    return _vm.setOwnerAsAddress(key)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "order-tiles__item__heading",
                  domProps: { textContent: _vm._s(owner.address) }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "order-tiles__item__text" }, [
                  _vm._v(_vm._s(owner.postal) + " " + _vm._s(owner.town))
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "order-flow__expand-btn",
            attrs: { href: "#" },
            on: { click: _vm.setOtherAddress }
          },
          [_vm._v("Anden adresse?")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "order-flow__content__expansion",
            class: {
              "order-flow__content__expansion--open": _vm.showOtherAddress
            }
          },
          [
            _c("inputfield", {
              attrs: { name: "Adresse", value: _vm.intCompanyAddress.address },
              on: {
                input: function(val) {
                  _vm.intCompanyAddress.address = val
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-flow__content__two-col" },
              [
                _c("inputfield", {
                  attrs: {
                    name: "Post nr.",
                    value: _vm.intCompanyAddress.postal.toString(),
                    "input-type": "tel"
                  },
                  on: {
                    input: function(val) {
                      _vm.intCompanyAddress.postal = val
                    },
                    stopTyping: _vm.getTownByZip
                  }
                }),
                _vm._v(" "),
                _c("inputfield", {
                  attrs: { name: "By", value: _vm.intCompanyAddress.town },
                  on: {
                    input: function(val) {
                      _vm.intCompanyAddress.town = val
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("inputfield", {
              attrs: { name: "Land", value: _vm.intCompanyAddress.country },
              on: {
                input: function(val) {
                  _vm.intCompanyAddress.country = val
                }
              }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8cd8ab0e", { render: render, staticRenderFns: staticRenderFns })
  }
}