var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logType
    ? _c(
        "div",
        {
          staticClass: "log-message",
          class: [
            "log-message-type--" + _vm.logType,
            { "log-message-error": _vm.statuslog.contact_log_status > 500 }
          ]
        },
        [
          _c("div", { staticClass: "log-message__header" }, [
            _c("div", [
              _c("span", { staticClass: "log-message__date" }, [
                _vm._v(_vm._s(_vm.date))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "log-message__name" }, [
                _vm._v(_vm._s(_vm.name))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "log-message__body" }, [
            _c(
              "div",
              { staticClass: "log-message__text" },
              [
                _vm.logType == "WELCOME_MESSAGE"
                  ? [
                      _c("p", [
                        _c("strong", [_vm._v("Hej " + _vm._s(_vm.lead.name))])
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _vm.statuslog.showActions
                        ? _c("div", { staticClass: "log-message__actions" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("contactmethod", "email")
                                  }
                                }
                              },
                              [
                                _c("span", [_vm._v("På e-mail")]),
                                _vm._v(" "),
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__phone-icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 512 512"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("|")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("contactmethod", "phone")
                                  }
                                }
                              },
                              [
                                _c("span", [_vm._v("På telefon")]),
                                _vm._v(" "),
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__email-icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 512 512"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "WELCME_ACTION_NOT_SET_ERROR"
                  ? [
                      _c("p", [
                        _vm._v(
                          "Der skete en fejl og dit svar blev ikke sendt! Prøv igen..."
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "log-message__actions" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("contactmethod", "email")
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("På e-mail")]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "log-message__phone-icon",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 512 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("|")]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("contactmethod", "phone")
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("På telefon")]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "log-message__email-icon",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 512 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "WE_CALl_MESSAGE"
                  ? [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Det gør vi senest indenfor to timer i vores åbningstid. Passer det\n          bedre på et andet tidspunkt, så sæt gerne et\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setcontacttime")
                              }
                            }
                          },
                          [_vm._v("tidspunkt her")]
                        ),
                        _vm._v(", hvor du træffes bedst.\n        ")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Mens du venter på os, kan du bekræfte kundeinformation og udfylde\n          sagsdata med knapper nedenfor.\n          "
                        ),
                        typeof _vm.lead.customer_name !== "undefined"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcustomerinfo")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "13.56",
                                        r: "2.84"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.containsCaseData
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcasedata")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-miterlimit": "10",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "4.38",
                                        r: "2.08"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("En stor hjælp der sparer en masse tid.")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "WE_CALL_MESSAGE_RELATED_PRODUCTS"
                  ? [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Det gør vi senest indenfor to timer i vores åbningstid. Har du et tidspunkt der passer bedre,\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setcontacttime")
                              }
                            }
                          },
                          [_vm._v("så sæt det her")]
                        ),
                        _vm._v(".\n        ")
                      ]),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "For de fleste af vores kunder som vi har hjulpet med " +
                            _vm._s(_vm.statuslog.mainProductName) +
                            " har vi også lavet en uforpligtende gennemgang af behovet for " +
                            _vm._s(_vm.statuslog.relatedProductNames)
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("Skal vi tage en snak om dette når vi ringer?")
                      ]),
                      _vm._v(" "),
                      _vm.statuslog.showActions
                        ? _c("p", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("addrelatedproducts", true)
                                  }
                                }
                              },
                              [_vm._v("Ja tak")]
                            ),
                            _vm._v(" | "),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "addrelatedproducts",
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v("Nej tak")]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "WE_WRITE_MESSAGE"
                  ? [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Måtte et opkald være bedre, men på et specifikt tidspunkt kan du give\n          os et\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setcontacttime")
                              }
                            }
                          },
                          [_vm._v("tidspunkt her")]
                        ),
                        _vm._v(", hvor du træffes bedst.\n        ")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Mens du venter på os, kan du bekræfte kundeinformation og udfylde\n          sagsdata med knapper nedenfor.\n          "
                        ),
                        typeof _vm.lead.customer_name !== "undefined"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcustomerinfo")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "13.56",
                                        r: "2.84"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.containsCaseData
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcasedata")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-miterlimit": "10",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "4.38",
                                        r: "2.08"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("En stor hjælp der sparer en masse tid.")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "WE_WRITE_MESSAGE_RELATED_PRODUCTS"
                  ? [
                      _vm._m(5),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Det gør vi senest indenfor to timer i vores åbningstid.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "For de fleste af vores kunder som vi har hjulpet med " +
                            _vm._s(_vm.statuslog.mainProductName) +
                            " har vi også lavet en uforpligtende gennemgang af behovet for " +
                            _vm._s(_vm.statuslog.relatedProductNames)
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("Skal vi tage en snak om dette når vi ringer?")
                      ]),
                      _vm._v(" "),
                      _vm.statuslog.showActions
                        ? _c("p", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("addrelatedproducts", true)
                                  }
                                }
                              },
                              [_vm._v("Ja tak")]
                            ),
                            _vm._v(" | "),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "addrelatedproducts",
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v("Nej tak")]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "RELATED_PRODUCTS_ACCEPTED"
                  ? [
                      _vm._m(7),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Din henvendelse får nu nogle ekstra hænder og øjne, så vi kommer hele vejen rundt.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Mens du venter på nyt fra os, kan du selv hjælpe sagen videre ved at udfylde kundedata og evt. sagsdata med knapperne nedenfor.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        typeof _vm.lead.customer_name !== "undefined"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcustomerinfo")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "13.56",
                                        r: "2.84"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.containsCaseData
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcasedata")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-miterlimit": "10",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "4.38",
                                        r: "2.08"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("En stor hjælp der sparer en masse tid.")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "RELATED_PRODUCTS_REJECTED"
                  ? [
                      _vm._m(8),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Du skal dog ikke holde dig tilbage, hvis du ønsker at vi kigger på det senere i processen.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Mens du venter på nyt fra os, kan du selv hjælpe sagen videre ved at udfylde kundedata og evt. sagsdata med knapperne nedenfor.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        typeof _vm.lead.customer_name !== "undefined"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcustomerinfo")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "13.56",
                                        r: "2.84"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.containsCaseData
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("setcasedata")
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "log-message__icon",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 33.07 33.07"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-linejoin": "round",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        d:
                                          "M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z"
                                      }
                                    }),
                                    _c("rect", {
                                      staticStyle: { fill: "none" },
                                      attrs: { width: "33.07", height: "33.07" }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z"
                                      }
                                    }),
                                    _c("circle", {
                                      staticStyle: {
                                        fill: "none",
                                        stroke: "#0f436b",
                                        "stroke-miterlimit": "10",
                                        "stroke-width": "1.5px"
                                      },
                                      attrs: {
                                        cx: "16.53",
                                        cy: "4.38",
                                        r: "2.08"
                                      }
                                    }),
                                    _c("path", {
                                      staticStyle: { fill: "#0f436b" },
                                      attrs: {
                                        d:
                                          "M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("En stor hjælp der sparer en masse tid.")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "CUSTOMER_INFO_RECEIVED"
                  ? [
                      _vm._m(9),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          De indtastede oplysninger er modtaget og arkiveret på rette sted.\n          "
                        ),
                        !_vm.leadDisabled
                          ? _c("span", [
                              _vm._v("Du kan se en\n            "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("viewcustomerinvoice")
                                    }
                                  }
                                },
                                [_vm._v("kvittering")]
                              ),
                              _vm._v("\n            med dine inputs her.")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Måtte der være fejl, kan du\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("comment")
                              }
                            }
                          },
                          [_vm._v("sende os en kommentar")]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "CUSTOMER_CASEINFO_RECEIVED"
                  ? [
                      _vm._m(10),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          De indtastede oplysninger er modtaget og arkiveret på rette sted.\n          "
                        ),
                        !_vm.leadDisabled
                          ? _c("span", [
                              _vm._v("Du kan se en\n            "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "viewproductinvoice",
                                        _vm.metadata.productid
                                      )
                                    }
                                  }
                                },
                                [_vm._v("kvittering")]
                              ),
                              _vm._v("\n            med dine inputs her.")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Måtte der være fejl, kan du sende os en kommentar med knappen\n          nedenfor\n        "
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "CUSTOMER_COMMENT_RECEIVED"
                  ? [
                      _vm._m(11),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Samtidigt er der sendt en notifikation til den ansvarlige\n          medarbejder. Du kan forvente svar fra os indenfor to timer i vores\n          åbningstid.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.statuslog.commentidx > -1
                        ? _c("p", [
                            _vm._v("\n          Du kan se\n          "),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "viewcomment",
                                      _vm.statuslog.commentidx
                                    )
                                  }
                                }
                              },
                              [_vm._v("din kommentar her.")]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "CUSTOMER_WANTS_CALL"
                  ? [
                      _vm._m(12),
                      _vm._v(" "),
                      _vm.metadata.startTime && _vm.metadata.endTime
                        ? _c("p", [
                            _vm._v(
                              "\n          Vi ringer til dig\n          " +
                                _vm._s(
                                  _vm
                                    .getMoment(_vm.metadata.startTime)
                                    .format("DD-MM-YYYY")
                                ) +
                                " mellem\n          " +
                                _vm._s(
                                  _vm
                                    .getMoment(_vm.metadata.startTime)
                                    .format("HH")
                                ) +
                                " -\n          " +
                                _vm._s(
                                  _vm
                                    .getMoment(_vm.metadata.endTime)
                                    .format("HH")
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Ønsker du at ændre tidspunktet kan du gøre det\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setcontacttime")
                              }
                            }
                          },
                          [_vm._v("lige her.")]
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "NO_CALL_RESPONSE_CUSTOMER"
                  ? [
                      _vm._m(13),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Jeg har lige forsøgt at ringe til dig uden held. Det vil jeg prøve\n          at gøre igen\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Du er også velkommen til at give os et\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setcontacttime")
                              }
                            }
                          },
                          [_vm._v("tidspunkt her")]
                        ),
                        _vm._v(
                          ", hvor du træffes bedst. Så ringer vi der.\n        "
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "CUSTOMER_ON_HOLD"
                  ? [
                      _vm._m(14),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Jeg har sat din henvendelse på pause indtil d. " +
                            _vm._s(
                              _vm.metadata.delayed_time
                                ? _vm
                                    .getMoment(_vm.metadata.delayed_time)
                                    .format("DD-MM-YYYY")
                                : ""
                            ) +
                            ". Dette\n          kan skyldes flere forgæves forsøg på kontakt, eller en aftale med\n          dig.\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n          Skal vi modsat videre med det samme skal du sende os en\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("comment")
                              }
                            }
                          },
                          [_vm._v("kommentar")]
                        ),
                        _vm._v(".\n        ")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "AWAITING_CUSTOMER_INFO"
                  ? [
                      _vm.metadata.reason
                        ? _c("nl2br", {
                            attrs: { tag: "p", text: _vm.metadata.reason }
                          })
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.logType == "LEAD_ARCHIVED"
                  ? [
                      _vm._m(15),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Vi fortager os ikke yderligere vedrørende denne henvendelse."
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("\n          Du kan sende en\n          "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("comment")
                              }
                            }
                          },
                          [_vm._v("kommentar")]
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "log-message__avatar",
              attrs: { src: _vm.image }
            })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Tak for din henvendelse til NEMADVOKAT. Vi har sendt en kvittering\n          til din mail med et link hertil. Vi finder nu en medarbejder til din\n          sag.\n          "
      ),
      _c("strong", [_vm._v("Hvordan ønsker du vi kontakter dig?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi ringer til dig")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi ringer til dig")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi kan hjælpe med mere")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi skriver til dig ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi skriver til dig")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi kan hjælpe med mere")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Vi udvider holdet")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Det er modtaget")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Kundeinformation er modtaget")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Sagsdata er modtaget")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Din kommentar er modtaget")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Det er en aftale")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Du tog ikke telefonen")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Henvendelse sat på hold")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("VI har arkiveret din henvendelse")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c0bbfdde", { render: render, staticRenderFns: staticRenderFns })
  }
}