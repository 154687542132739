<template>
  <div class="case-overview__block">
    <h3>Holdet på min sag</h3>
    <div class="case-responsibles">
      <div class="case-responsible" v-if="responsible">
        <div class="case-responsible__img">
          <img v-if="responsible.image" :src="responsible.image" />
          <img v-if="!responsible.image" src="/assets/img/client-icon.svg" />
        </div>
        <div class="case-responsible__content">
          <span class="case-responsible__text" v-text="responsible.fullname"></span>
          <span class="case-responsible__sub-text" v-text="responsible.title"></span>
          <router-link
          :to="{name: pageName, params: {modal: 'messages'}}"
          class="case-responsible__link"
          >Skriv besked</router-link>
        </div>
      </div>
      <div class="case-responsible" v-if="assistent">
        <div class="case-responsible__img">
          <img v-if="assistent.image" :src="assistent.image" />
          <img v-if="!assistent.image" src="/assets/img/client-icon.svg" />
        </div>
        <div class="case-responsible__content">
          <span class="case-responsible__text" v-text="assistent.fullname"></span>
          <span class="case-responsible__sub-text" v-text="assistent.title"></span>
          <router-link
            :to="{name: pageName, params: {modal: 'messages'}}"
            class="case-responsible__link"
          >Skriv besked</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: { type: Object }
  },
  computed: {
    responsible() {
      return this.order ? this.order.responsible : null;
    },
    assistent() {
      return this.order ? this.order.assistant : null;
    },
    pageName() {
      return this.partType == "part" ? "part-case" : "case";
    },
    partType() {
      return this.$store.state.parttype;
    }
  }
};
</script>