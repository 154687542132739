<template>
  <div>
    <company-name
      v-if="slide === 'company-name'"
      :company-product="companyData.companyProduct"
      @submit="setCompanyName"
      :company-name="companyData.companyName"
      :company-by-name="companyData.companyByName"
    ></company-name>

    <number-of-owners
      v-if="slide === 'number-of-owners'"
      @submit="setNumberOfOwners"
      :company-name="companyData.companyName"
      :number-of-owners="companyData.owners.length"
    ></number-of-owners>
    <template v-for="(owner, key) in this.companyData.owners">
      <owner-type
        v-if="slide === 'owner-type-' + key"
        :multiple-owners="multipleOwners"
        :key="key"
        :owner-number="key + 1"
        :owner-type="companyData.owners[key].type"
        @submit="(ownerType) => setOwnerType(ownerType, key)"
      ></owner-type>
      <owner-info
        v-if="slide === 'owner-info-' + key"
        :multiple-owners="multipleOwners"
        :key="key"
        :owner="companyData.owners[key]"
        :owner-number="key + 1"
        @submit="(ownerInfo) => setOwnerInfo(ownerInfo, key)"
      ></owner-info>
    </template>

    <choose-ceo
      v-if="slide === 'choose-ceo'"
      :company-name="companyData.companyName"
      :owners="companyData.owners"
      :ceo-data="companyData.ceo"
      @submit="setCeoOwner"
    ></choose-ceo>
    <company-address
      v-if="slide === 'company-address'"
      :company-name="companyData.companyName"
      :owners="companyData.owners"
      :company-address="companyData.address"
      @submit="setComapnyAddress"
    ></company-address>
    <company-goal
      v-if="slide === 'company-goal'"
      :company-name="companyData.companyName"
      :company-goal="companyData.goal"
      @submit="setCompanyGoal"
    ></company-goal>
    <financial-year
      v-if="slide === 'financial-year'"
      :company-name="companyData.companyName"
      :selected-date-range="financialdaterange"
      :financial-year="companyData.financial_year"
      @submit="setFinancialYear"
    ></financial-year>
    <capital
      v-if="slide === 'capital'"
      :company-name="companyData.companyName"
      :capital="companyData.nemreg_pricedata.capital"
      @submit="setCapital"
    ></capital>
    <incorportaion-rule
      v-if="slide === 'incorporation-rule'"
      :company-name="companyData.companyName"
      :selectedIncormationsRule="selectedIncorporationRule"
      @submit="setIncorporationRule"
    ></incorportaion-rule>
    <other-stuff
      v-if="slide === 'other-stuff'"
      :other-stuff="companyData.otherStuff"
      @submit="setOtherStuff"
    ></other-stuff>
  </div>
</template>

<script>
import CompanyName from "./slides/company-name.vue";
import NumberOfOwners from "./slides/number-of-owners.vue";
import OwnerType from "./slides/owner-type.vue";
import OwnerInfo from "./slides/owner-info.vue";
import ChooseCeo from "./slides/choose-ceo.vue";
import CompanyAddress from "./slides/company-address.vue";
import CompanyGoal from "./slides/company-goal.vue";
import OtherStuff from "./slides/other-stuff.vue";
import CompanyType from "./slides/company-type.vue";
import FinancialYear from "./slides/company-financial-year.vue";
import Capital from "./slides/capital.vue";
import IncorportaionRule from "./slides/incorportaion-rule.vue";

export default {
  props: {
    slide: { type: String, default: "company-name" },
    company: { type: Object, default: null },
    slideNumber: { tyoe: Number, default: 0 },
  },
  data() {
    return {
      intSlideNumber: 1,
      selectedIncorporationRule: -1,
      financialdaterange: "",
      companyData: {
        companyType: null,
        companyName: "",
        companyByName: [],
        owners: [],
        ceo: null,
        address: null,
        goal: "",
        financial_year: {
          start: "",
          end: "",
        },
        nemreg_pricedata: {
          type: "deponering",
          exchange_rate: 0,
          capital: 0,
          deposit: 0,
        },
        incorporation_rule: "",
        otherStuff: null,
        extra: {},
      },
    };
  },
  mounted() {
    this.updateCompanyData();
    this.$emit("percent", this.percentCompleted);
  },
  watch: {
    slideNumber(nextSlide, prevSlide) {
      if (nextSlide > prevSlide) {
        this.intSlideNumber += 1;
      } else {
        this.intSlideNumber -= 1;
      }
    },
    percentCompleted() {
      this.$emit("percent", this.percentCompleted);
    },
  },
  computed: {
    multipleOwners() {
      return this.companyData.owners.length > 1 ? true : false;
    },
    multipleCeo() {
      return this.companyData.ceo && this.companyData.ceo.filter((ceo) => ceo.selected).length > 1 ? true : false;
    },
    numberOfScreens() {
      let numberOfOwners = 2;
      if (this.companyData.owners.length > 0) {
        numberOfOwners = this.companyData.owners.length;
      }

      let screenNumber = 9;
      if(this.multipleCeo) {
        screenNumber += 1;
      }

      return screenNumber + numberOfOwners * 2;
    },
    percentCompleted() {
      let p = ((this.intSlideNumber / this.numberOfScreens) * 100).toFixed(0);
      return p < 100 ? parseInt(p) : 100;
    },
  },
  methods: {
    updateCompanyData() {
      if (this.company) {
        this.companyData = Object.assign({}, this.companyData, this.company);
        if (this.companyData.completed) {
          this.intSlideNumber = this.numberOfScreens;
        }
      }
    },
    navigateToSlide(slide, meta) {
      this.$emit("slide", slide);
    },
    goToSlide(slide) {
      this.navigateToSlide(slide);
    },
    setCompanyName(companyNameObj) {
      this.companyData.companyName = companyNameObj.companyName;
      this.companyData.companyByName = companyNameObj.companyByName;
      this.goToSlide("number-of-owners");
    },
    setNumberOfOwners(ownerNumber) {
      let owners = [];
      for (let i = 0; i < ownerNumber; i++) {
        owners.push({
          type: "",
          info: {},
        });
      }

      this.$set(this.companyData, "owners", owners);
      this.goToSlide("owner-type-0");
    },
    setOwnerType(ownerType, key) {
      let ownerData = this.companyData.owners[key];
      ownerData.type = ownerType;
      this.$set(this.companyData.owners, key, ownerData);
      this.goToSlide("owner-info-" + key);
    },
    setOwnerInfo(ownerInfo, key) {
      let ownerData = this.companyData.owners[key];
      ownerData.info = ownerInfo;
      this.$set(this.companyData.owners, key, ownerData);

      if (this.companyData.owners.length === key + 1) {
        this.goToSlide("choose-ceo");
      } else {
        this.goToSlide("owner-type-" + (key + 1));
      }
    },
    setCeoOwner(ceoData) {
      this.$set(this.companyData, "ceo", ceoData);
      this.goToSlide("company-address");
    },
    setComapnyAddress(addressData) {
      this.$set(this.companyData, "address", addressData);
      this.goToSlide("company-goal");
    },
    setCompanyGoal(goalTxt) {
      this.$set(this.companyData, "goal", goalTxt);
      this.goToSlide("financial-year");
    },
    setFinancialYear({ financialYear, selectedDateRange }) {
      this.financialdaterange = selectedDateRange;
      this.$set(
        this.companyData,
        "financial_year",
        Object.assign({}, financialYear)
      );
      this.goToSlide("capital");
    },
    setCapital({ capital }) {
      this.$set(
        this.companyData,
        "nemreg_pricedata",
        Object.assign({}, this.companyData.nemreg_pricedata, { capital })
      );

      if(this.multipleCeo) {
        this.goToSlide('incorporation-rule');
      } else {
        this.$set(this.companyData, "incorporation_rule", "Selskabet tegnes af en direktør");
        this.goToSlide("other-stuff");
      }
    },
    setIncorporationRule({ incorporationRule, selectedRule }) {
      this.selectedIncorporationRule = selectedRule;
      this.$set(this.companyData, "incorporation_rule", incorporationRule);
      this.goToSlide("other-stuff");
    },
    setOtherStuff(otherStuff) {
      this.$set(this.companyData, "otherStuff", otherStuff);
      this.setCompany();
    },
    setCompany() {
      this.$emit(
        "submit",
        Object.assign({}, this.companyData, { completed: true })
      );
    },
  },
  components: {
    "company-name": CompanyName,
    "number-of-owners": NumberOfOwners,
    "owner-type": OwnerType,
    "owner-info": OwnerInfo,
    "choose-ceo": ChooseCeo,
    "company-address": CompanyAddress,
    "company-goal": CompanyGoal,
    "other-stuff": OtherStuff,
    "company-type": CompanyType,
    FinancialYear,
    Capital,
    IncorportaionRule,
  },
};
</script>