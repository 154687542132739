var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lead
    ? _c(
        "div",
        {
          ref: "logmessages",
          staticClass: "lead-info lead-info--log-messages",
          class: { loading: _vm.loading }
        },
        [
          _vm.error
            ? _c("p", { staticClass: "error" }, [
                _vm._v(
                  "\n    Der skete en fejl og data kunne ikke hentes...\n    "
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--default",
                    on: { click: _vm.reload }
                  },
                  [_vm._v("Prøv igen")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "messages" },
            _vm._l(_vm.logList, function(logEntry) {
              return _c("status-log-message", {
                key: logEntry.id,
                attrs: {
                  statuslog: logEntry,
                  lead: _vm.lead,
                  containsCaseData: _vm.containsCaseData
                },
                on: {
                  contactmethod: _vm.setContactMethod,
                  setcontacttime: _vm.setContactTime,
                  viewcustomerinvoice: _vm.viewCustomerInvoice,
                  viewproductinvoice: _vm.viewProductInvoice,
                  comment: _vm.setComment,
                  viewcomment: _vm.viewComment,
                  setcustomerinfo: _vm.setCustomerInfo,
                  setcasedata: _vm.setCaseData,
                  addrelatedproducts: _vm.setRelatedProducts
                }
              })
            }),
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d60c0c52", { render: render, staticRenderFns: staticRenderFns })
  }
}