<template>
  <div
    class="profile-login profile-login--customer"
    :class="{ loading: loading }"
  >
    <div class="profile-login__wrapper">
      <div>
        <template v-if="view == 'intro'">
          <h2 class="profile-login__header">Nulstilling nødvendig</h2>
          <p class="profile-login__text">
            Du har som kunde tidligere modtaget et unikt link til NEMBLIK™ til
            din e-mail. Dette er din nemmeste vej ind. Har du mistet dette link,
            kan vi nulstille din adgang ved at validere dine oplysninger på ny.
            Tidligere links vil stoppe med at virke
          </p>
          <button class="btn" @click="view = 'resetcustomer'">Gå videre</button>
        </template>

        <template v-if="view == 'customerreset'">
          <h2 class="profile-login__header">Vi har nu sendt et link</h2>
          <p class="profile-login__text">
            Hvis e-mailen '{{ email }}' genkendes i vores system, vil du modtage
            en e-mail med et nyt link. <br />
            <br />
            Tjek din e-mail og følg linket. <br />
            <br />
            Modtager du ingen e-mail, skal du henvende dig til vores support på
            info@nemadvokat.dk
          </p>
        </template>

        <template v-if="view == 'resetcustomer'">
          <h2 class="profile-login__header">Vi skal bruge din e-mail</h2>
          <p class="profile-login__text">
            Indtast nedenfor den e-mailadresse du anvendte ved oprettelsen af
            din sag hos os. Genkender vi din e-mail i vores system, sender vi et
            nyt link til <b>NEMBLIK™</b>. <br />
            <br />
            Når du klikker på linket, sender vi en SMS til den mobiltelefon vi
            har registeret på dit kundekort.
          </p>
          <input-field
            name="E-mail"
            input-type="email"
            v-model="email"
            @enter="resetCustomer"
            :error="fieldErrors.indexOf('email') > -1 ? 'Skal udfyldes' : ''"
          ></input-field>
          <p class="error" v-if="error" v-html="error"></p>
          <button
            class="btn"
            @click="resetCustomer"
            :disabled="!email || loading"
          >
            Nulstil min adgang
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import InputField from "../input-field/input-field.vue";
import Datastore from "../../services/datastore.js";

export default {
  data() {
    return {
      email: "",
      view: "intro",
      error: null,
      fieldErrors: [],
      loading: false,
    };
  },
  methods: {
    resetCustomer() {
      this.fieldErrors = [];
      if (!this.email) {
        this.fieldErrors.push("email");
        this.error = "Du har ikke udfyldt alle felter";
      }

      if (this.loading || !this.email) return;

      this.error = null;
      this.loading = true;
      Datastore.customerReset(this.email)
        .then((res) => {
          this.loading = false;
          this.view = "customerreset";
        })
        .catch((error) => {
          this.loading = false;
          let res = error.response;
          if (res && res.data.code) {
            switch (res.data.code) {
              case "bad_request":
                this.fieldErrors.push("email");
                this.error = "Du har ikke udfyldt en korrekt e-mail adresse";
                break;
              default:
                this.error =
                  "Der skete en fejl under login! <br/> Kontakt NEMAdvokat for at få nulstillet din kode";
                break;
            }
          } else {
            this.error =
              "Der skete en fejl med forbindelsen! <br/> Tjek om du har forbindelse til internettet.";
          }
        });
    },
  },
  components: {
    "input-field": InputField,
  },
};
</script>