var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.msgList, function(message) {
      return _c("msg-item", {
        key: message.id,
        attrs: { message: message, part: _vm.part }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7cd40f6b", { render: render, staticRenderFns: staticRenderFns })
  }
}