var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case-droppoint" }, [
    _c(
      "div",
      { staticClass: "case-droppoint__text" },
      [
        _vm._t("default", function() {
          return [
            _c("p", [
              _vm._v(
                "Her kan du sikkert sende filer til din sag og sagsbehandler."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Træk de filer ud du ønsker at dele ind i boksen nedenfor, eller tryk på 'Vælg filer' for at finde dem på din enhed."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Herefter trykker du på uploadknappen for at sende dem til os. Vi får automatisk en notifikation, og går straks dine filer igennem."
              )
            ])
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _vm.uploadUrl
      ? _c(
          "div",
          { staticClass: "nem-dropzone" },
          [
            _c("vue-dropzone", {
              ref: "dropzone",
              attrs: { id: "dropzone", options: _vm.dropzoneOptions },
              on: {
                "vdropzone-queue-complete": _vm.finishUploading,
                "vdropzone-file-added": _vm.checkQueue,
                "vdropzone-files-added": _vm.checkQueue,
                "vdropzone-success": _vm.onSuccess,
                "vdropzone-error": _vm.onError
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.uploadUrl
      ? _c("div", { staticClass: "case-droppoint__text" }, [
          _vm.succes && !_vm.error
            ? _c("p", { staticClass: "case-droppoint__text__success" }, [
                _vm._v("Alle dine filer blev uploaded med success")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.succes && _vm.error
            ? _c("p", { staticClass: "case-droppoint__text__error" }, [
                _vm._v("Der skete en fejl da nogle af dine filer blev uploaded")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Filer du uploader skal være af typen pdf, jpg, png eller gif - filen kan være helt op til 40 MB stor."
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn",
              class: { loading: _vm.uploadingFiles },
              attrs: { disabled: _vm.uploadingFiles || !_vm.queueSize },
              on: { click: _vm.uploadFiles }
            },
            [_vm._v("Upload filer")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.nemadvokat.dk/datapolitik/",
                target: "_blank"
              }
            },
            [_vm._v("Vores datapolitik")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39e81b03", { render: render, staticRenderFns: staticRenderFns })
  }
}