var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kyc-view", class: { loading: _vm.loading } },
    [
      _vm.exists && !_vm.finished
        ? _c("kyc-modal", {
            attrs: { validation: _vm.validation, showCloseBtn: false },
            on: {
              close: function($event) {
                _vm.finished = true
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      (!_vm.loading && _vm.exists === false) || _vm.finished
        ? _c("div", { staticClass: "kyc-wrapper kyc-wrapper--status" }, [
            !_vm.loading && _vm.exists === false
              ? _c("p", {
                  staticClass: "error",
                  domProps: { textContent: _vm._s(_vm.translations.not_exists) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.finished
              ? _c("p", {
                  staticClass: "success",
                  domProps: { textContent: _vm._s(_vm.translations.success) }
                })
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-51978686", { render: render, staticRenderFns: staticRenderFns })
  }
}