var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement-letter" }, [
    _c("div", { staticClass: "agreement-letter__body" }, [
      _c("p", [
        _vm._v("Vi har nu beregnet den samlede tinglysningsafgift på din sag.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Tinglysningsafgiften udgør "),
        _c("span", {
          directives: [
            {
              name: "price",
              rawName: "v-price",
              value: _vm.order.registration_fee,
              expression: "order.registration_fee"
            }
          ]
        }),
        _vm._v(
          " Beløbet bedes indbetalt på vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Navngiv venligst overførslen " + _vm._s(_vm.order.caseid) + "TL"
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Vi kan ikke gennemføre tinglsyningen før afgiften er modtaget.")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Se sagens fulde aftalebrev/ordrebekræftigelse "),
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.$emit("showletter")
              }
            }
          },
          [_vm._v("her")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b19e940", { render: render, staticRenderFns: staticRenderFns })
  }
}