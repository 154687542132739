var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "order-nav",
      class: {
        "order-nav--show-case-btn":
          !_vm.showNewCase && !_vm.loading && _vm.partType == "customer"
      }
    },
    [
      _c("div", { staticClass: "order-nav__wrapper" }, [
        _c("div", { staticClass: "order-nav__header" }, [
          _c("h2", [_vm._v("Mine sager")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-nav__close", on: { click: _vm.hideOrders } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 200 200"
                  }
                },
                [
                  _c("line", {
                    attrs: { x1: "160.64", y1: "44", x2: "39.65", y2: "164.98" }
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: { x1: "39.65", y1: "44", x2: "160.64", y2: "164.98" }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "order-nav__body" },
          _vm._l(_vm.orderList, function(order) {
            return _c("order", {
              key: order.main_order_hash,
              attrs: { order: order }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "order-nav__footer" },
          [
            !_vm.loading && _vm.partAbleToCreateNewCase
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn--new-case",
                    on: { click: _vm.onShowNewCase }
                  },
                  [
                    _c("span", [_vm._v("Ny sag")]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 29.7 29.7"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M16.9,0h-4V12.9H0v4H12.9V29.7h4V16.9H29.7v-4H16.9Z"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn--logout",
                attrs: { to: { name: "logout" } }
              },
              [
                _c("span", [_vm._v("Log ud")]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 376.75 442.48"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M224.36,399.5a25,25,0,0,0-17.79-7.37H50.37V50.37h18l138.18,0a25.19,25.19,0,0,0,0-50.38H25.24A25.15,25.15,0,0,0,.08,25.16L0,417.27a25.16,25.16,0,0,0,25.16,25.16l181.37.05a25.17,25.17,0,0,0,17.83-43Z"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M369.39,203.5l-82.63-82.71a25.18,25.18,0,1,0-35.63,35.58l40.21,40.21H138a25.18,25.18,0,1,0,0,50.35H290.3l-39.17,39.18a25.18,25.18,0,1,0,35.63,35.58L369.39,239a25.06,25.06,0,0,0,0-35.48Z"
                      }
                    })
                  ]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "order-nav__background",
        on: { click: _vm.hideOrders }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-06d967cc", { render: render, staticRenderFns: staticRenderFns })
  }
}