<template>
  <div class="order-flow-progress">
    <div class="order-flow-progress__wrapper">
      <div class="order-flow-progress__bar">
        <div
          class="order-flow-progress__fill"
          :style="{ width: percentComplete + '%' }"
        ></div>
        <span class="progress-dot">
          <span class="progress-dot__fill"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentComplete: {
      type: Number,
      default: null,
    },
  },
};
</script>