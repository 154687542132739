var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-fields" }, [
    _c(
      "div",
      { staticClass: "input-fields__two" },
      [
        _c("date-picker", {
          attrs: {
            name: "Dato",
            "is-ready": true,
            "default-date": _vm.startDate,
            format: "DD-MM-YYYY"
          },
          on: { pickdate: _vm.updateDate }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "drop-down", staticStyle: { margin: "0" } }, [
          _c("label", { attrs: { for: "hour" } }, [_vm._v("Kl.")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.setTimeHour,
                  expression: "setTimeHour"
                }
              ],
              staticClass: "drop-down__select",
              attrs: { id: "hour" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.setTimeHour = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.hours, function(hour) {
              return _c("option", { domProps: { value: hour.key } }, [
                _vm._v("\n          " + _vm._s(hour.value) + "\n        ")
              ])
            }),
            0
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6cfe8511", { render: render, staticRenderFns: staticRenderFns })
  }
}