var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "label-input", class: { "error-shake": _vm.error } },
    [
      _vm.inputType == "text"
        ? _c("input", {
            directives: [
              {
                name: "ui-mask",
                rawName: "v-ui-mask",
                value: { mask: _vm.mask },
                expression: "{'mask': mask}"
              }
            ],
            ref: "inputField",
            staticClass: "input input-field",
            class: { "has-text": _vm.hasText },
            attrs: { type: "text", name: _vm.fieldkey, disabled: _vm.disable },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onInput,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                },
                _vm.onKey
              ],
              click: _vm.onClick,
              blur: _vm.onBlur
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType == "email"
        ? _c("input", {
            directives: [
              {
                name: "ui-mask",
                rawName: "v-ui-mask",
                value: { mask: _vm.mask },
                expression: "{'mask': mask}"
              }
            ],
            ref: "inputField",
            staticClass: "input input-field",
            class: { "has-text": _vm.hasText },
            attrs: { type: "email", name: _vm.fieldkey, disabled: _vm.disable },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onInput,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                },
                _vm.onKey
              ],
              click: _vm.onClick,
              blur: _vm.onBlur
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType == "password"
        ? _c("input", {
            directives: [
              {
                name: "ui-mask",
                rawName: "v-ui-mask",
                value: { mask: _vm.mask },
                expression: "{'mask': mask}"
              }
            ],
            ref: "inputField",
            staticClass: "input input-field",
            class: { "has-text": _vm.hasText },
            attrs: {
              type: "password",
              name: _vm.fieldkey,
              disabled: _vm.disable
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onInput,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                },
                _vm.onKey
              ],
              click: _vm.onClick,
              blur: _vm.onBlur
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType == "tel"
        ? _c("input", {
            directives: [
              {
                name: "ui-mask",
                rawName: "v-ui-mask",
                value: { mask: _vm.mask },
                expression: "{'mask': mask}"
              }
            ],
            ref: "inputField",
            staticClass: "input input-field",
            class: { "has-text": _vm.hasText },
            attrs: { type: "tel", name: _vm.fieldkey, disabled: _vm.disable },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onInput,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onEnter.apply(null, arguments)
                },
                _vm.onKey
              ],
              click: _vm.onClick,
              blur: _vm.onBlur
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputType == "textarea"
        ? _c(
            "textarea",
            {
              directives: [
                {
                  name: "ui-mask",
                  rawName: "v-ui-mask",
                  value: { mask: _vm.mask },
                  expression: "{'mask': mask}"
                }
              ],
              ref: "inputField",
              staticClass: "input input-field",
              class: { "has-text": _vm.hasText },
              attrs: { name: _vm.fieldkey, disabled: _vm.disable },
              on: {
                input: _vm.onInput,
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onEnter.apply(null, arguments)
                  },
                  _vm.onKey
                ],
                click: _vm.onClick,
                blur: _vm.onBlur
              }
            },
            [_vm._v(_vm._s(_vm.value))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isRemovable
        ? _c(
            "button",
            {
              staticClass: "label-input__btn-remove",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onRemove.apply(null, arguments)
                }
              }
            },
            [
              _c("img", {
                attrs: { src: "/assets/img/icons/icon-close.svg", alt: "" }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _vm.error
        ? _c("div", [
            _c("span", { staticClass: "tag-label" }, [
              _vm._v(_vm._s(_vm.errormsg))
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f938eff", { render: render, staticRenderFns: staticRenderFns })
  }
}