var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "kyc-modal",
      class: { loading: _vm.loading },
      attrs: {
        title: "Identifikation",
        show: true,
        showClose: _vm.showCloseBtn,
        showBackBtn: _vm.showBackBtn
      },
      on: { close: _vm.onClose, back: _vm.onGoBack }
    },
    [
      _c("order-flow-progress", {
        attrs: { percentComplete: _vm.percentComplete }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "language-selector",
          attrs: { slot: "header_right" },
          slot: "header_right"
        },
        [
          _c(
            "button",
            {
              staticClass: "language-selector__btn",
              class: { "language-selector__btn--active": _vm.language == "da" },
              on: {
                click: function($event) {
                  _vm.language = "da"
                }
              }
            },
            [_vm._v("\n      DK\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "language-selector__btn",
              class: { "language-selector__btn--active": _vm.language == "en" },
              on: {
                click: function($event) {
                  _vm.language = "en"
                }
              }
            },
            [_vm._v("\n      UK\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _vm.slide == "intro"
        ? _c("intro-text", {
            attrs: {
              ready: _vm.uid ? true : false,
              translations: _vm.translations.intro_text
            },
            on: { submit: _vm.submitIntro }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide == "id-selector"
        ? _c("id-selector", {
            attrs: {
              translations: _vm.translations.id_selector,
              "validation-types": _vm.validationTypes,
              "selected-types": _vm.selectedValidationMethods,
              uid: _vm.uid,
              "order-hash": _vm.orderHash
            },
            on: { submit: _vm.submitValidationMethod }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide == "esignatur"
        ? _c("esignatur", {
            attrs: {
              translations: _vm.translations.esignatur,
              "validation-method": _vm.lastValidationMethod
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide == "upload-file"
        ? _c("upload-file", {
            attrs: {
              translations: _vm.translations.upload_file,
              uid: _vm.uid,
              "validation-method": _vm.lastValidationMethod,
              "validation-types": _vm.validationTypes
            },
            on: { submit: _vm.submitUpload }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79ded7c8", { render: render, staticRenderFns: staticRenderFns })
  }
}