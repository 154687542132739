<template>
  <modal
    :title="title"
    :show="true"
    @close="onClose"
    :showActions="true"
    class="base-modal-nemblik--half"
  >
    <div class="new-case-product" v-if="slide === 'select-product'">
      <div class="new-case-product__header">
        <img
          :src="'/assets/img/icons/' + product.icon"
          alt
          class="case-item__icon"
        />
        <h3 class="case-item__title" v-text="product.title"></h3>
      </div>
      <p v-if="product.description" v-text="product.description"></p>
      <div class="new-case-product__body">
        <div class="book-meeting" v-if="bookMeeting">
          <date-time-input v-model="contactTime"></date-time-input>
        </div>
        <input-field
          name="Bemærkninger til sag"
          input-type="textarea"
          :value="comment"
          @input="(inp) => (comment = inp)"
        />
        <p class="error" v-if="error">
          Der skete desværre en fejl da sagen skulle oprettes! Prøv igen eller
          kontakt NEMADVOKAT direkte...
        </p>
      </div>
    </div>
    <div class="new-case__select-finished" v-if="slide === 'finished'">
      <p>Sagen er nu modtaget.</p>
      <p>
        Den vil blive oprettet indenfor 24 timer hvorefter der vil kunne
        uploades dokumenter til sagen
      </p>
    </div>

    <div slot="actions" class="actions-full-width">
      <template v-if="slide === 'select-product'">
        <button
          class="btn btn--positive btn--full-width"
          :class="{ loading: loading }"
          @click="orderCase"
          :disabled="!valid || loading"
        >
          Send
        </button>
      </template>
      <template v-if="slide === 'finished'">
        <button class="btn btn--positive" @click="onClose">Ok</button>
      </template>
    </div>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import DateTimeInput from "./../input-field/date-time-input.vue";
import InputField from "./../input-field/input-field.vue";
import DataStore from "../../services/datastore";

export default {
  props: {
    customer: { type: Object, default: null },
    product: { type: Object, default: null },
    bookMeeting: { type: Boolean, default: false },
    campaignSourceId: {type: Number, default: null}
  },
  data() {
    return {
      slide: "select-product",
      loading: false,
      comment: "",
      error: false,
      contactTime: null,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    orderCase() {
      if (this.loading) return;

      this.loading = true;
      this.error = false;
      let email = this.customer.customer_email || this.customer.email;
      let order_type = this.partType == "part" ? 4 : 3;

      let leadData = {
        name: this.customer.name,
        email,
        phone: this.customer.phone,
        comment: this.comment,
        type: this.product.type,
        system: this.product.system,
        order_type,
        campaign_source_id: this.partCampaignSourceID,
      };

      if (this.bookMeeting) {
        leadData.contact_time = this.contactTime;
      }

      if(this.product.campaign_id) {
        leadData.campaign_id = this.product.campaign_id;
      }

      DataStore.createLead(leadData)
        .then(({ data }) => {
          if (data.status == "error") {
            return Promise.reject(data.code);
          }
          this.loading = false;
          this.slide = "finished";
          this.comment = "";
          return Promise.resolve();
        })
        .catch((err) => {
          console.error(err);
          this.error = true;
          this.loading = false;
        });
    },
  },
  computed: {
    title() {
      if (!this.product.title) {
        return "Opret sag";
      }

      return "Opret sag: " + this.product.title;
    },
    partType() {
      return this.$store.state.parttype;
    },
    partCampaignSourceID() {
      switch (this.partType) {
        case "part":
          return this.$store.state.part.campaign_source_id
            ? this.$store.state.part.campaign_source_id
            : null;
        default:
          return this.campaignSourceId;
      }
    },
    valid() {
      if (this.bookMeeting && !this.contactTime) {
        return false;
      }

      return true;
    },
  },
  components: {
    InputField,
    Modal,
    DateTimeInput,
  },
};
</script>