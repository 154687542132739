<template>
  <div
    class="order-nav"
    :class="{
      'order-nav--show-case-btn':
        !showNewCase && !loading && partType == 'customer',
    }"
  >
    <div class="order-nav__wrapper">
      <div class="order-nav__header">
        <h2>Mine sager</h2>
        <div class="order-nav__close" @click="hideOrders">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <line x1="160.64" y1="44" x2="39.65" y2="164.98" />
            <line x1="39.65" y1="44" x2="160.64" y2="164.98" />
          </svg>
        </div>
      </div>
      <div class="order-nav__body">
        <order
          v-for="order in orderList"
          :order="order"
          :key="order.main_order_hash"
        ></order>
      </div>
      <div class="order-nav__footer">
        <button
          class="btn btn--new-case"
          v-if="!loading && partAbleToCreateNewCase"
          @click="onShowNewCase"
        >
          <span>Ny sag</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.7 29.7">
            <path d="M16.9,0h-4V12.9H0v4H12.9V29.7h4V16.9H29.7v-4H16.9Z" />
          </svg>
        </button>
        <router-link :to="{ name: 'logout' }" class="btn btn--logout">
          <span>Log ud</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.75 442.48">
            <path
              d="M224.36,399.5a25,25,0,0,0-17.79-7.37H50.37V50.37h18l138.18,0a25.19,25.19,0,0,0,0-50.38H25.24A25.15,25.15,0,0,0,.08,25.16L0,417.27a25.16,25.16,0,0,0,25.16,25.16l181.37.05a25.17,25.17,0,0,0,17.83-43Z"
            />
            <path
              d="M369.39,203.5l-82.63-82.71a25.18,25.18,0,1,0-35.63,35.58l40.21,40.21H138a25.18,25.18,0,1,0,0,50.35H290.3l-39.17,39.18a25.18,25.18,0,1,0,35.63,35.58L369.39,239a25.06,25.06,0,0,0,0-35.48Z"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <div class="order-nav__background" @click="hideOrders"></div>
  </div>
</template>

<script>
import Order from "./order-item.vue";

export default {
  data() {
    return {
      customer: {},
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route(to, from) {
      if (to.params.hash && to.params.hash != from.params.hash) {
        this.loadData();
      }

      this.hideOrders();
    },
    loadedOrder() {
      this.updateOrders();
    },
    loading() {
      this.$emit('loading', this.loading);
    } 
  },
  methods: {
    hideOrders() {
      this.$store.commit("showorders", false);
    },
    onShowNewCase() {
      this.$router.push({
        name:
          this.partType == "customer" ? "profile-new-case" : "part-new-case",
      });
    },
    updateOrders() {
      if (!this.customer.orders) {
        return;
      }

      let order = this.customer.orders.filter(
        (order) => order.main_order_hash == this.loadedOrder.main_order_hash
      );
      if (order[0]) {
        let idx = this.customer.orders.indexOf(order[0]);
        if (idx > -1) {
          let orders = Object.assign([], this.customer.orders);
          orders[idx] = Object.assign({}, this.loadedOrder);
          this.$set(this.customer, "orders", orders);
        }
      }
    },
    loadData() {
      if (this.loading) return;

      let hash = this.$route.params.hash;
      this.loading = true;
      this.$store
        .dispatch("getpart", {
          hash,
          loadCases: true,
        })
        .then((customer) => {
          this.loading = false;
          this.customer = customer;

          if (this.activeCases.length == 1) {
            this.showOrder(this.activeCases[0].main_order_hash);
            this.hideOrders();
          } else if (this.allIsArchived && this.partAbleToCreateNewCase) {
            this.onShowNewCase();
          } else {
            this.$store.commit("showorders", true);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$router.push({ path: "/" });
        });
    },
    showOrder(orderhash) {
      let name = this.partType == "part" ? "part-case" : "case";
      let modal = this.partType == "part" ? "messages" : null;
      this.$router.push({
        name,
        params: {
          orderhash,
          modal,
        },
        query: this.$route.query,
      });
    },
  },
  computed: {
    loadedOrder() {
      return this.$store.state.order;
    },
    showNewCase() {
      return this.$route.name == "profile-new-case" ||
        this.$route.name == "part-new-case"
        ? true
        : false;
    },
    orderHeader() {
      return this.$route.params.orderhash ? true : false;
    },
    partType() {
      return this.$store.state.parttype;
    },
    partAbleToCreateNewCase() {
      if (
        this.partType === "customer" &&
        this.$store.state.part.customer_department > 0
      ) {
        return false;
      }

      return this.$store.state.part.phone ? true : false;
    },
    orderList() {
      return this.customer && this.customer.orders
        ? this.customer.orders.filter(
            (order) => order.order_state != "inactive"
          )
        : [];
    },
    activeCases() {
      return this.orderList.filter(
        (order) => order.order_state == "active"
      );
    },
    allIsArchived() {
      let archivedOrders = this.orderList.filter(
        (order) => order.order_state == "archived"
      );
      return this.orderList.length == archivedOrders.length;
    },
  },
  components: {
    Order,
  },
};
</script>