var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--1",
      attrs: {
        title: "Hvad skal selskabet hedde?",
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("input-field", {
            attrs: { name: "Selskabsnavn", value: _vm.intCompanyName },
            on: {
              input: function(val) {
                _vm.intCompanyName = val
              },
              enter: function($event) {
                return _vm.submit()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "order-flow__content__expansion order-flow__content__expansion--auto-height",
              class: {
                "order-flow__content__expansion--open":
                  _vm.intCompanyByNames.length
              }
            },
            _vm._l(_vm.intCompanyByNames, function(byname, key) {
              return _c("input-field", {
                key: key,
                attrs: { isRemovable: true, name: "Binavn", value: byname },
                on: {
                  remove: function($event) {
                    return _vm.intCompanyByNames.splice(key, 1)
                  },
                  input: function(val) {
                    return _vm.$set(_vm.intCompanyByNames, key, val)
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.intCompanyByNames.length < 5
            ? _c(
                "button",
                {
                  staticClass: "order-flow__expand-btn",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.intCompanyByNames.push("")
                    }
                  }
                },
                [_vm._v("Tilføj binavn")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-db47ee54", { render: render, staticRenderFns: staticRenderFns })
  }
}