<template>
  <modal v-if="product" :title="product.product.title" :show="true" :noScroll="false" @close="onClose" :showActions="true" class="receipt-modal">
    <invoice
      v-if="lead && product"
      :show-header="false"
      :lead="lead"
      :product="product"
      @updated="(udpatedLead) => $emit('input', udpatedLead)"
      @close="onClose"
    ></invoice>
    <button slot="actions" class="btn btn--show" @click="onClose">Luk</button>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import Invoice from "./../create-company-order/slides/final-order.vue";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  methods: {
    onClose() {
      this.$router.push({ name: "lead-view", view: null, slide: null });
    },
  },
  computed: {
    product() {
      if(!this.lead || !this.lead.products) {
        return null;
      }

      let product = this.lead.products.filter(
        (product) => product.id == this.$route.query.productid
      );
      return product.length ? product[0] : null;
    },
  },
  components: {
    Modal,
    Invoice,
  },
};
</script>