<template>
  <modal
    title="Fremsendelse af ID"
    @close="$emit('close')"
    :show="true"
    :showActions="true"
  >
    <div class="validate-modal">
      <div class="validate-modal__box">
        <h2>Identifikation</h2>
        <p>
          Vi er, som advokater, underlagt hvidvaskloven. Vi er således
          forpligtet til at validere vores klienters identitet. Vi må ikke
          påbegynde arbejdet, førend vi har modtaget legitimationsoplysninger.
        </p>
        <p>
          I jeres tilfælde, skal vi have identificeret virksomhedens reelle
          ejere og modtage ID på disse. Nedenfor kan du se, hvilke reelle ejere
          vi har identificeret og status på legitimationsprocessen.
        </p>
        <div :class="{ loading }">
          <table class="validation-table" v-if="!loading">
            <tbody>
              <tr>
                <th>Navn</th>
                <th>Status</th>
                <th>Link</th>
              </tr>
              <tr v-for="owner in ownerList" :key="owner.name">
                <td v-text="owner.name"></td>
                <td v-text="owner.status"></td>
                <td>
                  <a v-if="owner.link" :href="owner.link" target="_blank"
                    >Åben link</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <p class="error" v-if="error" v-text="error"></p>
        </div>
        <div class="deposit-info">
          <h3 class="deposit-info__heading">Praktisk information</h3>
          <img
            src="/assets/img/icons/icon-info.svg"
            class="deposit-info__icon"
          />
          <p>
            Har du spørgsmål til fremsendelse af ID, kan disse rettes til
            sagsbehandleren på din sag.
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./modal/base-modal.vue";
import Datastore from "./../services/datastore";

export default {
  props: {
    customer: { type: Object },
  },
  data() {
    return {
      loading: false,
      realOwners: [],
      error: "Test",
    };
  },
  watch: {
    "customer.unique_hash"() {
      this.getRealOwners();
    },
  },
  mounted() {
    this.getRealOwners();
  },
  methods: {
    getRealOwners() {
      if (!this.customer) {
        return;
      }

      this.loading = true;
      this.error = "";
      Datastore.getCustomerRealOwners(this.customer.unique_hash)
        .then(({ data }) => {
          this.realOwners = data;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.error = "Data kunne ikke hentes!";
          this.loading = false;
        });
    },
  },
  computed: {
    ownerList() {
      return this.realOwners.map(({ name, kyc_validation }) => {
        let status = "Link ikke genereret";
        let link = "";
        if (kyc_validation) {
          status = "Afventer";
          link = this.$router.resolve({
            name: "kyc-view",
            params: { uid: kyc_validation.uid_hash },
          }).href;

          if (kyc_validation.validations) {
            let completedValidationCount = kyc_validation.validations.filter(
              ({ ready }) => (ready ? true : false)
            ).length;

            if (completedValidationCount >= 2) {
              status = "Godkendt";
              link = "";
            }
          }
        }

        return {
          name,
          status,
          link,
        };
      });
    },
  },
  components: {
    Modal,
  },
};
</script>