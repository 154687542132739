<template>
    <slide title="Vælg selskabstype for holdingselskab" class="order-flow__wrapper--3" @next="submit()" :validated="isValid" :overwrite-save-btn="true">
        <template slot="content">
            <div class="order-tiles" :class="{'order-tiles--selection': isValid}">
                <div class="order-tiles__item order-tiles__item--2" :class="{'order-tiles__item--selected': (intCompanyType === 'ivs')}" @click="selectType('ivs')">
                    <img src="/assets/img/icons/icon-company-2.svg" alt="Ikon af selskab">
                    <span class="order-tiles__item__heading">IVS</span>
                </div>
                <div class="order-tiles__item order-tiles__item--3" :class="{'order-tiles__item--selected': (intCompanyType === 'aps')}" @click="selectType('aps')">
                    <img src="/assets/img/icons/icon-company-2.svg" alt="Ikon af selskab">
                    <span class="order-tiles__item__heading">APS</span>
                </div>
            </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
export default {
  props: {
      companyType: {type: String, default: null}
  },
  data() {
      return {
          intCompanyType: null
      }
  },
  mounted() {
      this.intCompanyType = this.companyType;
  },
  watch: {
      companyType() {
          this.intCompanyType = this.companyType;
      }
  },
  methods: {    
      selectType(type) {
          this.intCompanyType = type;
      },
      submit() {
        this.$emit('submit', this.intCompanyType);  
      }
  },
  computed: {
    isValid() {
        return (this.intCompanyType) ? true : false;
    }
  },
  components: {
    'slide': Slide 
  }
}
</script>

