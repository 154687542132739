<template>
  <modal title="Send en kommentar" :show="true" :noScroll="false" @close="onClose" ref="modal">
    <div class="lead-comment">
      <div class="lead-comment__textarea">
        <input-field input-type="textarea" v-model="comment"></input-field>
      </div>
      <button
        class="btn btn--positive"
        :class="{ loading: loading }"
        @click="sendComment"
        :disabled="comment.length <= 0"
      >
        Send
      </button>
      <p v-if="error">
        Der skete en fejl da kommentaren skulle sendes - prøv igen
      </p>
    </div>
  </modal>
</template>

<script>
import InputField from "./../input-field/input-field.vue";
import DataStore from "../../services/datastore";
import Modal from "./../modal/base-modal.vue";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      comment: "",
      loading: false,
      error: false,
    };
  },
  methods: {
    onClose() {
      this.$router.push({
        name: "lead-view",
        params: { view: null, slide: null },
      });
    },
    sendComment() {
      if (!this.comment) return;

      this.error = false;
      this.loading = true;
      DataStore.addLeadComment(this.lead.hash, { comment: this.comment })
        .then(({ data }) => {
          let sendcomments = Object.assign(
            [],
            this.$store.state.sendLeadComments
          );
          sendcomments.push({
            id: data.id,
            comment: this.comment,
          });
          this.$store.commit("sendLeadComments", sendcomments);
          this.$emit(
            "input",
            Object.assign({}, this.lead)
          );
          this.$nextTick(() => {
            this.$router.push({ name: "lead-view", params: { view: null } });
          });
        })
        .catch((err) => {
          console.error(err);
          this.error = true;
        });
    },
  },
  components: {
    InputField,
    Modal,
  },
};
</script>