<template>
  <div class="case-documents">
    <div class="documents-table" :class="{ loading: loading }">
      <table-data
        v-if="!loading"
        :headers="headers"
        :table-data="fileList"
        @click="openFile"
      ></table-data>
    </div>
    <div class="case-documents_text" v-if="orderActive">
      <p>
        Vil du uploade et dokument til os skal du benytte
        <a href="javascript:void(0)" @click="showUpload = true">upload</a>
      </p>
    </div>
    <modal
      :show="true"
      title="Upload ny fil"
      :no-scroll="true"
      @close="showUpload = false"
      v-if="showUpload"
    >
      <case-droppoint v-if="showUpload" :uploadUrl="upoloadUrl"></case-droppoint>
    </modal>
  </div>
</template>

<script>
import API from "./../../services/datastore.js";
import TableData from "./../table/table.vue";
import Moment from "moment";
import CaseDroppoint from "./case-droppoint.vue";
import Modal from "../modal/base-modal.vue";

export default {
  props: {
    hash: { type: String, default: null },
    partHash: { type: String, default: null },
    orderActive: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      showUpload: false,
      sharedFiles: [],
      headers: {
        Filnavn: "name",
        Ændret: "updated_at",
      },
    };
  },
  watch: {
    hash() {
      if (this.hash) {
        this.loadSharedFiles();
      }
    },
    showUpload(newValue, oldValue) {
        if(!newValue && oldValue && this.hash) {
            this.loadSharedFiles();
        } 
    }
  },
  mounted() {
    window.messageBus.$on(
      "upload_headerbtn_click",
      this.onUploadHeaderClick.bind(this)
    );
    if (this.hash) {
      this.loadSharedFiles();
    }
  },
  beforeDestroy() {
    window.messageBus.$off(
      "upload_headerbtn_click",
      this.onUploadHeaderClick.bind(this)
    );
  },
  methods: {
    onUploadHeaderClick() {
      this.showUpload = true;
    },
    loadSharedFiles() {
      if (this.loading) return;

      this.loading = true;
      API.getSharedFiles(this.hash, this.partHash)
        .then((res) => {
          this.loading = false;
          if (!res.data.status) {
            this.sharedFiles = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    openFile(file) {
      if (this.loading) return;

      this.loading = true;
      let win = null;
      if (!window.navigator || !window.navigator.msSaveOrOpenBlob) {
        win = window.open("");
        win.document.write("Indlæser...");
        win.document.title = "Indlæser...";
      }

      console.log(file);
      API.getSharedFileByFileId(this.hash, file.file_id, this.partHash)
        .then((blob) => {
          this.loading = false;
          if (!win) {
            window.navigator.msSaveOrOpenBlob(blob, file.name);
          } else {
            win.location = URL.createObjectURL(blob);
          }
        })
        .catch((error) => {
          this.loading = false;
          win.close();
          console.log(error);
          alert("Der skete en fejl da filen skulle vises!");
        });
    },
  },
  computed: {
    fileList() {
      return this.sharedFiles.map((file) => {
        return {
          name: file.file.name,
          updated_at: Moment(
            file.file.updated_at,
            "DD-MM-YYYY HH:mm:ss"
          ).format("ll"),
          file_id: file.file_id,
        };
      });
    },
    upoloadUrl() {
      return this.hash
        ? API.getUploadUrl(this.hash, this.partHash)
        : null;
    },
  },
  components: {
    TableData,
    CaseDroppoint,
    Modal,
  },
};
</script>