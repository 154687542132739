<template>
  <div class="case-tasks case-overview__block" v-if="order && customer">
    <h3
      class="case-tasks__heading"
      v-if="
        (order.has_letter && !order.letter_approved) ||
        (!customer.has_signed && !customer.cpr_validated)
      "
    >
      Før sagen kan fortsætte
    </h3>
    <div
      class="task-item task-item--interactive"
      :class="{ 'task-item--success': order.letter_approved }"
      @click="showContractTableModal = true"
      v-if="order && order.new_type && order.has_letter"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 102.51 102.51"
        class="task-item__icon"
      >
        <rect x="13.07" y="36.38" width="13.41" height="2.72" rx="1" />
        <rect x="31.49" y="36.38" width="14.41" height="2.72" rx="1" />
        <path
          d="M87.76,23a1.77,1.77,0,0,0-.46,0H76.43l-.24,0L76,23H3.93V81H98.84V23ZM96,78.18H6.76V25.79h68v17a1.41,1.41,0,0,0,1.41,1.41,1.36,1.36,0,0,0,.85-.28l4.81-3.6,4.81,3.6a1.42,1.42,0,0,0,2-.28,1.44,1.44,0,0,0,.28-.85v-17H96Z"
        />
        <path
          d="M14.51,52.66a1.42,1.42,0,1,0,0,2.83H57.79a1.42,1.42,0,0,0,0-2.83H14.51Z"
        />
        <path
          d="M89.05,52.5H74.73a1.42,1.42,0,0,0-1.43,1.4V68.35a1.4,1.4,0,0,0,1.39,1.43H89a1.42,1.42,0,0,0,1.44-1.39s0,0,0-.07,0,0,0-.06V53.94A1.43,1.43,0,0,0,89.05,52.5ZM87.62,67H76.12V55.33h11.5Z"
        />
        <path
          d="M14.51,59.73a1.42,1.42,0,1,0,0,2.83H50.72a1.42,1.42,0,0,0,0-2.83H14.51Z"
        />
        <path
          d="M14.51,66.8a1.42,1.42,0,1,0,0,2.83H57.79a1.42,1.42,0,0,0,0-2.83H14.51Z"
        />
      </svg>
      <div class="task-item__text">
        <span>Godkend vores aftalebrev</span>
        <img src="/assets/img/icons/icon-attention.svg" />
        <img src="/assets/img/icons/icon-validated.svg" class="img-success" />
      </div>
    </div>

    <div
      class="task-item"
      v-if="customer && hasValidated !== null"
      :class="{
        'task-item--success': customerIsValidated,
        'task-item--interactive': !customerIsValidated,
      }"
      @click="nemIdValidate"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 102.51 102.51"
        class="task-item__icon"
      >
        <path
          d="M27.27,52.68h.25a7.52,7.52,0,0,0,5.75-2.5c3.09-3.48,2.6-9.38,2.54-10-.2-4.35-2.27-6.42-4-7.39a9,9,0,0,0-4.36-1.15h-.14A9,9,0,0,0,23,32.75c-1.72,1-3.81,3-4,7.36-.1,1.09-.47,6.67,2.54,10.07A7.5,7.5,0,0,0,27.27,52.68Zm.15-18.4c4.77.11,5.65,3.82,5.76,6.1h0c0,.25.49,5.37-1.88,8A5,5,0,0,1,27.35,50a4.87,4.87,0,0,1-3.87-1.6c-2.35-2.64-1.91-7.81-1.89-8v-.12C21.86,35.06,25.31,34.29,27.42,34.28ZM27.51,52Zm-.16-19.65Z"
        />
        <path
          d="M43.86,64.16V64h-.7l.7-.12c-.05-1.61-.16-5.39-3.75-6.61l-.11,0a22,22,0,0,1-6.46-3,1.37,1.37,0,0,0-1.83.33,1.33,1.33,0,0,0-.22,1,1.28,1.28,0,0,0,.54.85,24.68,24.68,0,0,0,7.27,3.32c1.68.6,1.87,2.48,1.92,4.19,0,.09,0,.15,0,.19a14.88,14.88,0,0,1-.14,2.27A28.77,28.77,0,0,1,27.4,69.61a28.89,28.89,0,0,1-13.69-3.15,14.55,14.55,0,0,1-.13-2.34V64c.05-1.71.25-3.6,1.91-4.19a24.88,24.88,0,0,0,7.3-3.34,1.29,1.29,0,0,0,.53-.84,1.33,1.33,0,0,0-.22-1,1.37,1.37,0,0,0-1.85-.32,21.93,21.93,0,0,1-6.46,2.94l-.1,0c-3.58,1.23-3.69,5-3.74,6.63a1.87,1.87,0,0,1,0,.21,12.06,12.06,0,0,0,.42,3.67,1.23,1.23,0,0,0,.5.62c.23.15,5.88,3.82,15.55,3.82,9.26,0,14.92-3.42,15.54-3.82a1.28,1.28,0,0,0,.51-.62A13.21,13.21,0,0,0,43.86,64.16Z"
        />
        <rect x="50.32" y="40.36" width="21.67" height="2.72" rx="1" />
        <rect x="50.11" y="49.61" width="33.83" height="2.72" rx="1" />
        <rect x="50.11" y="58.86" width="14.41" height="2.72" rx="1" />
        <rect x="69.53" y="58.86" width="14.41" height="2.72" rx="1" />
        <path
          d="M43.49,22.06H3.67V80.45H98.84V22.06ZM96.12,77.73H6.39V24.78H96.12Z"
        />
      </svg>
      <div class="task-item__text">
        <span v-if="!customerIsValidated">{{
          customer.is_company ? "Fremsend ID" : "Fremsend ID"
        }}</span>
        <span v-else-if="customerIsValidated">Valideret ID</span>
        <img src="/assets/img/icons/icon-attention.svg" />
        <img src="/assets/img/icons/icon-validated.svg" class="img-success" />
      </div>
    </div>
    <kyc-modal
      v-if="showValidateWindow"
      :validation="validation"
      :order="order"
      @close="onCloseValidationWindow"
    ></kyc-modal>
    <company-validate-modal
      v-if="showValidateCompanyModal"
      @close="onCloseValidationWindow"
      :customer="customer"
    ></company-validate-modal>
    <contract-table-modal
      v-if="showContractTableModal"
      @close="showContractTableModal = false"
      :order="order"
      :customer="customer"
    ></contract-table-modal>
  </div>
</template>

<script>
import CompanyValidateModal from "./../company-validate-modal.vue";

import ContractTableModal from "./../contract/contract-table-modal.vue";
import KycModal from "./../kyc-flow/kyc-modal.vue";
import Datastore from "./../../services/datastore";

export default {
  props: {
    order: { type: Object },
    customer: { type: Object },
  },
  data() {
    return {
      showValidateWindow: false,
      showValidateCompanyModal: false,
      showContractTableModal: false,
      hasValidated: null,
      validation: null,
    };
  },
  watch: {
    "customer.unique_hash"() {
      this.checkHasValidated();
    },
  },
  mounted() {
    this.checkHasValidated();
  },
  methods: {
    onCloseValidationWindow() {
      this.$router.replace({ query: { kyccompleted: null } });
      this.checkHasValidated();
      this.showValidateWindow = false;
      this.showValidateCompanyModal = false;
    },
    checkHasValidated() {
      if (!this.customer) {
        return;
      }

      this.hasValidated = null;

      if (this.customer.is_company) {
        Datastore.getCustomerRealOwners(this.customer.unique_hash)
          .then(({ data }) => {
            this.hasValidated =
              data.length ==
              data.filter(({ kyc_validation }) => {
                return (
                  kyc_validation &&
                  kyc_validation.validations &&
                  kyc_validation.validations.filter(({ ready }) =>
                    ready ? true : false
                  ).length
                );
              }).length
                ? true
                : false;
          })
          .catch((err) => {
            console.error(err);
            this.hasValidated = false;
          });
      } else {
        if (this.$route.query.kyccompleted) {
          this.showValidateWindow = true;
        }

        if(this.customer && this.customer.cpr_validated) {
          this.hasValidated = true;
          return;
        }

        Datastore.getCustomerKYCValidation(this.customer.unique_hash)
          .then(({ data }) => {
            this.hasValidated =
              data.validations.filter(({ ready }) => ready).length >= 2;
            this.validation = data;
          })
          .catch((err) => {
            console.error(err);
            this.hasValidated = false;
          });
      }
    },
    nemIdValidate() {
      if (this.customerIsValidated === false) {
        if (this.customer.is_company) {
          this.showValidateCompanyModal = true;
        } else {
          this.showValidateWindow = true;
          this.validation = null;
          Datastore.createCustomerKYCValidation(this.customer.unique_hash)
            .then(({ data }) => {
              this.validation = data;
            })
            .catch((err) => {
              console.error(err);
              this.showValidateWindow = false;
            });
        }
      }
    },
  },
  computed: {
    customerIsValidated() {
      return (
        this.hasValidated ||
        this.customer.has_signed ||
        this.customer.cpr_validated
      );
    },
  },
  components: {
    CompanyValidateModal,
    ContractTableModal,
    KycModal,
  },
};
</script>