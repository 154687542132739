var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-login",
      class: {
        loading:
          _vm.sendingAuth || _vm.validatingAuthCode || _vm.emailValidating
      }
    },
    [
      _c(
        "div",
        { staticClass: "profile-login__wrapper" },
        [
          _vm.emailNotValidated
            ? [
                _c("h2", { staticClass: "profile-login__header" }, [
                  _vm._v("Din e-mail er endnu ikke blevet valideret")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "profile-login__text" }, [
                  _vm._v(
                    "Din email er ikke valideret! Valider venligst din e-mail via det unikke link du har fået i din mail da du blev oprettet."
                  )
                ]),
                _vm._v(" "),
                _vm._m(0)
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.emailAlreadyValidated
            ? [
                _c("h2", { staticClass: "profile-login__header" }, [
                  _vm._v("Din validering er gennemført")
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3)
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.emailNotValidated && !_vm.emailAlreadyValidated
            ? [
                !_vm.showEnterCode
                  ? [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "profile-login__header profile-login__header--no-margin"
                        },
                        [_vm._v("Grib din telefon")]
                      ),
                      _vm._v(" "),
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.sendAuthCode } },
                        [_vm._v("Send kode")]
                      )
                    ]
                  : !_vm.loggingIn
                  ? [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "profile-login__header profile-login__header--no-margin"
                        },
                        [_vm._v("Indtast SMS-kode")]
                      ),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _c("code-input", {
                        attrs: { title: "", fields: 6 },
                        on: {
                          complete: _vm.onCodeCompletion,
                          change: _vm.onCodeChange
                        }
                      }),
                      _vm._v(" "),
                      _vm.error
                        ? _c("p", {
                            staticClass: "error",
                            domProps: { textContent: _vm._s(_vm.error) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", { staticClass: "profile-login__contact-text" }, [
                        _vm._v("Har du ikke modtaget SMS kode? "),
                        _c(
                          "a",
                          {
                            staticClass: "profile-login__contact-link",
                            attrs: { href: "javascript:void" },
                            on: { click: _vm.sendAuthCode }
                          },
                          [_vm._v("Gensend ny kode")]
                        ),
                        _vm._v(" eller "),
                        _c(
                          "a",
                          {
                            staticClass: "profile-login__contact-link",
                            attrs: {
                              href:
                                "https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Kontakt os her.")]
                        )
                      ])
                    ]
                  : [
                      _c("div", { staticClass: "loading" }),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "profile-login__header profile-login__header--center"
                        },
                        [_vm._v("Succes!")]
                      )
                    ]
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__contact-text" }, [
      _c(
        "a",
        {
          staticClass: "profile-login__contact-link",
          attrs: {
            href: "https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/",
            target: "_blank"
          }
        },
        [_vm._v("Kontakt os her")]
      ),
      _vm._v(" hvis du har sprøgsmål.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v("Du er valideret til at kunne benytte "),
      _c("b", [_vm._v("NEM")]),
      _vm._v("BLIK.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v(
        "Du har modtaget et link på sms, samt email. Det er dette link du skal bruge for at kunne tilgå dit "
      ),
      _c("b", [_vm._v("NEM")]),
      _vm._v(
        "BLIK. Hver gang du klikker på linket vil du modtage en unik SMS-kode der giver dig adgang. Vi sender et link til dig hver gang der er nyt i din sag."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v("Har du mistet linket kan du sende en mail til "),
      _c(
        "a",
        {
          staticClass: "profile-login__contact-link",
          attrs: { href: "mailto:info@nemadvokat.dk" }
        },
        [_vm._v("info@nemadvokat.dk")]
      ),
      _vm._v(" eller ringe på "),
      _c(
        "a",
        {
          staticClass: "profile-login__contact-link",
          attrs: { href: "tel:70204161" }
        },
        [_vm._v("70204161")]
      ),
      _vm._v(" og anmode om at få tilsendt dit link til NEMblik.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v(
        "Klik på ‘Send kode’ nedenfor og vi sender en bekræftelseskode til det mobilnummer der er tilknyttet din sag. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        " Koden virker i 30 minutter, hvorefter der skal bestilles en ny kode for login. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "profile-login__text" }, [
      _vm._v(
        "Vi har sendt dig en SMS med en engangskode til dit NEMBLIK™. Koden er ved dig indenfor 30 sekunder. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" Indtast den i boksene nedenfor og tryk videre.")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-33c3fc9b", { render: render, staticRenderFns: staticRenderFns })
  }
}