<template>
    <slide title="Hvad skal selskabet hedde?" :orderHelp="helpText" class="order-flow__wrapper--1" @next="submit()" :validated="isValid">
        <template slot="content">
            <input-field name="Selskabsnavn" :value="intCompanyName" @input="(val) => {intCompanyName = val}" @enter="submit()" />
            <div class="order-flow__content__expansion order-flow__content__expansion--auto-height" :class="{'order-flow__content__expansion--open': intCompanyByNames.length}">
                <input-field v-for="(byname, key) in intCompanyByNames" :isRemovable="true" @remove="intCompanyByNames.splice(key, 1)" :key="key" name="Binavn" :value="byname" @input="(val) => $set(intCompanyByNames, key, val)" />                
            </div>
            <button href="#" class="order-flow__expand-btn" v-if="intCompanyByNames.length < 5" @click="intCompanyByNames.push('')">Tilføj binavn</button>
        </template>
    </slide>
</template>

<script>
import InputField from './../../input-field/input-field.vue';
import Slide from './slide.vue';

export default {
  props: {
      companyName: {type: String, default: null},
      companyByName: {type: Array, default() {return [ ]}},
      companyProduct: {type: String, defauult: 'main'}
  },
  data() {
      return {
          intCompanyName: '',
          intCompanyByNames: [],
          helpText: {
              heading: 'Information',
              paragraphs: [
                  'Vi kontrollerer om det valgte navn er ledigt, ligesom vi kan hjælpe dig, hvis det skal sikres som et varemærke.'
              ]
          }
      }
  },
  mounted() {
      this.intCompanyName = this.companyName;
      this.intCompanyByNames = this.companyByName;
  },
  watch: {
      companyName() {
          this.intCompanyName = this.companyName;
      },
      companyByName() {
          this.intCompanyByNames = this.companyByName;
      }
  },
  methods: {
      submit() {
          if(this.intCompanyName) {
            this.$emit('submit', {companyName: this.intCompanyName, companyByName: this.intCompanyByNames});
          }
      }
  },
  computed: {
    isValid() {
        return (this.intCompanyName && this.intCompanyName.length > 0) ? true : false;
    }
  },
  components: {
      InputField,
      Slide
  }
}
</script>

