var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement-letter" }, [
    _c(
      "div",
      { staticClass: "agreement-letter__body" },
      [
        _vm.letterData.price_method > 0 ? _c("h3", [_vm._v("Pris")]) : _vm._e(),
        _vm._v(" "),
        _vm.letterData.price_method == 1
          ? _c(
              "p",
              { staticClass: "intro" },
              [
                _vm._v(
                  "Vores honorar for denne opgave udgør en fast aftalt pris på "
                ),
                _c("span", {
                  directives: [
                    {
                      name: "price",
                      rawName: "v-price",
                      value: _vm.letterData.price,
                      expression: "letterData.price"
                    }
                  ]
                }),
                _vm._v(" "),
                _c("b", [_vm._v("inkl. moms.")]),
                _vm._v(" "),
                _vm.letterData.discountPercent > 0
                  ? _c("span", [
                      _vm._v(
                        "Rabat " + _vm._s(_vm.letterData.discountPercent) + "%."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" Moms udgør heraf "),
                _c("span", {
                  directives: [
                    {
                      name: "price",
                      rawName: "v-price",
                      value: _vm.vat,
                      expression: "vat"
                    }
                  ]
                }),
                _vm._v(" "),
                _vm.letterData.other_case
                  ? [_vm._v("Afregning sker på anden sag.")]
                  : _vm._e(),
                _vm._v(" "),
                _vm.letterData.against_capital
                  ? [
                      _vm._v(
                        "Vores honorar og gebyr til Erhvervsstyrelsen modregnes i den indbetalte selskabskapital."
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.letterData.price_method == 2
          ? _c("p", [
              _vm._v(
                "\n            Vores honorar for denne opgave afregnes som aftalt efter medgået tid. Prisen udgør kr. 1.250,00 - 3.750,00 "
              ),
              _c("b", [_vm._v("inkl. moms")]),
              _vm._v(
                " i timen. Enhver opgave vil blive tilrettelagt således, at vi inddrager personale med lavere timetakster, til at bistå med arbejde, hvor det skønnes hensigtsmæssigt. Det er altid muligt, at få oplyst sagens løbende omkostninger.\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.letterData.tax > 0
          ? _c("p", [
              _vm._v("Afgifter/gebyrer udgør "),
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.letterData.tax,
                    expression: "letterData.tax"
                  }
                ]
              }),
              _vm._v(", momsfrit.")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h3", [_vm._v("Deponering")]),
        _vm._v(" "),
        _vm.letterData.deposit_method === 1
          ? [
              _c("p", [
                _vm._v(
                  "Til sikkerhed for vores honorar og kendte omkostninger bedes "
                ),
                _c("span", {
                  directives: [
                    {
                      name: "price",
                      rawName: "v-price",
                      value: _vm.letterData.deposit_price,
                      expression: "letterData.deposit_price"
                    }
                  ]
                }),
                _vm._v(
                  " indsættes på vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677. Navngiv venligst overførslen " +
                    _vm._s(_vm.letterData.caseid) +
                    "."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Din indbetaling registreres indenfor 1-2 bankdage. Vores kvittering herfor vil fremgå i dit NEMBLIK på sagen, og du vil modtage en kvittering til din e-mail."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Vi vil høfligt påminde om beløbet indtil deponering er modtaget. Krydser vores påmindelser din indbetaling, skal du naturligvis set bort fra disse."
                )
              ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.letterData.deposit_method === 0
          ? _c("p", [
              _vm._v(
                "Efter aftale skal der ikke foretages deponering forud for sagens opstart."
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _vm._v("Se sagens fulde aftalebrev/ordrebekræftigelse "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.$emit("showletter")
                }
              }
            },
            [_vm._v("her")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4024639e", { render: render, staticRenderFns: staticRenderFns })
  }
}