var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-selector" }, [
    _c("h2", { staticClass: "login-selector__heading" }, [
      _vm._v("Vælg din brugertype")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "login-selector__text" }, [
      _vm._v(
        "\n    Er du kunde hos os eller tilknyttet en sag som part, vælg nedenfor.\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "login-tile-grid" },
      [
        _c(
          "router-link",
          {
            staticClass: "login-tile",
            attrs: { to: { name: "customer-reset-login" } }
          },
          [
            _c("img", {
              attrs: { src: "/assets/img/icons/icon-client-login.svg" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "login-tile__text" }, [_vm._v("Kunde")])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "login-tile", attrs: { to: { name: "part-login" } } },
          [
            _c("img", {
              attrs: { src: "/assets/img/icons/icon-login-part.svg" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "login-tile__text" }, [_vm._v("Part")])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79db106a", { render: render, staticRenderFns: staticRenderFns })
  }
}