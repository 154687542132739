var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "related-products" }, [
    _vm.relatedProducts.length
      ? _c("h2", { staticClass: "related-products__heading" }, [
          _vm._v("\n    Relateret produkter\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.relatedProducts.length
      ? _c(
          "div",
          { staticClass: "lead-products" },
          _vm._l(_vm.relatedProducts, function(product) {
            return _c(
              "div",
              {
                key: product.id,
                staticClass: "lead-product",
                on: {
                  click: function($event) {
                    return _vm.addRelatedProduct(product)
                  }
                }
              },
              [
                _c("div", { staticClass: "lead-product__content" }, [
                  _c("h4", [
                    _vm._v(
                      "\n          " +
                        _vm._s(product.product.title) +
                        "\n          "
                    ),
                    _vm.getProductPrice(product)
                      ? _c("span", {
                          directives: [
                            {
                              name: "dkkprice",
                              rawName: "v-dkkprice",
                              value: _vm.getProductPrice(product),
                              expression: "getProductPrice(product)"
                            }
                          ]
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: {
                        loading:
                          _vm.relatedProductLoading.indexOf(product.id) > -1
                      },
                      attrs: {
                        disabled:
                          _vm.relatedProductLoading.indexOf(product.id) > -1
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/assets/img/icons/icon-check.svg",
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tiføj")])
                    ]
                  )
                ])
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3c121c2d", { render: render, staticRenderFns: staticRenderFns })
  }
}