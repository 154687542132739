var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "msg-item" }, [
    _c("div", { staticClass: "msg-item__block" }, [
      _c("div", { staticClass: "msg-item__header" }, [
        _c("div", { staticClass: "msg-item__header__content" }, [
          _c("span", {
            staticClass: "msg-date",
            domProps: { textContent: _vm._s(_vm.msg.created_at) }
          }),
          _vm._v(" "),
          _vm.msg.subject
            ? _c("h4", {
                staticClass: "msg-topic",
                domProps: { textContent: _vm._s(_vm.msg.subject) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h5", {
            staticClass: "msg-name",
            domProps: { textContent: _vm._s(_vm.msg.from) }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "msg-item__avatar" }, [
          _c("img", { attrs: { src: _vm.msg.img, alt: "" } })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "msg-item__body" }, [
        !_vm.msg.render_html
          ? _c(
              "div",
              { staticClass: "msg-text" },
              [_c("nl2br", { attrs: { tag: "p", text: _vm.msg.message } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.msg.render_html
          ? _c("div", {
              staticClass: "msg-text",
              domProps: { innerHTML: _vm._s(_vm.msg.message) }
            })
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-498fe262", { render: render, staticRenderFns: staticRenderFns })
  }
}