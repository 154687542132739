<template>
  <div class="box box-primary">
    <inline-msg-thread class="box-body" ref="mesageWindow" :messages="messages" :part="part"></inline-msg-thread>
  </div>
</template>

<script>
import InlineMsgThread from "./inline-msg-trhead.vue";

export default {
  props: {
    messages: {
      type: Array,
      default() {
        return [];
      }
    },
    order: { type: Object, default: null },
    part: { type: Object, default: null }
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      if (this.$refs.mesageWindow) {
        this.$refs.mesageWindow.$el.scrollTop = this.$refs.mesageWindow.$el.scrollHeight;
      }
    }
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        if (this.messages.length) {
          this.scrollToBottom();
        }
      });
    }
  },
  computed: {
    customerDepartmentTxt() {
        let partId = (this.order.customer) ? parseInt(this.order.customer.customer_department) : nul;
        switch(partId) {
            case 1:
                return 'Samtale med STØTTRUP';
            default:
                return 'Samtale med NEMADVOKAT';
        }
      }
  },
  components: {
    InlineMsgThread
  }
};
</script>