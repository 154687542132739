<template>
    <div class="intro-header">
        <div class="intro-header__wrapper">
            <div class="content-block">
                <h1 class="content-block__heading"><slot></slot></h1>
                <p class="content-block__text" v-if="content">{{ content }}</p>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default{
        props: {
            content: {type: String, default: null}
        }
    }
</script>