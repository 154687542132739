var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--6",
      attrs: { title: _vm.title, validated: _vm.isValid },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { staticClass: "order-tiles order-tiles--selection" },
          _vm._l(_vm.incorporationRules, function(rule, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "order-tiles__item",
                class: [
                  "order-tiles__item--0",
                  {
                    "order-tiles__item--selected":
                      _vm.intSelectedCoporationRule === key
                  }
                ],
                on: {
                  click: function($event) {
                    _vm.intSelectedCoporationRule = key
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "order-tiles__item__heading",
                  domProps: { textContent: _vm._s(rule) }
                })
              ]
            )
          }),
          0
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-86db898a", { render: render, staticRenderFns: staticRenderFns })
  }
}