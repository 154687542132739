/**
 * Returns true if product has extra data to fill in
 */
export const HasCaseDataToFill = (leadProduct) => {
	if (
		leadProduct &&
		leadProduct.product &&
		leadProduct.product.static_product &&
		leadProduct.product.static_product.nemRegProduct
	) {
		
		if(leadProduct.created) {
			return false;
		}

		//If custom product all products can use company guide
		if (leadProduct.product.custom_product_version_id) {
			return true;
		}

		let productTitle = leadProduct.product.title;
		return [ 'stiftelse af aps', 'stiftelse af holding aps', 'aps-premium' ].indexOf(productTitle.toLowerCase()) >
		-1
			? true
			: false;
	}

	return false;
};
