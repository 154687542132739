<template>
  <div
    class="profile-login profile-login--part"
    :class="{'loading': validatingLogin || emailValidating || settingPassword}"
  >
    <div class="profile-login__wrapper">
      <div>
        <template v-if="validateEmailView">
          <h2 class="profile-login__header">Validerer din e-mail</h2>
          <p class="profile-login__text" v-if="emailValidating">Din e-mail valideres, vent venligst...</p>
          <p class="profile-login__text"></p>
          <p class="error" v-if="error" v-html="error"></p>
        </template>

        <template v-if="setPasswordView">
          <h2 class="profile-login__header">Din validering er gennemført!</h2>
          <p class="profile-login__text">
            Du er valideret til at kunne benytte
            <b>NEM</b>BLIK.
          </p>
          <p
            class="profile-login__text"
          >Indtast venligst den adgangskode som du ønsker at bruge til at logge ind med.</p>
          <input-field
            name="Kodeord"
            input-type="password"
            v-model="password"
            @enter="setPassword"
            :error="fieldErrors.indexOf('password') > -1 ? 'Skal udfyldes' : ''"
          ></input-field>
          <input-field
            name="Gentag Kodeord"
            input-type="password"
            v-model="confirm_password"
            :error="fieldErrors.indexOf('confirm_password') > -1 ? 'Skal udfyldes' : ''"
            @enter="setPassword"
          ></input-field>
          <p class="error" v-if="error" v-html="error"></p>
          <button
            class="btn"
            @click="setPassword"
            :disabled="!password || !confirm_password || password != confirm_password || settingPassword"
          >Gem</button>
        </template>

        <template v-if="!validateEmailView && !setPasswordView">
          <h2 class="profile-login__header">Part Login</h2>
          <p class="profile-login__text">Log ind med din e-mail og selvvalgt kodeoerd.</p>
          <input-field
            name="E-mail"
            input-type="email"
            v-model="email"
            @enter="validateLogin"
            :error="fieldErrors.indexOf('email') > -1 ? 'Skal udfyldes' : ''"
          ></input-field>
          <input-field
            name="Kodeord"
            input-type="password"
            v-model="password"
            @enter="validateLogin"
            :error="fieldErrors.indexOf('password') > -1 ? 'Skal udfyldes': ''"
          ></input-field>
          <p class="error" v-if="error" v-html="error"></p>
          <button
            class="btn"
            @click="validateLogin"
            :disabled="!email || !password || validatingLogin"
          >Log ind</button>
          <p class="profile-login__contact-text">
            Har du glemt din adgangskode?
            <a
              href="https://www.nemadvokat.dk/om-nemadvokat/kontakt-os/"
              target="_blank"
              class="profile-login__contact-link"
            >Kontakt os her.</a>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import InputField from "../input-field/input-field.vue";
import Datastore from "../../services/datastore.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      confirm_password: "",
      emailValidating: false,
      emailAlreadyValidated: false,
      validatingLogin: false,
      settingPassword: false,
      error: null,
      fieldErrors: []
    };
  },
  mounted() {
    if (this.validateEmailView) {
      this.validateEmail();
    }
  },
  methods: {
    setPassword() {
      if (this.settingPassword) return;

      this.error = null;
      this.fieldErrors = [];
      let err = false;
      if(!this.password) {
          this.fieldErrors.push('password');
      }

      if(!this.confirm_password) {
          this.fieldErrors.push('confirm_password');
      }

      if(this.confirm_password != this.password) {
          this.fieldErrors.push('password');
          this.fieldErrors.push('confirm_password');
          this.error = 'Kodeord er ikke ens!';
      }

      if(this.fieldErrors.length) {
          return;
      }

      this.settingPassword = true;
      Datastore.partSetPassword(this.parthash, this.password)
        .then(res => {
          this.settingPassword = false;
          if (res.data.status == "ok") {
            this.password = '';
            this.confirm_password = '';

            this.$router.push({
              name: "part-login",
              query: {
                emailvalidated: true
              }
            });
          } else if(res.data.status === 'error' && res.data.code === 'password_not_set') {
            this.error =
              "Der skete en da dit kodeord skulle sættes. <br/> Kontakt venligst NEMADVOKAT.";
          } else {
            this.error =
              "Der skete en da dit kodeord skulle sættes. <br/> Kontakt venligst NEMADVOKAT.";
          }
        })
        .catch(err => {
          this.settingPassword = false;
          let res = err.response;
          if (res && res.data.code) {
            switch (res.data.code) {
              case "not_found":
              case "no_response":
                this.$router.replace("/");
                return;
              case "bad_request":
                this.fieldErrors = res.data.inputs;
                this.error = "Du har ikke udfyldt alle felter korrekt. Kode skal være på min. 6 tegn og have store og små bogstaver, samt specialtegn";
                return;
              default:
                this.error =
                  "Der skete en fejl under login! <br/> Kontakt NEMAdvokat for at få nulstillet din kode";
                return;
            }
          } else {
            this.error =
              "Der skete en fejl med forbindelsen! <br/> Tjek om du har forbindelse til internettet.";
          }
        });
    },
    validateEmail() {
      if (this.emailValidating) return;

      this.emailValidating = true;
      this.error = null;
      Datastore.partValidateEmail(this.parthash)
        .then(res => {
          this.emailValidating = false;
          if (res.data.unique_hash) {
            this.$router.push({
              name: "part-password",
              params: {
                hash: res.data.unique_hash
              }
            });
          } else {
            switch (res.data.code) {
              case "not_found":
              case "no_response":
                this.emailAlreadyValidated = true;
                this.error =
                  "Din e-mail er allerede blevet valideret! <br/> Hvis det ikke var dig kontakt venligst NEMAdvokat.";
                return;
              default:
                this.error =
                  "Der skete en fejl under valideringen, og din mail blev ikke valideret!";
                return;
            }
          }
        })
        .catch(error => {
          console.error(error);
          this.emailValidating = false;
          this.error =
            "Der skete en fejl med forbindelsen! <br/> Tjek om du har forbindelse til internettet.";
        });
    },
    validateLogin() {
      this.fieldErrors = [];
      if (!this.email) {
        this.fieldErrors.push("email");
        this.error = "Du har ikke udfyldt alle felter";
      }

      if (!this.password) {
        this.fieldErrors.push("password");
        this.error = "Du har ikke udfyldt alle felter";
      }

      if (this.validatingLogin || !this.email || !this.password) return;

      this.error = null;
      this.validatingLogin = true;
      Datastore.partLogin(this.email, this.password)
        .then(res => {
          this.validatingLogin = false;
          if (res.data.part_hash) {
            if (this.orderHash) {
              this.$router.push({
                name: "part-case",
                params: {
                  orderhash: this.orderHash,
                  hash: res.data.part_hash
                }
              });
            } else if (this.emailValidated) {
              //If user just has validated e-mail we welcome them
              this.$router.push({
                name: "part-welcome",
                params: {
                  hash: res.data.part_hash
                }
              });
            } else {
              this.$router.push({
                name: "part-profile",
                params: {
                  hash: res.data.part_hash
                }
              });
            }
          } else {
            switch (res.data.code) {
              case "not_found":
              case "no_response":
                this.$router.replace("/");
                return;
              case "email_not_validated":
                this.error =
                  "Din email er ikke valideret! <br/> Validér venligst din e-mail via det unikke link, som du har fået i din mail da du blev oprettet";
                return;
              case "user_not_found":
                this.error =
                  "Du har ikke indtastet et korrekte e-mail el. kodeord!";
                return;
              case "login_reset":
                this.error =
                  "Du har forsøgt at logge ind for mange gange og din bruger er dermed blevet nulstillet. Kontakt venligt NEMADVOKAT";
                return;
              default:
                this.error =
                  "Der skete en fejl under login! <br/> Kontakt NEMAdvokat for at få nulstillet din kode";
                return;
            }
          }
        })
        .catch(error => {
          this.validatingLogin = false;

          let res = error.response;
          if (res && res.data.code) {
            switch (res.data.code) {
              case "not_found":
              case "no_response":
                this.$router.replace("/");
                return;
              case "bad_request":
                this.fieldErrors = res.data.inputs;
                this.error = "Du har ikke udfyldt alle felter";
                return;
              default:
                this.error =
                  "Der skete en fejl under login! <br/> Kontakt NEMAdvokat for at få nulstillet din kode";
                return;
            }
          } else {
            this.error =
              "Der skete en fejl med forbindelsen! <br/> Tjek om du har forbindelse til internettet.";
          }
        });
    }
  },
  computed: {
    orderHash() {
      return this.$route.query.orderhash
        ? this.$route.query.orderhash.replace(/\//, "")
        : null;
    },
    parthash() {
      return this.$route.params.hash;
    },
    emailValidated() {
      return this.$route.query.emailvalidated ? true : false;
    },
    validateEmailView() {
      return this.parthash && this.$route.name == "part-validate"
        ? true
        : false;
    },
    setPasswordView() {
      return this.$route.name == "part-password" && this.parthash
        ? true
        : false;
    }
  },
  components: {
    "input-field": InputField
  }
};
</script>