<template>
    <div class="label-input" v-bind:class="{ 'error-shake': error}">
        <input v-if="inputType == 'text'" ref="inputField" class="input input-field" v-bind:class="{ 'has-text': hasText }" type="text" v-bind:name="fieldkey" :value="value" v-on:input="onInput" :disabled="disable" v-ui-mask="{'mask': mask}" @keydown.enter="onEnter" @click="onClick" @keydown="onKey" @blur="onBlur">
        <input v-if="inputType == 'email'" ref="inputField" class="input input-field" v-bind:class="{ 'has-text': hasText }" type="email" v-bind:name="fieldkey" :value="value" v-on:input="onInput" :disabled="disable" v-ui-mask="{'mask': mask}" @keydown.enter="onEnter" @click="onClick" @keydown="onKey" @blur="onBlur">
        <input v-if="inputType == 'password'" ref="inputField" class="input input-field" v-bind:class="{ 'has-text': hasText }" type="password" v-bind:name="fieldkey" :value="value" v-on:input="onInput" :disabled="disable" v-ui-mask="{'mask': mask}" @keydown.enter="onEnter" @click="onClick" @keydown="onKey" @blur="onBlur">
        <input v-if="inputType == 'tel'" ref="inputField" class="input input-field" v-bind:class="{ 'has-text': hasText }" type="tel" v-bind:name="fieldkey" :value="value" v-on:input="onInput" :disabled="disable" v-ui-mask="{'mask': mask}" @keydown.enter="onEnter" @click="onClick" @keydown="onKey" @blur="onBlur">
        <textarea v-if="inputType == 'textarea'" ref="inputField" class="input input-field" v-bind:class="{ 'has-text': hasText }" v-bind:name="fieldkey" v-on:input="onInput" :disabled="disable" v-ui-mask="{'mask': mask}" @keydown.enter="onEnter" @click="onClick" @keydown="onKey" @blur="onBlur">{{ value }}</textarea>
        <button class="label-input__btn-remove" v-if="isRemovable" @click.prevent="onRemove"><img src="/assets/img/icons/icon-close.svg" alt=""></button>
        <label class="label">{{ name }}</label>
        <div v-if="error">
            <span class="tag-label">{{ errormsg }}</span>
        </div>
    </div>
</template>
<script type="text/javascript">
    export default{
        props: {
            'name': {type: String, default: ''},
            'fieldkey': {type: String, default: ''},
            'value': {type: String, default: ''},
            'error': '',
            'inputType': {type: String, default: 'text'},
            'disable': {type: Boolean, default: false},
            'mask': {type: String, default: null},
            'select': {type: Boolean, default: false},
            isRemovable: {type: Boolean, default: false},
        },
        data() {
            return {
                stopTypingTimer: null,
            }
        },
        methods:  {
            onBlur() {
                this.$emit('blur');
            },
            onClick() {
              if(this.select) {
                  this.$refs.inputField.select();
              }
              this.$emit('click');
            },
            onEnter() {
                this.$emit('enter', event.target.value);
            },
            onInput(event) {
                var self = this;
                this.$emit('input', event.target.value);
                if(this.stopTypingTimer) {
                    clearTimeout(this.stopTypingTimer);
                }

                this.stopTypingTimer = setTimeout(() => {
                    self.$emit('stopTyping', event.target.value);
                }, 500);
            },
            onKey(event) {
                this.$emit('key', event);
            },
            onRemove() {
                this.$emit('remove');
            },
        },
        computed: {
            hasText() {
                return  (this.value && this.value.length > 0);
            },
            errormsg: function() {
                return (typeof this.error === 'object') ? this.error[0] : this.error;
            }
        }
    }
</script>