var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msg-view" },
    [
      _c("message-thread", {
        class: { loading: _vm.loading },
        attrs: { order: _vm.order, messages: _vm.messages, part: _vm.customer }
      }),
      _vm._v(" "),
      _vm.newMessage
        ? _c("new-message-view", {
            attrs: {
              order: _vm.order,
              "current-user": _vm.customer,
              "part-hash": _vm.partHash,
              messages: _vm.messages
            },
            on: {
              close: function($event) {
                _vm.newMessage = false
              },
              messagesent: _vm.onMessageSent
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-15fe888c", { render: render, staticRenderFns: staticRenderFns })
  }
}