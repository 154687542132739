var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.headers, function(field, header) {
          return _c("td", { domProps: { textContent: _vm._s(header) } })
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.data, function(item, key) {
        return _c(
          "tr",
          _vm._l(_vm.headers, function(field, header) {
            return _c("td", {
              domProps: { innerHTML: _vm._s(item[header]) },
              on: {
                click: function($event) {
                  return _vm.onClick(_vm.tableData[key])
                }
              }
            })
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-286c41ed", { render: render, staticRenderFns: staticRenderFns })
  }
}