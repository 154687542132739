import Vue from 'vue';
import VMasker from './MaskedInput.js';
import currencyFormatter from 'currency-formatter';

Vue.directive('ui-mask', (el, binding) => {
    if(binding.value.mask) {
        VMasker(el).maskPattern(binding.value.mask);
    }
});

Vue.directive('price', (el, binding) => {
        if(typeof binding.value !== 'undefined') {
            el.innerHTML = currencyFormatter.format(binding.value, {
                "symbol": "kr.",
                "thousand": ".",
                "decimal": ",",
                "precision": 2,
                "format": '%v %s'
            });
        }
});

Vue.directive('dkkprice', (el, binding) => {
        if(typeof binding.value !== 'undefined') {
            el.innerHTML = currencyFormatter.format(binding.value, {
                "symbol": "DKK",
                "thousand": ".",
                "decimal": ",",
                "precision": 2,
                "format": '%s %v'
            });
        }
});