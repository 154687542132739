var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "msg-new",
      attrs: { show: true, title: "Ny besked" },
      on: { close: _vm.closeMessage }
    },
    [
      _c("div", [
        _c("div", { staticClass: "tool-line" }, [
          _c("div", { ref: "toolbar" }, [
            _c("span", { staticClass: "ql-formats" }, [
              _c("select", { staticClass: "ql-color" }, [
                _c("option", { attrs: { selected: "selected" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#e60000" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ff9900" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffff00" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#008a00" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#0066cc" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#9933ff" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffffff" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#facccc" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffebcc" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffffcc" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#cce8cc" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#cce0f5" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ebd6ff" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#bbbbbb" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#f06666" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffc266" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#ffff66" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#66b966" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#66a3e0" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#c285ff" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#888888" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#a10000" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#b26b00" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#b2b200" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#006100" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#0047b2" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#6b24b2" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#444444" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#5c0000" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#663d00" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#666600" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#003700" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#002966" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "#3d1466" } })
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ql-formats" }, [
              _c("button", {
                staticClass: "ql-list",
                attrs: { value: "bullet" }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "ql-list",
                attrs: { value: "ordered" }
              })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ql-formats" }, [
              _c("select", { staticClass: "ql-header" }, [
                _c("option", { attrs: { value: "1" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }),
                _vm._v(" "),
                _c("option", { attrs: { selected: "selected" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "6" } })
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ql-formats" }, [
              _c("button", { staticClass: "ql-bold" }),
              _vm._v(" "),
              _c("button", { staticClass: "ql-italic" }),
              _vm._v(" "),
              _c("button", { staticClass: "ql-underline" })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tool-line__actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                class: { "btn--loading": _vm.loading },
                attrs: { disabled: !_vm.readyToSend || _vm.loading },
                on: { click: _vm.sendMessage }
              },
              [_vm._v("Send")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "subject-line" },
          [
            _c("input-field", {
              ref: "subject",
              attrs: { name: "Emne" },
              model: {
                value: _vm.subject,
                callback: function($$v) {
                  _vm.subject = $$v
                },
                expression: "subject"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-line" },
          [
            _vm.editorOptions
              ? _c("quill-editor", {
                  staticClass: "msg-text",
                  attrs: { options: _vm.editorOptions },
                  model: {
                    value: _vm.message,
                    callback: function($$v) {
                      _vm.message = $$v
                    },
                    expression: "message"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("inline-msg-thread", {
              attrs: {
                messages: _vm.messages,
                part: _vm.currentUser,
                reverse: true
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { ref: "signatur", staticStyle: { display: "none" } }, [
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", [_vm._v("Med venlig hilsen")]),
          _vm._v(" "),
          _c("p", [_c("b", [_vm._v(_vm._s(_vm.currentUser.name))])])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-031ac68a", { render: render, staticRenderFns: staticRenderFns })
  }
}