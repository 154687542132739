var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Book telefonmøde", show: true, noScroll: false },
      on: { close: _vm.onClose }
    },
    [
      _c("div", { staticClass: "book-phone-meeting" }, [
        _c(
          "div",
          { staticClass: "input-fields" },
          [
            _c("date-picker", {
              attrs: {
                name: "Dato",
                "is-ready": true,
                "default-date": _vm.startDate,
                format: "DD-MM-YYYY"
              },
              on: { pickdate: _vm.updateDate }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "input-fields" }, [
          _c("div", { staticClass: "drop-down" }, [
            _c("label", { attrs: { for: "hour" } }, [_vm._v("Kl.")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.setTimeHour,
                    expression: "setTimeHour"
                  }
                ],
                staticClass: "drop-down__select",
                attrs: { id: "hour" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.setTimeHour = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.hours, function(hour) {
                return _c("option", { domProps: { value: hour.key } }, [
                  _vm._v("\n            " + _vm._s(hour.value) + "\n          ")
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.error,
                  expression: "error"
                }
              ],
              staticClass: "error"
            },
            [
              _vm._v(
                "\n        Der skete desværre en fejl og det valgte tidspunkt blev ikke sat\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn--positive",
            class: { loading: _vm.loading },
            attrs: { disabled: _vm.loading || _vm.lead.contact_state == 4 },
            on: { click: _vm.selelectTime }
          },
          [_vm._v("\n      Bestil\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38af1bf8", { render: render, staticRenderFns: staticRenderFns })
  }
}