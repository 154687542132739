<template>
  <div class="case-overview__block">
    <h3>Indbetalinger</h3>
    <div>
      <div class="case-deposit">
        <div class="case-deposit__line">
          <p>Her fremgår sagens økonomi. Manglende betalinger her kan bremse sagen. Bemærk der kan gå op til 24 timer før vi registrerer din betaling</p>
        </div>
        <div
          class="case-deposit__line"
          v-for="depositOrder in depositOrders"
          :key="depositOrder.id"
        >
          <p v-text="depositOrder.typeText"></p>
          <p v-price="depositOrder.price"></p>
          <button v-if="depositOrder.status != 2" @click="showDeposit(depositOrder)">Betal nu</button>
          <p v-if="depositOrder.status == 2">Modtaget</p>
        </div>
      </div>
    </div>

    <modal
      title="Betaling af tinglysningsgebyr"
      @close="showTinlgysningModal = false"
      :show="showTinlgysningModal"
    >
      <registration-fee :order="order" @showletter="showCustomerLetter"></registration-fee>
    </modal>

    <deposit-modal
      v-if="showDepositOrder"
      :caseid="order.caseid"
      :deposit-order="showDepositOrder"
      @close="showDepositOrder = null"
      @showagreementletter="showCustomerLetter"
    ></deposit-modal>
  </div>
</template>

<script>
import Modal from "../modal/base-modal.vue";
import RegistrationFee from "./../contract/registrationfee.vue";
import DepositModal from "./../contract/deposit-modal.vue";

export default {
  props: {
    order: { type: Object },
  },
  data() {
    return {
      showTinlgysningModal: false,
      showDepositOrder: null,
    };
  },
  methods: {
    showCustomerLetter() {
      this.showTinlgysningModal = false;
      this.showDepositOrder = null;
      this.$emit("showcustomerletter");
    },
    showDeposit(depositOrder) {
      switch (depositOrder.id) {
        case "a":
          this.$emit("showdeposits");
          break;
        case "b":
          this.showTinlgysningModal = true;
          break;
        default:
          this.showDepositOrder = depositOrder;
          break;
      }
    },
  },
  computed: {
    depositOrders() {
      let depositOrders = [];
      if (this.order.depositorders) {
        depositOrders = depositOrders.concat(this.order.depositorders);
      }

      return depositOrders.map((depositOrder) => {
        depositOrder.typeText = depositOrder.typeText.split(' – ', 1)[0];

        if (
          depositOrder.type == 1 &&
          this.order.price_data.pricing_method == "hourly"
        ) {
          depositOrder.typeText = "Sikkerhedsstillelse";
          depositOrder.type = 1.1;
        }

        return depositOrder;
      });
    },
  },
  components: {
    Modal,
    RegistrationFee,
    DepositModal,
  },
};
</script>