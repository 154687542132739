export default {
	intro_text: {
		title: 'We need to confirm your identity',
		text: [
			'We need to know our customers and since we do not meet with you in person, we are obliged to identify you in another way.',
			'The process only takes a few minutes, then we can get back to business.'
		],
		submit: 'Continue',
		faq: {
			title: 'Regulation',
			text: [
				'As lawyers, we are subject to Anti-Money Laundering regulation, and are thus obliged to obtain identification from all our clients.',
				"We can't commence with your case before we have received the requested identification. <a href='https://dda.nu/saadan-sender-du-legitimation/' target='_blank' class='kyc-wrapper__link kyc-wrapper__link--inline'>Read more (in Danish).</a>"
			]
		}
	},
	pep_person: {
		title: 'Politisk eksponeret',
		yes_option: 'Ja,',
		yes_option_text: 'Jeg er, eller inden for de seneste 12 måneder har været, politisk',
		no_option: 'Nej,',
		no_option_text: 'Jeg er, eller inden for de seneste 12 måneder har været, politisk',
		submit: 'Næste',
		faq: {
			title: 'Hvad vil det sige at være PEP?',
			text: [
				'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut reprehenderit quia quasi. In sunt ad veritatis deserunt quia iusto consequuntur dicta, quo dolorum! Suscipit corrupti, repellendus doloremque esse rem soluta!'
			]
		}
	},
	id_info_text: {
		title: 'Submission of ID',
		text: 'Please have your NemID and/or identification papers ready.',
		esignatur_error: 'An error has occurred. Please try again or identify without NemID',
		esignatur_btn: 'Identify using NemID',
		no_esignatur: 'Identify without NemID',
		submit: 'Continue'
	},
	esignatur: {
		title: 'Redirecting to E-signatur',
		text: 'Please have your NemID and/or identification papers ready.',
		esignatur_info: "If you don't get redirected, open the validation link ",
		esignatur_btn: 'here'
	},
	id_selector: {
		title_finish: 'Thank you, we have reached the end.',
		title_first_source: 'Choose the first source of information',
		title_secound_source: 'Choose the second source of information',
		remove_error: 'An error happened! Try againg and check internet connection!',
		finish_btn: 'Finish',
		validationNames: {
			1: 'NemID/MitID',
			2: 'Passport',
			3: 'Driver license',
			4: 'Yellow health card'
		},
		validationDescriptions: {
			1: 'Redirects to e-signatur for signing',
			3: "Digtialt driver's license is not accepted",
			4: 'Must include social security number'
		}
	},
	upload_file: {
		title: 'Upload',
		validationNames: {
			1: 'NemID/MitID',
			2: 'Passport',
			3: 'Driver license',
			4: 'Yellow health card'
		},
		upload_error: 'An error has occurred during upload. Please try again.',
		size_error: 'The file size is{{filesize}}. The file size is limited to {{maxFilesize}}',
		type_error: 'This file format is not supported',
		size_info: 'Please note, that the file size is limited to 5 MB and must either be in png, jpg or PDF-format',
		upload_btn: 'Upload',
		select_files: 'Select files',
		remove_file: 'Remove file',
		cancel_upload: 'Cancel upload',
		cancel_upload_confirm: 'Are you sure you wish to cancel upload?',
		upload_cancelled: 'Upload cancelled'
	},
	kyc_view: {
		not_exists: "Validation not available. Check the link in your mail and that you haven't validated already",
		success: 'Your validation have now been recieved!'
	}
};
