<template>
    <slide :title="title" class="order-flow__wrapper--6" @next="submit()" :validated="isValid">
        <template slot="content">
                <div class="order-tiles" :class="{'order-tiles--selection': isValid}">
                    <div class="order-tiles__item" :class="['order-tiles__item--' + key, {'order-tiles__item--selected': (selectedOwnerKey === key)}]" v-for="(owner, key) in addressList" :key="key" @click="setOwnerAsAddress(key)">
                        <span class="order-tiles__item__heading" v-text="owner.address"></span>
                        <span class="order-tiles__item__text">{{ owner.postal }} {{ owner.town }}</span>
                    </div>
                </div>
                <button href="#" class="order-flow__expand-btn" @click="setOtherAddress">Anden adresse?</button>
                <div class="order-flow__content__expansion" :class="{'order-flow__content__expansion--open': showOtherAddress}">
                    <inputfield name="Adresse" :value="intCompanyAddress.address" @input="(val) => {intCompanyAddress.address = val}" />
                    <div class="order-flow__content__two-col">
                        <inputfield name="Post nr." :value="intCompanyAddress.postal.toString()" @input="(val) => {intCompanyAddress.postal = val}" input-type="tel" @stopTyping="getTownByZip"></inputfield>
                        <inputfield name="By" :value="intCompanyAddress.town" @input="(val) => {intCompanyAddress.town = val}"></inputfield>
                    </div>
                    <inputfield name="Land" :value="intCompanyAddress.country" @input="(val) => {intCompanyAddress.country = val}"></inputfield>                    
                </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
import inputFieldVue from '../../input-field/input-field.vue';
import DataStore from '../../../services/datastore';

export default {
  props: {
      holdingCompanies: {type: Array, defualt() {return [ ]}},
      owners: {type: Array, default() {return {}}},
      companyName: {type: String, default: 'virksomhed'},
      companyAddress: {type: Object, default() {return null}}
  },
  data() {
      return {
          showOtherAddress: false,
          intCompanyAddress: {
              id: null,
              address: '',
              postal: '',
              country: 'Danmark',
              town: ''
          }
      }
  },
  mounted() {
    if(this.companyAddress) {
        this.intCompanyAddress = Object.assign({}, this.intCompanyAddress, this.companyAddress);
        if(this.selectedOwnerKey === null) {
            this.showOtherAddress = true;
        }
    } 
  },
  watch: {
      companyAddress() {
        if(this.companyAddress) {
            this.intCompanyAddress = Object.assign({}, this.intCompanyAddress, this.companyAddress);
            if(this.selectedOwnerKey === null) {
                this.showOtherAddress = true;
            }
        } 
      }
  },
  methods: {
      setOtherAddress() {
          this.showOtherAddress = true;
          this.intCompanyAddress = {
              id: null,
              address: '',
              postal: '',
              country: 'Danmark',
              town: ''
          }
      },
      getTownByZip(value) {
        if(value && value.length === 4 && parseInt(value)) {
            DataStore.getCityFromZipcode(value).then((res) => {
                if(res.data[0]) {
                    this.$set(this.intCompanyAddress, 'town', res.data[0].navn);
                }
            });
        }
      },
      setOwnerAsAddress(key) {
          this.showOtherAddress = false;
          let owner = this.addressList[key];
          this.intCompanyAddress = {
              id: key,
              address: owner.address,
              postal: owner.postal,
              country: owner.country,
              town: owner.town
          };
      },
      submit() {
        this.$emit('submit', Object.assign({}, this.intCompanyAddress));  
      }
  },
  computed: {
    title() {
        return 'Hvad er adressen for <br/>' + this.companyName;
    },
    isValid() {
        return (this.intCompanyAddress.address && 
        this.intCompanyAddress.address.length > 0 && 
        this.intCompanyAddress.postal.length > 0 && 
        this.intCompanyAddress.town && 
        this.intCompanyAddress.town.length > 0) ? true : false;
    },
    selectedOwnerKey() {
        if(this.intCompanyAddress.id !== null) {
            return this.intCompanyAddress.id;
        }

        return null;
    },
    addressList() {
        return this.owners
            .filter((owner) => !owner.info.company_creation)
            .map((owner) => owner.info);
    }
  },
  components: {
    'slide': Slide,
    'inputfield': inputFieldVue
  }
}
</script>