<template>
    <slide :title="title" :orderHelp="helpText" class="order-flow__wrapper--4" @next="submit()" :validated="isValid">
        <template slot="content">

                    <div class="label-checkbox" v-if="isCompany">
                        <input type="checkbox" id="is_company_creation" v-model="intOwner.company_creation" />
                        <label for="is_company_creation" class="label">
                            <span class="circle"></span>
                            <span class="check"></span>
                            <span class="box"></span>Selskabet er under stiftelse
                        </label>
                    </div>

                    <template v-if="!intOwner.company_creation">
                        <inputField name="Navn" :value="intOwner.name" @input="(val) => {intOwner.name = val}"></inputField>
                        <inputField name="Adresse" :value="intOwner.address" @input="(val) => {intOwner.address = val}"></inputField>
                        <div class="order-flow__content__two-col">
                            <inputField name="Post nr." :value="intOwner.postal.toString()" @input="(val) => {intOwner.postal = val}" input-type="tel" @stopTyping="getTownByZip"></inputField>
                            <inputField name="By" :value="intOwner.town" @input="(val) => {intOwner.town = val}"></inputField>
                        </div>
                        <inputField name="Land" :value="intOwner.country" @input="(val) => {intOwner.country = val}"></inputField>
                        <inputField v-if="!isCompany" name="E-mail" :value="intOwner.email" @input="(val) => {intOwner.email = val}" input-type="email"></inputField>
                        <inputField  v-if="!isCompany" name="Tlf" :value="intOwner.phone" @input="(val) => {intOwner.phone = val}" input-type="tel"></inputField>
                        
                        <inputField v-if="!intOwner.is_company" name="CPR-nr" :value="intOwner.secret_identifier" @input="(val) => {intOwner.secret_identifier = val.substr(0, 11)}" mask="999999-9999" input-type="tel"></inputField>
                        <inputField v-if="intOwner.is_company"  name="CVR-nr" :value="intOwner.secret_identifier" @input="(val) => {intOwner.secret_identifier = val.substr(0,8)}" mask="99999999" input-type="tel"></inputField>
                        <inputField name="Ejerandel i %" :value="intOwner.owner_percent.toString()" @input="(val) => {intOwner.owner_percent = parseInt(val.substr(0, 3))}" mask="999" input-type="tel" @enter="submit()"></inputField>
                    </template>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
import InputField from './../../input-field/input-field.vue';
import DataStore from './../../../services/datastore';

export default {
  props: {
      owner: {type: Object, default() { return { }}},
      ownerNumber: {type: Number, default: 1},
      multipleOwners: {type: Boolean, default: false}
  },
  data() {
      return {
          intOwner: {
              name: '',
              address: '',
              postal: '',
              town: '',
              email: '',
              phone: '',
              country: 'Danmark',
              secret_identifier: '',
              owner_percent: 100,
              is_company: false,
              company_creation: false
          }
      }
  },
  mounted() {
      this.intOwner = Object.assign(this.intOwner, this.owner.info);
      this.intOwner.is_company = (this.owner.type === 'selskab');
  },
  watch: {
      owner() {
        this.intOwner = Object.assign(this.intOwner, this.owner.info);
        this.intOwner.is_company = (this.owner.type === 'selskab');
      }
  },
  methods: {
      getTownByZip(value) {
        if(value && value.length === 4 && parseInt(value)) {
            DataStore.getCityFromZipcode(value).then((res) => {
                if(res.data[0]) {
                    this.$set(this.intOwner, 'town', res.data[0].navn);
                }
            });
        }
      },
      submit() {
        let intOwner = Object.assign({}, this.intOwner);
        if(intOwner.company_creation) {
              intOwner = {
                name: 'Selskabet er under stiftelse',
                address: '',
                postal: '',
                town: '',
                email: '',
                phone: '',
                country: 'Danmark',
                secret_identifier: '',
                owner_percent: 100,
                is_company: true,
                company_creation: true
              }
          }
          
        this.$emit('submit', Object.assign({}, intOwner));  
      }
  },
  computed: {
    helpText() {
        if(this.isCompany) {
            return {
                heading: 'Information',
                paragraphs: [
                    'Skal du eje selskabet gennem et andet selskab under stiftelse ved os, tjekke du af i ”selskabet er under stiftelse”, så klarer vi resten.'
                ]
            }
        }

        return null;
    },
    isCompany() {
        return (this.owner.type === 'selskab') ? true : false;
    },
    isValid() {
        if(this.isCompany && this.intOwner.company_creation) {
            return true;
        }

        if(this.isCompany) {
            return (this.intOwner.name && 
            this.intOwner.name.length &&
            this.intOwner.address &&
            this.intOwner.address.length &&
            this.intOwner.postal.length > 0 &&
            this.intOwner.town &&
            this.intOwner.town.length > 0) ? true : false;
        }

        return (this.intOwner.name && 
        this.intOwner.name.length &&
        this.intOwner.address &&
        this.intOwner.address.length &&
        this.intOwner.postal.length > 0 &&
        this.intOwner.town &&
        this.intOwner.town.length > 0 &&
        this.intOwner.email && this.intOwner.email.length > 0 &&
        this.intOwner.phone && this.intOwner.phone.length > 0
        ) ? true : false;
    },
    title() {
        return 'Indsæt oplysninger på ' + this.numberTxt + ' ejer';
    },
    numberTxt() {
        if(!this.multipleOwners) {
            return '';
        }

        switch(this.ownerNumber) {
            case 1:
                return 'første';
            case 2:
                return 'anden';
            case 3:
                return 'tredje';
            case 4:
                return 'fjerde';
            case 5:
                return 'femte';
            case 6:
                return 'sjette';
            case 7:
                return 'syvende';
            case 8:
                return 'ottende';
            default:
                return this.ownerNumber + '.';
        }
    }
  },
  components: {
    'slide': Slide,
    'inputField': InputField
  }
}
</script>

