<template>
  <div
    class="lead-info lead-info--log-messages"
    v-if="lead"
    :class="{ loading: loading }"
    ref="logmessages"
  >
    <p v-if="error" class="error">
      Der skete en fejl og data kunne ikke hentes...
      <button class="btn btn--default" @click="reload">Prøv igen</button>
    </p>

    <div class="messages">
      <status-log-message
        :statuslog="logEntry"
        :lead="lead"
        :key="logEntry.id"
        :containsCaseData="containsCaseData"
        v-for="logEntry in logList"
        @contactmethod="setContactMethod"
        @setcontacttime="setContactTime"
        @viewcustomerinvoice="viewCustomerInvoice"
        @viewproductinvoice="viewProductInvoice"
        @comment="setComment"
        @viewcomment="viewComment"
        @setcustomerinfo="setCustomerInfo"
        @setcasedata="setCaseData"
        @addrelatedproducts="setRelatedProducts"
      ></status-log-message>
    </div>
  </div>
</template>

<script>
import DataStore from "../../services/datastore";
import StatusLogMessage from "./lead-status-log-message.vue";
import LOG_STATUS_TYPES from "./logstatus-types";
import Momemnt from "moment";
import { HasCaseDataToFill } from "./casedata-util";
import { NextCallTime } from "./call-date-util";

let scrollTimer = null;

export default {
  props: {
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      statusLog: [],
      loading: false,
      error: false,
    };
  },
  watch: {
    lead() {
      this.getContactStatusLog();
    },
    logList(newLogList, oldLogList) {
      this.$nextTick(() => {
        if (
          oldLogList &&
          newLogList &&
          newLogList.length != oldLogList.length
        ) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    scrollToBottom() {
      if (window.innerWidth > 900) {
        window.scrollTo(0, document.body.scrollHeight);
      } else if (this.$refs.logmessages) {
        if (scrollTimer) {
          clearTimeout(scrollTimer);
        }

        scrollTimer = setTimeout(() => {
          let offset = window.outerHeight - this.$refs.logmessages.clientHeight;
          this.$refs.logmessages.scrollTo(
            0,
            this.$refs.logmessages.scrollHeight + offset
          );
        }, 10);
      }
    },
    reload() {
      this.statusLog = [];
      return this.getContactStatusLog();
    },
    getContactStatusLog(force = false) {
      if (this.loading && !force) return;

      this.loading = true;
      this.error = false;
      return DataStore.getLeadContactLog(this.lead.hash)
        .then(({ data }) => {
          this.statusLog = data;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.error = true;
        });
    },
    setContactMethod(set_contact_method) {
      if (this.loading) return;
      this.loading = true;

      let params = {
        set_contact_method,
      };

      if (set_contact_method == "phone" && this.defaultCallDate) {
        params.update_contact_time = this.defaultCallDate;
      }

      DataStore.updateLead(this.lead.hash, params)
        .then(({ data }) => {
          this.$emit("input", Object.assign({}, this.lead, data));
          return this.getContactStatusLog(true);
        })
        .catch((err) => {
          console.error(err);
          this.statusLog.push({
            contact_log_status: 501,
            creted_at: Momemnt().toISOString(),
          });
        });
    },
    setRelatedProducts(setRelatedProducts) {
      if (this.loading) return;
      this.loading = true;

      let params = {
        use_related_products: setRelatedProducts ? 1 : 0,
      };

      DataStore.updateLead(this.lead.hash, params)
        .then(({ data }) => {
          this.$emit("input", Object.assign({}, this.lead, data));
          return this.getContactStatusLog(true);
        })
        .catch((err) => {
          console.error(err);
          this.statusLog.push({
            contact_log_status: 501,
            creted_at: Momemnt().toISOString(),
          });
        });
    },
    setContactTime() {
      this.$router.push({
        name: "lead-view",
        params: {
          view: "book-telefon-mode",
        },
      });
    },
    viewCustomerInvoice() {
      if (this.leadDisabled) {
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: {
          view: "customer-recept",
        },
      });
    },
    viewProductInvoice(productid) {
      if (!productid || this.leadDisabled) {
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: {
          view: "invoice",
        },
        query: {
          productid,
        },
      });
    },
    setComment() {
      this.$router.push({
        name: "lead-view",
        params: {
          view: "kommentar",
        },
      });
    },
    viewComment(commentidx) {
      this.$router.push({
        name: "lead-view",
        params: {
          view: "view-comment",
        },
        query: {
          commentid: commentidx,
        },
      });
    },
    setCustomerInfo() {
      if (this.lead.customer_verified) {
        this.$router.push({
          name: "lead-view",
          params: { view: "customer-recept" },
        });
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: { view: "kundevalidering" },
      });
    },
    setCaseData() {
      this.$router.push({
        name: "lead-view",
        params: { view: "products" },
      });
    },
    getMainProductName() {
      if(!this.products.length) {
        return '';
      }

      let mainProduct = this.products[0];
      return (mainProduct.product && mainProduct.product.title) ? mainProduct.product.title : '';
    },
    getRelatedProductNames() {
      let relatedProductsNames = this.relatedCampaignProducts
        .map((product) => (product.product && product.product.title) ? product.product.title : '' )
        .filter((title) => title);

      if(relatedProductsNames.length) {
        let lastProductName = relatedProductsNames.pop();

        if(relatedProductsNames.length) {
          return relatedProductsNames.join(', ') + ' og ' + lastProductName;
        } else {
          return lastProductName;
        }
      }

      return '';
    },
  },
  computed: {
    leadDisabled() {
      return this.lead.contact_state == 4 ? true : false;
    },
    logList() {
      return this.statusLog.map((message) => {
        let logType = LOG_STATUS_TYPES[message.contact_log_status];

        let showActions = false;
        if (logType == "WELCOME_MESSAGE") {
          let actionReponseLogs = this.statusLog.filter(
            ({ contact_log_status }) => {
              return (
                [
                  "WE_CALl_MESSAGE",
                  "WE_WRITE_MESSAGE",
                  "LEAD_ARCHIVED",
                  "WE_CALL_MESSAGE_RELATED_PRODUCTS",
                  "WE_WRITE_MESSAGE_RELATED_PRODUCTS"
                ].indexOf(LOG_STATUS_TYPES[contact_log_status]) > -1
              );
            }
          );

          showActions = actionReponseLogs.length <= 0 ? true : false;
        } else if (logType == "WE_CALL_MESSAGE_RELATED_PRODUCTS" || logType == 'WE_WRITE_MESSAGE_RELATED_PRODUCTS') {
          let actionReponseLogs = this.statusLog.filter(
            ({ contact_log_status }) => {
              return (
                [
                  "RELATED_PRODUCTS_ACCEPTED",
                  "RELATED_PRODUCTS_REJECTED",
                ].indexOf(LOG_STATUS_TYPES[contact_log_status]) > -1
              );
            }
          );

          showActions = actionReponseLogs.length <= 0 ? true : false;
          message = Object.assign({}, message, {
            mainProductName: this.mainProductName, 
            relatedProductNames: this.relatedProductNames, 
          });
        } else if (logType == "CUSTOMER_COMMENT_RECEIVED") {
          let commentid =
            message.metadata && message.metadata.commentid
              ? message.metadata.commentid
              : null;
          if (commentid) {
            let comment = this.$store.state.sendLeadComments.filter(
              ({ id }) => id == commentid
            );
            if (comment.length) {
              message = Object.assign({}, message, {
                commentidx: this.$store.state.sendLeadComments.indexOf(
                  comment[0]
                ),
              });
            }
          }
        }

        return Object.assign({}, message, {
          logType,
          showActions,
        });
      });
    },
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    relatedCampaignProducts() {
      return this.lead && this.lead.campaign_id && this.lead.campaign
        ? this.lead.campaign.products.filter(({ related }) => related)
        : [];
    },
    mainProductName() {
      if(!this.products.length) {
        return '';
      }

      let mainProduct = this.products[0];
      return (mainProduct.product && mainProduct.product.title) ? mainProduct.product.title.toLowerCase() : '';
    },
    relatedProductNames() {
      let relatedProductsNames = this.relatedCampaignProducts
        .map((product) => (product.product && product.product.title) ? product.product.title.toLowerCase() : '' )
        .filter((title) => title);

      if(relatedProductsNames.length) {
        let lastProductName = relatedProductsNames.pop();

        if(relatedProductsNames.length) {
          return relatedProductsNames.join(', ') + ' og ' + lastProductName;
        } else {
          return lastProductName;
        }
      }

      return '';
    },
    containsCaseData() {
      return this.products.filter((leadProduct) =>
        HasCaseDataToFill(leadProduct)
      ).length > 0
        ? true
        : false;
    },
    defaultCallDate() {
      let date = NextCallTime();
      return date ? date.format("DD-MM-YYYY HH:mm") : null;
    },
  },
  components: {
    StatusLogMessage,
  },
};
</script>