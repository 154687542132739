var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lead && _vm.product
    ? _c(
        "div",
        {
          staticClass: "order-flow order-flow--company",
          class: { loading: _vm.loading }
        },
        [
          _c("order-flow-progress", {
            attrs: { percentComplete: _vm.percentComplete }
          }),
          _vm._v(" "),
          _vm.hasNavigated && !_vm.lockSlideNumbers
            ? _c("div", { staticClass: "order-flow__back" }, [
                _c(
                  "button",
                  {
                    staticClass: "order-flow__back__btn",
                    on: { click: _vm.goToPrevSlide }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 177 162.86"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M168,72.43H30.73L87.79,15.36A9,9,0,1,0,75.07,2.64L2.64,75.06c-.21.21-.41.44-.6.67a3.15,3.15,0,0,0-.22.29l-.31.41c-.08.13-.15.26-.23.38l-.22.38,0,0c-.06.12-.11.24-.16.35s-.13.26-.18.4-.1.26-.15.4-.11.28-.15.43-.07.27-.11.41-.08.29-.11.44-.05.32-.07.48L0,80.54a10.58,10.58,0,0,0,0,1.78l.06.38c0,.16,0,.33.07.49s.07.29.11.43.07.29.11.42.1.28.15.42a3.52,3.52,0,0,0,.15.42c.05.13.11.25.17.38s.13.28.2.41l.21.36c.08.13.16.27.25.4a4.61,4.61,0,0,0,.28.38,3.81,3.81,0,0,0,.24.32q.29.34.6.66h0l72.43,72.43A9,9,0,1,0,87.79,147.5L30.73,90.43H168a9,9,0,0,0,0-18Z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("company", {
            attrs: {
              "slide-number": _vm.slideNumber,
              company: _vm.companyData,
              slide: _vm.slide
            },
            on: {
              submit: _vm.setCompany,
              slide: _vm.setCompanySlide,
              percent: function(val) {
                return (_vm.percentComplete = val)
              }
            }
          }),
          _vm._v(" "),
          _vm.slide === "extra"
            ? _c("extra", {
                attrs: {
                  extra: _vm.extra,
                  loading: _vm.loading,
                  error: _vm.error
                },
                on: { submit: _vm.setExtra }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.slide === "thanks"
            ? _c("thanks", {
                attrs: { lead: _vm.lead, product: _vm.product },
                on: {
                  submit: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3eaa772e", { render: render, staticRenderFns: staticRenderFns })
  }
}