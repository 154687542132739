var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: "Fremsendelse af ID", show: true, showActions: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "validate-modal" }, [
        _c("div", { staticClass: "validate-modal__box" }, [
          _c("h2", [_vm._v("Identifikation")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        Vi er, som advokater, underlagt hvidvaskloven. Vi er således\n        forpligtet til at validere vores klienters identitet. Vi må ikke\n        påbegynde arbejdet, førend vi har modtaget legitimationsoplysninger.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        I jeres tilfælde, skal vi have identificeret virksomhedens reelle\n        ejere og modtage ID på disse. Nedenfor kan du se, hvilke reelle ejere\n        vi har identificeret og status på legitimationsprocessen.\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { class: { loading: _vm.loading } }, [
            !_vm.loading
              ? _c("table", { staticClass: "validation-table" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("th", [_vm._v("Navn")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Status")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Link")])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.ownerList, function(owner) {
                        return _c("tr", { key: owner.name }, [
                          _c("td", {
                            domProps: { textContent: _vm._s(owner.name) }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: { textContent: _vm._s(owner.status) }
                          }),
                          _vm._v(" "),
                          _c("td", [
                            owner.link
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: owner.link,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Åben link")]
                                )
                              : _vm._e()
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("p", {
                  staticClass: "error",
                  domProps: { textContent: _vm._s(_vm.error) }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "deposit-info" }, [
            _c("h3", { staticClass: "deposit-info__heading" }, [
              _vm._v("Praktisk information")
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "deposit-info__icon",
              attrs: { src: "/assets/img/icons/icon-info.svg" }
            }),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Har du spørgsmål til fremsendelse af ID, kan disse rettes til\n          sagsbehandleren på din sag.\n        "
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-db5c24d4", { render: render, staticRenderFns: staticRenderFns })
  }
}