<template>
    <div class="datetime-picker input-field">
        <div class="label-input">
            <input class="input input-field"
                   v-bind:class="{ 'is-danger': error, 'has-text': hasText, 'working': !isReady }"
                   type="text"
                   v-bind:name="fieldkey"
                   v-model="value"
                   @click="showCal"
                   @blur="leave"
                   readonly="true"
            >
            <label class="label">{{ name }}</label>
            <div v-if="error">
                <span class="tag-label">{{ errormsg }}</span>
            </div>
        </div>
        <div class="picker-wrap" v-show="show">
            <table class="date-picker">
                <thead>
                <tr class="date-head">
                    <th colspan="4">
                        <span class="show-year">{{now.getFullYear()}}</span>
                    </th>
                    <th colspan="3">
                        <span class="btn-prev" @click="monthClick(-1)">&lt;</span>
                        <span class="show-month">{{months[now.getMonth()]}}</span>
                        <span class="btn-next" @click="monthClick(1)">&gt;</span>
                    </th>
                </tr>
                <tr class="date-days">
                    <th v-for="day in days">{{day}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in 6">
                    <td v-for="(item, j) in 7"
                        :class="date[i * 7 + j] && date[i * 7 + j].status"
                        :date="date[i * 7 + j] && date[i * 7 + j].date"
                        @click="pickDate(i * 7 + j)">{{date[i * 7 + j] && date[i * 7 + j].text}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Moment from 'moment';

    export default {
        props: {
            width: { type: String, default: '238px' },
            readonly: { type: Boolean, default: false },
            format: { type: String, default: 'YYYY-MM-DD' },
            tabIndex: '',
            error: '',
            fieldkey: {type: String, default: 'date'},
            name: {type: String, default: ''},
            busyDays: {type: Object, default: () => { return {}}},
            isReady: {type: Boolean, default: false},
            defaultDate: {type: String, default: null},
            selectWeekends: {type: Boolean, default: false},
            disbaleAfterDate: {type: String, default: ''},
            disbaleBeforeDate: {type: String, default: ''},
            selectPast: {type: Boolean, default: false}
        },
        data () {
            return {
                show: false,
                days:  ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
                months:  ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                date: [],
                value: null,
                now: new Date()
            };
        },
        watch: {
            now () {
                this.update();
            },
            show () {
                this.update();
            },
            busyDays() {
                this.update();
            },
            defaultDate() {
                if(this.defaultDate) {
                    this.value = this.defaultDate;
                    this.now = this.parse(this.value);
                }
            }
        },
        computed: {
            hasText() {
                if(!this.value) {
                    return false;
                }

                return (this.value.length > 0);
            },

            errormsg() {
                if(!this.error) {
                    return '';
                }

                return (typeof this.error[0] !== 'undefined') ? this.error[0] : this.error;
            },

            allDaysEvents() {
                var allDays = [ ];

                for(var i in this.busyDays) {
                    if(this.busyDays[i].allDay) {
                        allDays[i] = this.busyDays[i];
                    }
                }
                return allDays;
            }
        },
        methods: {
            showCal() {
                if(this.isReady) {
                    this.show = true;
                }
            },
            busyDay(date) {
                var today = Moment().hour(17);
                date = Moment(date).utcOffset("+00:00", true);

                if(!this.selectPast && date.diff(today, 'days') < 0) {
                    return true;
                }


                if(!this.selectWeekends && date.isoWeekday() > 5) {
                    return true;
                }

                if(this.disbaleAfterDate && date.isAfter(Moment(this.disbaleAfterDate, 'YYYY-MM-DD'))) {
                   return true;
                }

                if(this.disbaleBeforeDate && date.isBefore(Moment(this.disbaleBeforeDate, 'YYYY-MM-DD'))) {
                   return true;
                }

                for(var i in this.allDaysEvents) {
                    var event = this.allDaysEvents[i];
                    if(date.isBetween(event.startTime.date, event.endTime.date)) {
                        return true;
                    }
                }

                return false;
            },

            close () {
                this.show = false;
            },
            update () {
                var arr = [];
                var time = new Date(this.now);
                time.setMonth(time.getMonth(), 1);           // the first day
                var curFirstDay = (time.getDay() == 0) ? 6 : time.getDay() - 1;
                time.setDate(0);                             // the last day
                var lastDayCount = time.getDate();
                for (let i = curFirstDay; i > 0; i--) {
                    arr.push({
                        text: lastDayCount - i + 1,
                        time: new Date(time.getFullYear(), time.getMonth(), lastDayCount - i),
                        status: 'date-pass'
                    });
                }

                time.setMonth(time.getMonth() + 2, 0);       // the last day of this month
                var curDayCount = time.getDate();
                time.setDate(1);                             // fix bug when month change
                var value = this.value || this.stringify(new Date());
                for (let i = 0; i < curDayCount; i++) {
                    let tmpTime = new Date(time.getFullYear(), time.getMonth(), i + 1);
                    let status = '';
                    this.stringify(tmpTime) === value && (status = 'date-active');
                    arr.push({
                        text: i + 1,
                        time: tmpTime,
                        status: (this.busyDay(tmpTime)) ? 'date-pass' : status
                    });
                }

                var j = 1;
                while (arr.length < 42) {
                    arr.push({
                        text: j,
                        time: new Date(time.getFullYear(), time.getMonth() + 1, j),
                        status: 'date-future'
                    });
                    j++;
                }
                this.date = arr;
            },
            yearClick (flag) {
                this.now.setFullYear(this.now.getFullYear() + flag);
                this.now = new Date(this.now);
            },
            monthClick (flag) {
                this.now.setMonth(this.now.getMonth() + flag);
                this.now = new Date(this.now);
            },
            pickDate (index) {
                if(this.busyDay(this.date[index].time)) {
                    return;
                }

                this.show = false;
                this.now = new Date(this.date[index].time);
                this.value = this.stringify();
                this.$emit('pickdate', this.value);
            },
            parse (str) {
                var time = Moment(str, this.format).toDate();
                return isNaN(time.getTime()) ? null : time;
            },
            stringify (time = this.now, format = this.format) {
                var year = time.getFullYear();
                var month = time.getMonth() + 1;
                var date = time.getDate();
                var monthName = this.months[time.getMonth()];

                var map = {
                    YYYY: year,
                    MMM: monthName,
                    MM: ('0' + month).slice(-2),
                    M: month,
                    DD: ('0' + date).slice(-2),
                    D: date
                };
                return format.replace(/Y+|M+|D+/g, function (str) {
                    return map[str];
                });
            },
            leave (e) {
                if (!this.$el.contains(e.target) || e.relatedTarget) {
                    this.close();
                }
            }
        },
        mounted() {
            if(this.defaultDate) {
                this.value = this.defaultDate;
                this.now = this.parse(this.value);
            } else {
                this.value = null;
                this.now = new Date();
            }

            document.addEventListener('click', this.leave, false);
        },
        beforeDestroy () {
            document.removeEventListener('click', this.leave, false);
        }
    };
</script>