var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--4",
      attrs: {
        title: _vm.title,
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isCompany
            ? _c("div", { staticClass: "label-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.intOwner.company_creation,
                      expression: "intOwner.company_creation"
                    }
                  ],
                  attrs: { type: "checkbox", id: "is_company_creation" },
                  domProps: {
                    checked: Array.isArray(_vm.intOwner.company_creation)
                      ? _vm._i(_vm.intOwner.company_creation, null) > -1
                      : _vm.intOwner.company_creation
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.intOwner.company_creation,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.intOwner,
                              "company_creation",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.intOwner,
                              "company_creation",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.intOwner, "company_creation", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "label",
                    attrs: { for: "is_company_creation" }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "check" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "box" }),
                    _vm._v("Selskabet er under stiftelse\n                    ")
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.intOwner.company_creation
            ? [
                _c("inputField", {
                  attrs: { name: "Navn", value: _vm.intOwner.name },
                  on: {
                    input: function(val) {
                      _vm.intOwner.name = val
                    }
                  }
                }),
                _vm._v(" "),
                _c("inputField", {
                  attrs: { name: "Adresse", value: _vm.intOwner.address },
                  on: {
                    input: function(val) {
                      _vm.intOwner.address = val
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-flow__content__two-col" },
                  [
                    _c("inputField", {
                      attrs: {
                        name: "Post nr.",
                        value: _vm.intOwner.postal.toString(),
                        "input-type": "tel"
                      },
                      on: {
                        input: function(val) {
                          _vm.intOwner.postal = val
                        },
                        stopTyping: _vm.getTownByZip
                      }
                    }),
                    _vm._v(" "),
                    _c("inputField", {
                      attrs: { name: "By", value: _vm.intOwner.town },
                      on: {
                        input: function(val) {
                          _vm.intOwner.town = val
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("inputField", {
                  attrs: { name: "Land", value: _vm.intOwner.country },
                  on: {
                    input: function(val) {
                      _vm.intOwner.country = val
                    }
                  }
                }),
                _vm._v(" "),
                !_vm.isCompany
                  ? _c("inputField", {
                      attrs: {
                        name: "E-mail",
                        value: _vm.intOwner.email,
                        "input-type": "email"
                      },
                      on: {
                        input: function(val) {
                          _vm.intOwner.email = val
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isCompany
                  ? _c("inputField", {
                      attrs: {
                        name: "Tlf",
                        value: _vm.intOwner.phone,
                        "input-type": "tel"
                      },
                      on: {
                        input: function(val) {
                          _vm.intOwner.phone = val
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.intOwner.is_company
                  ? _c("inputField", {
                      attrs: {
                        name: "CPR-nr",
                        value: _vm.intOwner.secret_identifier,
                        mask: "999999-9999",
                        "input-type": "tel"
                      },
                      on: {
                        input: function(val) {
                          _vm.intOwner.secret_identifier = val.substr(0, 11)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.intOwner.is_company
                  ? _c("inputField", {
                      attrs: {
                        name: "CVR-nr",
                        value: _vm.intOwner.secret_identifier,
                        mask: "99999999",
                        "input-type": "tel"
                      },
                      on: {
                        input: function(val) {
                          _vm.intOwner.secret_identifier = val.substr(0, 8)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("inputField", {
                  attrs: {
                    name: "Ejerandel i %",
                    value: _vm.intOwner.owner_percent.toString(),
                    mask: "999",
                    "input-type": "tel"
                  },
                  on: {
                    input: function(val) {
                      _vm.intOwner.owner_percent = parseInt(val.substr(0, 3))
                    },
                    enter: function($event) {
                      return _vm.submit()
                    }
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e27ba67a", { render: render, staticRenderFns: staticRenderFns })
  }
}