var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box box-primary" },
    [
      _c("inline-msg-thread", {
        ref: "mesageWindow",
        staticClass: "box-body",
        attrs: { messages: _vm.messages, part: _vm.part }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bbe50e38", { render: render, staticRenderFns: staticRenderFns })
  }
}