<template>
    <slide title="Skal vi hjælpe med andet?" subTitle="Alle priser er ekskl. moms" class="order-flow__wrapper--7" @next="submit()" :validated="isValid">
        <template slot="content">
                    <div class="label-checkbox">
                        <input type="checkbox" id="check-1" v-model="intOtherStuff.registerTax"/>
                        <label for="check-1" class="label">
                            <span class="circle"></span>
                            <span class="check"></span>
                            <span class="box"></span>Momsregistrering (kr. 495,00)
                        </label>
                    </div>
                    <div class="label-checkbox">
                        <input type="checkbox" id="check-2" v-model="intOtherStuff.registerAsCeo"/>
                        <label for="check-2" class="label">
                            <span class="circle"></span>
                            <span class="check"></span>
                            <span class="box"></span>Arbejdsgiverregistrering (kr. 195,00)
                        </label>
                    </div>
                    <div class="label-checkbox">
                        <input type="checkbox" id="check-3" v-model="intOtherStuff.registerImportExport"/>
                        <label for="check-3" class="label">
                            <span class="circle"></span>
                            <span class="check"></span>
                            <span class="box"></span>Import/eksport registrering (kr. 195,00)
                        </label>
                    </div>
                    <div class="total-price">
                        <p>I alt: <span v-price="otherStuffPrice"></span></p>
                    </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
export default {
  props: {
    otherStuff: {type: Object, default() {return null}}
  },
  data() {
      return {
          intOtherStuff: {
              registerTax: false,
              registerAsCeo: false,
              registerImportExport: false
          }
      }
  },
  mounted() {
      if(this.otherStuff) {
          this.intOtherStuff = Object.assign({}, this.intOtherStuff, this.otherStuff);
      }
  },
  watch: {
      otherStuff() {
        this.intOtherStuff = Object.assign({}, this.intOtherStuff, this.otherStuff);
      }
  },
  methods: {
      submit() {
        this.$emit('submit', Object.assign({}, this.intOtherStuff));  
      }
  },
  computed: {
    isValid() {
        return true;
    },
    otherStuffPrice() {
          let otherStuff = this.intOtherStuff;          
          let stuffPrice = 0;

          if(otherStuff.registerTax) {
              stuffPrice += 495;
          }

          if(otherStuff.registerAsCeo) {
              stuffPrice += 195;
          }

          if(otherStuff.registerImportExport) {
              stuffPrice += 195;
          }

          return stuffPrice;
      }
  },
  components: {
    'slide': Slide 
  }
}
</script>