var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-profile-view", class: { loading: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "lead-section" },
        [
          !_vm.creatingOrder && !_vm.lead
            ? _c("div", { staticClass: "main-content__wrapper" }, [
                _c(
                  "div",
                  { staticClass: "lead-header lead-header--two-steps" },
                  [
                    _c("div", { staticClass: "lead-header__wrapper" }, [
                      _c("h2", {
                        staticClass: "lead-header__heading",
                        domProps: { innerHTML: _vm._s(_vm.pageHeader) }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "lead-header__sub-heading",
                        domProps: { innerHTML: _vm._s(_vm.pageSubHeading) }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lead-form" }, [
                  _c(
                    "div",
                    { staticClass: "lead-form__fields" },
                    [
                      _c("input-field", {
                        attrs: {
                          name: "Fulde navn",
                          value: _vm.name,
                          error: _vm.nameError
                        },
                        on: {
                          input: function(val) {
                            this$1.name = val
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input-field", {
                        attrs: {
                          name: "Telefon",
                          value: _vm.phone,
                          "input-type": "tel",
                          error: _vm.phoneError,
                          mask: "99999999"
                        },
                        on: {
                          input: function(val) {
                            this$1.phone = val
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("input-field", {
                        attrs: {
                          name: "E-mail",
                          value: _vm.email,
                          "input-type": "email",
                          error: _vm.emailError
                        },
                        on: {
                          input: function(val) {
                            this$1.email = val
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.pageParagraphs, function(paragraph, key) {
                        return _c("p", {
                          key: key,
                          staticClass: "lead-form__paragraph",
                          domProps: { innerHTML: _vm._s(paragraph) }
                        })
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error"
                            }
                          ],
                          staticClass: "error"
                        },
                        [
                          _vm._v(
                            "\n            Der skete en fejl under oprettelsen.. Tjek du har udfyldt alle\n            felter korrekt...\n          "
                          )
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--positive",
                      class: { loading: _vm.loading },
                      attrs: {
                        href: "javascript:void(0)",
                        disabled: _vm.loading
                      },
                      on: { click: _vm.createNewCase }
                    },
                    [_vm._v("Videre")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "datapolicy-link",
                      attrs: { href: _vm.policyLink, target: "_blank" }
                    },
                    [_vm._v("Se hvordan vi håndterer dine data")]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.creatingOrder || _vm.lead
            ? [
                _c(
                  "div",
                  {
                    staticClass: "main-content__wrapper",
                    class: { "creating-animation--loading": _vm.creatingOrder }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lead-header lead-header--two-steps" },
                      [
                        _c("div", { staticClass: "lead-header__wrapper" }, [
                          _vm.lead && !_vm.creatingOrder
                            ? _c(
                                "h2",
                                { staticClass: "lead-header__heading" },
                                [
                                  _vm._v(
                                    "\n              Så er vi klar\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.creatingOrder
                            ? _c(
                                "h2",
                                { staticClass: "lead-header__heading" },
                                [
                                  _vm._v(
                                    "\n              Hæng på mens vi samler et overblik til dig...\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.creatingOrder
                            ? _c("img", {
                                staticClass: "lead-header__loading",
                                attrs: {
                                  src: require("../../img/rotate-loading.svg")
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.lead && !_vm.creatingOrder
                            ? _c("img", {
                                staticClass: "lead-header__ready",
                                attrs: {
                                  src: require("../../img/icons/icon-check.svg")
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "lead-form" },
                          [
                            _vm.lead && !_vm.creatingOrder
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn--positive",
                                    attrs: {
                                      to: {
                                        name: "lead-view",
                                        params: {
                                          hash: this.lead.hash
                                        }
                                      },
                                      replace: ""
                                    }
                                  },
                                  [_vm._v("Gå videre")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            : [
                _c("div", { staticClass: "trust-block" }, [
                  _c("div", { staticClass: "trust-block__content" }, [
                    _c("div", { staticClass: "trust-block__content__text" }, [
                      _c("h2", {
                        domProps: { textContent: _vm._s(_vm.pageTrustHeader) }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        domProps: { innerHTML: _vm._s(_vm.pageTrustContent) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "trust-block__content__image" }, [
                      _c("img", {
                        ref: "campaignImage",
                        attrs: { src: _vm.campaignImageSource }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm._m(0)
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nem-footer" }, [
      _c("div", { staticClass: "nem-footer__content" }, [
        _c(
          "a",
          {
            staticClass: "nem-footer__link",
            attrs: {
              href:
                "http://www.advokatnoeglen.dk/sog.aspx?s=1&t=0&firm=Nemadvokat"
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../img/advokatsamfundet-logo.png"),
                alt: "Advokatsamfundet"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nem-footer__link",
            attrs: {
              href:
                "http://www.advokatnoeglen.dk/sog.aspx?s=1&t=0&firm=Nemadvokat"
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../img/borsen_gazelle-logo.png"),
                alt: "Børsen Gazelle"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nem-footer__link",
            attrs: {
              href:
                "https://dk.trustpilot.com/review/nemadvokat.dk?utm_medium=trustboxes&utm_source=Mini"
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("../../img/trustpilot-review-logo.png"),
                alt: "Trustpilot"
              }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5de63b8f", { render: render, staticRenderFns: staticRenderFns })
  }
}