var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-login", class: { loading: _vm.loading } },
    [
      _c("div", { staticClass: "profile-login__wrapper" }, [
        _c("h2", {
          staticClass: "profile-login__header profile-login__header--center",
          domProps: { textContent: _vm._s(_vm.status) }
        }),
        _vm._v(" "),
        _vm.success
          ? _c(
              "p",
              {
                staticClass: "profile-login__text profile-login__text--center"
              },
              [_vm._v("\n      Du blev successfuldt logget ud.\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c(
              "p",
              {
                staticClass: "profile-login__text profile-login__text--center"
              },
              [
                _vm._v(
                  "\n      Der skete en fejl og du blev ikke logget korrekt ud. Klik\n      "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.logOut }
                  },
                  [_vm._v("her")]
                ),
                _vm._v(" for at forsøge\n      igen.\n    ")
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-57730a9a", { render: render, staticRenderFns: staticRenderFns })
  }
}