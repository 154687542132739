var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order.caseid
    ? _c(
        "div",
        {
          staticClass: "order-item",
          class: {
            "multiple-letters": _vm.order.multiple_letters,
            active: _vm.active,
            "new-messages": _vm.newMessageCount > 0,
            archived: _vm.orderState == "archived"
          },
          on: { click: _vm.showOrder }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "order-item__content" }, [
            _c("span", {
              staticClass: "order-item__caseid",
              domProps: { textContent: _vm._s(_vm.order.caseid) }
            }),
            _vm._v(" "),
            _c("h2", {
              staticClass: "order-item__title",
              domProps: { textContent: _vm._s(_vm.order.key_field) }
            })
          ]),
          _vm._v(" "),
          _vm.newMessageCount
            ? _c("span", {
                staticClass: "order-item__msg-counter",
                domProps: { textContent: _vm._s(_vm.newMessageCount) }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-item__icon" }, [
      _c("img", { attrs: { src: "/assets/img/icons/icon-case.svg" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bf4e6e8c", { render: render, staticRenderFns: staticRenderFns })
  }
}