<template>
    <slide title="Er der andre produkter du vil høre mere om?" class="order-flow__wrapper--8" @next="submit()" :validated="false">
        <template slot="content">
            <div class="label-checkbox">
                <input type="checkbox" id="check-1" v-model="intExtraOptions.ownerdeal"/>
                <label for="check-1" class="label">
                    <span class="circle"></span>
                    <span class="check"></span>
                    <span class="box"></span>Ejeraftale
                </label>
            </div>
            <div class="label-checkbox">
                <input type="checkbox" id="check-2" v-model="intExtraOptions.ceocontract"/>
                <label for="check-2" class="label">
                    <span class="circle"></span>
                    <span class="check"></span>
                    <span class="box"></span>Direktørkontrakt
                </label>
            </div>
            <div class="label-checkbox">
                <input type="checkbox" id="check-3" v-model="intExtraOptions.trademark"/>
                <label for="check-3" class="label">
                    <span class="circle"></span>
                    <span class="check"></span>
                    <span class="box"></span>Varemærkeregistrering
                </label>
            </div>
            <div class="label-checkbox">
                <input type="checkbox" id="check-4" v-model="intExtraOptions.debt"/>
                <label for="check-4" class="label">
                    <span class="circle"></span>
                    <span class="check"></span>
                    <span class="box"></span>Gældsbrev på anpartshaverlån
                </label>
            </div>
            <input-type name="Andet vi skal drøfte når vi ringer?" :value="intExtraOptions.extra" @input="(val) => { $set(intExtraOptions, 'extra', val)}" input-type="textarea"></input-type>
        </template>
        <template slot="actions">
            <button class="btn btn--show" :class="{'btn--loading': loading}" @click="submit()" :disabled="loading">Afslut</button>
            <p class="error" v-if="error">Der skete en fejl under bestillingen! Tjek at du har internet og prøv igen...</p>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
import inputFieldVue from '../../input-field/input-field.vue';

export default {
  props: {
      loading: {type: Boolean, default: false},
      error: {type: Boolean, default: false},
      extra: {defualt: Object, default() {
          return {
              ownerdeal: false,
              ceocontract: false,
              trademark: false,
              debt: false,
              extra: ''
          }
      }}
  },
  data() {
      return {
          intExtraOptions: { }
      }
  },
  mounted() {
      this.intExtraOptions = Object.assign({}, this.extra);
  },
  watch: {
      extra() {
          this.intExtraOptions = Object.assign({}, this.extra);
      }
  },
  methods: {
      submit() {
        this.$emit('submit', this.intExtraOptions);
      }
  },
  components: {
    'slide': Slide,
    'input-type': inputFieldVue
  }
}
</script>