<template>
  <modal title="Udfyld ordredata" :show="true" :noScroll="false" @close="onClose" ref="modal">
    <company-lead-data
      v-if="lead"
      :lead="lead"
      @updated="(udpatedLead) => $emit('input', udpatedLead)"
      @close="onFinish"
    ></company-lead-data>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import CompanyLeadData from "./../create-company-order/create-company.vue";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  watch: {
    $route() {
      this.scrollToTop();
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.modal.scrollToTop();
    },
    onClose() {
      this.$router.push({
        name: "lead-view",
        params: { view: null, slide: null },
      });
    },
    onFinish() {
      this.$nextTick(() => {
        if (this.moreDataToFill) {
          this.$router.push({
            name: "lead-view",
            params: { view: "products", slide: null },
          });
        } else {
          this.onClose();
        }
      });
    },
  },
  computed: {
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    moreDataToFill() {
      return this.products.filter(
        ({ company_data_filled }) => !company_data_filled
      ).length > 0
        ? true
        : false;
    },
  },
  components: {
    Modal,
    CompanyLeadData,
  },
};
</script>