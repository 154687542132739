<template>
    <slide :title="title" class="order-flow__wrapper--3" @next="submit()" :validated="isValid" :overwrite-save-btn="true">
        <template slot="content">
            <div class="order-tiles" :class="{'order-tiles--selection': isValid}">
                <div class="order-tiles__item order-tiles__item--1" :class="{'order-tiles__item--selected': (intOwnerType === 'person')}" @click="selectType('person')">
                    <img src="/assets/img/icons/icon-company-1.svg" alt="Ikon af person">
                    <span class="order-tiles__item__heading">Person</span>
                    <span class="order-tiles__item__text">Anparterne ejes direkte personligt</span>
                </div>
                <div class="order-tiles__item order-tiles__item--2" :class="{'order-tiles__item--selected': (intOwnerType === 'selskab')}" @click="selectType('selskab')">
                    <img src="/assets/img/icons/icon-company-2.svg" alt="Ikon af selskab">
                    <span class="order-tiles__item__heading">Selskab</span>
                    <span class="order-tiles__item__text">Anparterne ejes af et eksisterende kapitalselskab</span>
                </div>
            </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
export default {
  props: {
      ownerNumber: {type: Number, default: 1},
      ownerType: {type: String, default: null},
      multipleOwners: {type: Boolean, default: false}
  },
  data() {
      return {
          intOwnerType: null,
          helpText: {
              heading: 'Information',
              paragraphs: [
                  'Skal du eje selskabet personligt eller via et andet selskab?'
              ]
          }
      }
  },
  mounted() {
      this.intOwnerType = this.ownerType;
  },
  watch: {
      ownerType() {
          this.intOwnerType = this.ownerType;
      }
  },
  methods: {
      selectType(type) {
          this.intOwnerType = type;
      },
      submit() {
        this.$emit('submit', this.intOwnerType);  
      }
  },
  computed: {
    isValid() {
        return (this.intOwnerType) ? true : false;
    },
    title() {
        if(!this.multipleOwners) {
            return 'Vælg ejertype for ejer';
        }

        return 'Vælg ejertype for den ' + this.numberTxt + ' ejer';
    },
    numberTxt() {
        switch(this.ownerNumber) {
            case 1:
                return 'første';
            case 2:
                return 'anden';
            case 3:
                return 'tredje';
            case 4:
                return 'fjerde';
            case 5:
                return 'femte';
            case 6:
                return 'sjette';
            case 7:
                return 'syvende';
            case 8:
                return 'ottende';
            default:
                return this.ownerNumber + '.';
        }
    }
  },
  components: {
    'slide': Slide 
  }
}
</script>

