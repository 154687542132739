<template>
  <div :class="{ loading: loading }">
    <template v-if="order">
      <case-menu :order="order"></case-menu>
      <overview
        v-if="!shownModalName && partType == 'customer'"
        :order="order"
        :customer="customer"
      ></overview>
      <messages
        v-if="shownModalName == 'messages'"
        :hash="orderHash"
        :part-hash="partHash"
        :customer="customer"
        :order="order"
        @messageread="onMessageRead"
      ></messages>
      <documents
        v-if="shownModalName == 'documents'"
        :hash="orderHash"
        :part-hash="partHash"
        :order-active="orderActive"
      ></documents>
      <economy v-if="shownModalName == 'economy'" :order="order"></economy>
    </template>
  </div>
</template>

<script>
import DataStore from "../../services/datastore";
import Documents from "./case-documents.vue";
import Economy from "./case-economy.vue";
import CaseMenu from "./case-menu.vue";
import Overview from "./case-overview.vue";
import Messages from "./case-messages.vue";

export default {
  data() {
    return {
      loadingOrder: false,
      orderHash: null,
      order: null,
      shownModal: -1,
      modals: ["messages", "documents", "economy"],
    };
  },
  mounted() {
    this.orderHash = this.$route.params.orderhash;
    if (this.orderHash) {
      this.loadOrder();
    }

    if (this.$route.params.modal) {
      this.showModal(this.$route.params.modal);
    } else if (this.partType == "part") {
      this.showModal("messages");
    }

    messageBus.$on('approvedletter', this.onApproveLetter.bind(this));
  },
  beforeDestroy() {
    messageBus.$off('approvedletter', this.onApproveLetter.bind(this));
  },
  watch: {
    $route(to, from) {
      if (to.params.orderhash !== from.params.orderhash) {
        this.orderHash = this.$route.params.orderhash;
        this.loadOrder();
      }

      if (to.params.modal !== from.params.modal) {
        this.showModal(to.params.modal);
      }
    },
  },
  computed: {
    enableNoScroll() {
      return window.innerWidth < 900;
    },
    responsible() {
      if (!this.order) {
        return null;
      }

      return this.order.responsible;
    },
    shownModalName() {
      return this.modals[this.shownModal];
    },
    loading() {
      return this.loadingOrder;
    },

    customer() {
      let customer = this.$store.state.part;
      return customer.unique_hash ? customer : null;
    },
    partType() {
      return this.$store.state.parttype;
    },
    partHash() {
      return this.partType == "part" ? this.$route.params.hash : "";
    },
    orderActive() {
      return this.order && this.order.active ? true : false;
    },
  },
  methods: {
    onMessageRead() {
      this.order.new_messages_nem = 0;
      this.order.part_message_count = 0;
      this.$store.commit("order", this.order);
    },
    onApproveLetter() {
      this.order.letter_approved = true;
      this.$store.commit("order", this.order);
    },
    showModal(modal) {
      this.shownModal = this.modals.indexOf(modal);
    },
    closeModal() {
      this.$router.push({ name: "case", params: { modal: null } });
    },
    loadOrder() {
      if (this.loadingOrder) return;

      this.order = null;
      this.$store.commit("order", null);
      this.loadingOrder = true;
      DataStore.getOrder(this.orderHash, this.partHash)
        .then((data) => {
          this.loadingOrder = false;
          this.order = data.data;
          this.$store.commit("order", this.order);
        })
        .catch((error) => {
          console.error(error);
          if (this.$route.params.hash) {
            let name =
              this.$store.state.parttype == "part" ? "part-profile" : "profile";
            this.$router.push({
              name,
              params: { hash: this.$route.params.hash },
            });
          } else {
            this.$router.push({ path: "/" });
          }
        });
    },
  },
  components: {
    Documents,
    Economy,
    Overview,
    Messages,
    CaseMenu,
  },
};
</script>