<template>
  <div class="drop-down">
    <label v-text="title"></label>
    <select
      :value="value"
      @input="changeValue"
      :disabled="disable"
      :tabindex="tabIndex"
      class="drop-down__select"
    >
      <option
        v-for="(option, key) in options"
        :value="key"
        v-text="option"
        :key="key"
      ></option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    value: null,
    options: {
      type: Array | Object,
      default() {
        return {};
      },
    },
    disable: { type: Boolean, defualt: false },
    tabIndex: { type: Number, default: null },
  },
  methods: {
    changeValue(evt) {
      this.$emit("input", evt.srcElement.value);
    },
  },
};
</script>