var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Send en kommentar", show: true, noScroll: false },
      on: { close: _vm.onClose }
    },
    [
      _c("div", { staticClass: "lead-comment" }, [
        _c(
          "div",
          { staticClass: "lead-comment__textarea" },
          [
            _c("input-field", {
              attrs: { "input-type": "textarea" },
              model: {
                value: _vm.comment,
                callback: function($$v) {
                  _vm.comment = $$v
                },
                expression: "comment"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn--positive",
            class: { loading: _vm.loading },
            attrs: { disabled: _vm.comment.length <= 0 },
            on: { click: _vm.sendComment }
          },
          [_vm._v("\n      Send\n    ")]
        ),
        _vm._v(" "),
        _vm.error
          ? _c("p", [
              _vm._v(
                "\n      Der skete en fejl da kommentaren skulle sendes - prøv igen\n    "
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-771c3461", { render: render, staticRenderFns: staticRenderFns })
  }
}