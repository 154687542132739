<template>
    <table>
        <thead>
            <tr>
                <td v-for="(field, header) in headers" v-text="header"></td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key) in data">
                <td v-html="item[header]" v-for="(field, header) in headers" @click="onClick(tableData[key])"></td>
            </tr>
        </tbody>
    </table>
</template>

<script type="text/javascript">
    export default{
        props: {
          tableData: {type: Array, default: function() { return [ ] }},
          headers: {type: Object, default: function() {return { }}}
        },
        methods: {
          onClick(item) {
              this.$emit('click', item);
          }
        },
        computed: {
            data() {
                if(!this.tableData)
                    return [ ];

                return this.tableData.map((itemData) => {
                    let obj = { };
                    for(let i in this.headers) {
                        let field = this.headers[i];
                        if(itemData[field]) {
                            obj[i] = itemData[field];
                        }
                    }

                    return obj;
                });
            }
        }
    }
</script>