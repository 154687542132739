<template>
  <modal
    title="Kundeinformation"
    :show="true"
    @close="onClose"
    :showActions="true"
    :noScroll="false"
    class="receipt-modal receipt-modal--personal-info"
  >
    <div class="order-receipt">
      <div class="company-content">
        <div class="confirm-order-block">
          <div class="confirm-order-block__content">
            <p><span>Type:</span> {{ personType }}</p>
            <p><span>Navn:</span> {{ lead.customer_name }}</p>
            <p><span>Adresse:</span> {{ lead.customer_address }}</p>
            <p><span>Postnr.:</span> {{ lead.customer_postal }}</p>
            <p><span>By:</span> {{ lead.customer_town }}</p>
            <p><span>E-mail:</span> {{ lead.customer_email }}</p>
            <p><span>Tlf.:</span> {{ lead.customer_phone }}</p>
          </div>
        </div>
      </div>
    </div>
    <button slot="actions" class="btn btn--show" @click="onClose">Luk</button>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  methods: {
    onClose() {
      this.$router.push({ name: "lead-view", view: null, slide: null });
    },
  },
  computed: {
    personType() {
      return (this.lead && this.lead.customer_is_company) ? 'Virksomhed' : 'Person';
    }
  },
  components: {
    Modal
  },
};
</script>