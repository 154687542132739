<template>
  <div class="new-case">
    <h1 class="new-case__heading">Hvad kan vi hjælpe med?</h1>
    <h3 class="new-case__subheading" v-if="isCompany">
      Start din næste sag nedenfor. Alle priser er ekskl. moms.
    </h3>
    <h3 class="new-case__subheading" v-if="!isCompany">
      Start din næste sag nedenfor. Alle priser er inkl. moms.
    </h3>
    <div class="new-case__grid">
      <div
        class="case-item"
        v-for="(product, key) in products"
        :key="key"
      >
        <img
          :src="'/assets/img/icons/' + product.icon"
          alt
          class="case-item__icon"
        />
        <h5 class="case-item__title" v-text="product.title"></h5>
        <span class="case-item__text" v-if="product.bookmeeting"
          >Uforpligtende</span
        >
        <span
           v-else
          class="case-item__price"
          v-price="product.price"
        ></span>
        <button class="btn case-item__btn" :class="{'case-item__btn--dark': product.bookmeeting}" @click="selectProduct(product)">
          {{ product.bookmeeting ? "Betil tid" : "Start sag" }}
        </button>
      </div>
      <div class="new-case__footer">
        <p>Nogle produkttyper kan være underlagt gebyrer til staten. <span>Disse afklares inden opstart af sagen.</span></p>
      </div>
    </div>
    <new-case-modal
      v-if="product"
      @close="product = null"
      :product="product"
      :customer="customer"
      :bookMeeting="product.bookmeeting ? true : false"
      :campaign-source-id="existing_customer_source_id"
    ></new-case-modal>
    <div class="loading" v-if="productLoading"></div>
  </div>
</template>

<script>
import DataStore from "../../services/datastore";
import NewCaseModal from "./new-case-modal.vue";

const CampaognSourceSlug = "eksisterende-kunde";

export default {
  props: {
    customer: { type: Object, default: null },
  },
  data() {
    return {
      product: null,
      products: [],
      productLoading: false,
      existing_customer_source_id: null,
    };
  },
  mounted() {
    this.loadProducts();
    this.loadSourceSlug();
  },
  methods: {
    selectProduct(product) {
      this.product = Object.assign({}, product);
      this.slide = "select-product";
    },
    loadSourceSlug() {
      DataStore.campaignSource(CampaognSourceSlug).then((res) => {
        this.existing_customer_source_id = res.data.id;
      });
    },
    loadProducts() {
      this.productLoading = true;
      DataStore.campaignProductList({
        is_company: this.isCompany ? 1 : 0,
        is_customer: !this.isCompany ? 1 : 0,
      })
        .then((res) => {
          this.productLoading = false;
          this.products = res.data.map((product) => {
            switch(product.icon) {
              case 'copyright':
                product.icon = 'icon-varemaerke-registrering.svg';
                break;
              case 'company':
                product.icon = 'icon-new-aps-company.svg';
                break;
              case 'house':
                product.icon = 'icon-boligadvokat.svg';
                break;
              case 'meeting':
                product.icon = 'icon-book-meeting.svg';
                break;
              default:
                product.icon = 'icon-tinglysning.svg';
                break;
            }

            if(this.isCompany) {
              product.price = product.price_incl_vat * 0.8;
            } else {
              product.price = product.price_incl_vat;
            }

            return product;
          })
        })
        .catch((err) => {
          console.error(err);
          this.productLoading = false;
        });
    },
  },
  computed: {
    isCompany() {
      return this.customer.is_company ? true : false;
    }
  },
  components: {
    NewCaseModal,
  },
};
</script>
