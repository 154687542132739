var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case-overview__block" }, [
    _c("h3", [_vm._v("Holdet på min sag")]),
    _vm._v(" "),
    _c("div", { staticClass: "case-responsibles" }, [
      _vm.responsible
        ? _c("div", { staticClass: "case-responsible" }, [
            _c("div", { staticClass: "case-responsible__img" }, [
              _vm.responsible.image
                ? _c("img", { attrs: { src: _vm.responsible.image } })
                : _vm._e(),
              _vm._v(" "),
              !_vm.responsible.image
                ? _c("img", { attrs: { src: "/assets/img/client-icon.svg" } })
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-responsible__content" },
              [
                _c("span", {
                  staticClass: "case-responsible__text",
                  domProps: { textContent: _vm._s(_vm.responsible.fullname) }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "case-responsible__sub-text",
                  domProps: { textContent: _vm._s(_vm.responsible.title) }
                }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "case-responsible__link",
                    attrs: {
                      to: { name: _vm.pageName, params: { modal: "messages" } }
                    }
                  },
                  [_vm._v("Skriv besked")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.assistent
        ? _c("div", { staticClass: "case-responsible" }, [
            _c("div", { staticClass: "case-responsible__img" }, [
              _vm.assistent.image
                ? _c("img", { attrs: { src: _vm.assistent.image } })
                : _vm._e(),
              _vm._v(" "),
              !_vm.assistent.image
                ? _c("img", { attrs: { src: "/assets/img/client-icon.svg" } })
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-responsible__content" },
              [
                _c("span", {
                  staticClass: "case-responsible__text",
                  domProps: { textContent: _vm._s(_vm.assistent.fullname) }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "case-responsible__sub-text",
                  domProps: { textContent: _vm._s(_vm.assistent.title) }
                }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "case-responsible__link",
                    attrs: {
                      to: { name: _vm.pageName, params: { modal: "messages" } }
                    }
                  },
                  [_vm._v("Skriv besked")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7b71f4dc", { render: render, staticRenderFns: staticRenderFns })
  }
}