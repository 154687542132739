var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Udfyld ordredata", show: true, noScroll: false },
      on: { close: _vm.onClose }
    },
    [
      _vm.lead
        ? _c("company-lead-data", {
            attrs: { lead: _vm.lead },
            on: {
              updated: function(udpatedLead) {
                return _vm.$emit("input", udpatedLead)
              },
              close: _vm.onFinish
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f98dfdd2", { render: render, staticRenderFns: staticRenderFns })
  }
}