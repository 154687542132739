<template>
  <div class="msg-item">
    <div class="msg-item__block">
      <div class="msg-item__header">
        <div class="msg-item__header__content">
          <span class="msg-date" v-text="msg.created_at"></span>
          <h4 class="msg-topic" v-if="msg.subject" v-text="msg.subject"></h4>
          <h5 class="msg-name" v-text="msg.from"></h5>
        </div>
        <div class="msg-item__avatar">
          <img :src="msg.img" alt />
        </div>
      </div>
      <div class="msg-item__body">
        <div class="msg-text" v-if="!msg.render_html">
          <nl2br tag="p" :text="msg.message" />
        </div>
        <div class="msg-text" v-if="msg.render_html" v-html="msg.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nl2br from "vue-nl2br";
import Moment from "moment";

export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      }
    },
    part: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    msg() {
      let message = this.message;
      message.created_at = Moment(message.created_at).format("LLL");
      message.img = "/assets/img/client-icon.svg";

      if (!message.from_nem) {
        message.from = this.part.name;
      } else if (message.from_nem) {
        message.from = message.creator.fullname;
        message.img = message.creator.image;
      }

      return message;
    }
  },
  components: {
    nl2br
  }
};
</script>