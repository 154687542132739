<template>
    <div class="case-droppoint">
        <div class="case-droppoint__text">
            <slot>
                <p>Her kan du sikkert sende filer til din sag og sagsbehandler.</p>
                <p>Træk de filer ud du ønsker at dele ind i boksen nedenfor, eller tryk på 'Vælg filer' for at finde dem på din enhed.</p>
                <p>Herefter trykker du på uploadknappen for at sende dem til os. Vi får automatisk en notifikation, og går straks dine filer igennem.</p>
            </slot>
        </div>
        <div class="nem-dropzone" v-if="uploadUrl">
            <vue-dropzone 
            ref="dropzone" 
            id="dropzone" 
            :options="dropzoneOptions" 
            @vdropzone-queue-complete="finishUploading"
            @vdropzone-file-added="checkQueue"
            @vdropzone-files-added="checkQueue"
            @vdropzone-success="onSuccess"
            @vdropzone-error="onError"
            ></vue-dropzone>
        </div>
        <div class="case-droppoint__text" v-if="uploadUrl">
            <p v-if="succes && !error" class="case-droppoint__text__success">Alle dine filer blev uploaded med success</p>
            <p v-if="succes && error" class="case-droppoint__text__error">Der skete en fejl da nogle af dine filer blev uploaded</p>
            <p>Filer du uploader skal være af typen pdf, jpg, png eller gif - filen kan være helt op til 40 MB stor.</p>
            <button class="btn" @click="uploadFiles" :disabled="uploadingFiles || !queueSize" :class="{'loading': uploadingFiles}">Upload filer</button>
            <a href="https://www.nemadvokat.dk/datapolitik/" target="_blank">Vores datapolitik</a>
        </div>
    </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone';

export default {
    props: {
        uploadUrl: {type: String, default: null}
    },
    data() {
        return {
            uploadingFiles: false,
            queueSize: 0,
            error: false,
            succes: false
        }
    },
    watch: {
        queueSize() {
            if(this.uploadingFiles && this.queueSize <= 0) {
                this.uploadingFiles = false;
            }
        }
    },
    methods: {
        checkQueue() {
            return this.queueSize = this.$refs.dropzone.getAddedFiles().length +  this.$refs.dropzone.getQueuedFiles().length;
        },
        uploadFiles() {
            this.error = false;
            this.succes = false;

            if(this.checkQueue() > 0) {
                this.$refs.dropzone.processQueue();
                this.uploadingFiles = true;
            }
        },
        finishUploading() {
            this.uploadingFiles = false;
            if(this.checkQueue() <= 0) {
                this.succes = true;
            }
        },
        onSuccess(file) {
            this.checkQueue();
            setTimeout(() => {
                this.$refs.dropzone.removeFile(file);
            }, 3000);
        },
        onError(file, message) {
            this.checkQueue();
            file.previewElement.getElementsByClassName('dz-error-message')[0].innerHTML = 'Der skete en fejl under upload';
            this.error = true;
        }
    },
    computed: {
        dropzoneOptions() {
            let uploadIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.19 32.19"><path d="M16,23.7a.88.88,0,0,1-.69-.33L6.73,12.71a.89.89,0,0,1,.69-1.45h3.89V3.75a.89.89,0,0,1,.89-.89h7.6a.89.89,0,0,1,.89.89v7.51h3.88a.88.88,0,0,1,.8.51.87.87,0,0,1-.11.94L16.69,23.37A.88.88,0,0,1,16,23.7ZM7.66,12.26,16,22.63l8.34-10.37H20.57a.88.88,0,0,1-.88-.89V3.86H12.31v7.52a.88.88,0,0,1-.89.88Z"/><path d="M30.94,20.66a.5.5,0,0,0-.5.5V27c0,.06-.12.1-.29.1H1.84c-.16,0-.28,0-.28-.1V21.16a.51.51,0,0,0-.5-.5.5.5,0,0,0-.5.5V27a1.15,1.15,0,0,0,1.28,1.1H30.15A1.16,1.16,0,0,0,31.44,27V21.16A.51.51,0,0,0,30.94,20.66Z"/></svg>';
            return {
                url: this.uploadUrl,
                thumbnailWidth: 150,
                maxFilesize: 40,
                acceptedFiles: 'image/jpeg,image/png,image/gif,application/pdf',
                autoProcessQueue: false,
                addRemoveLinks: true,
                dictDefaultMessage: uploadIcon + '<br/>Vælg filer',
                dictFallbackMessage: 'Vælg filer',
                dictFileTooBig: 'Filen fylder {{filesize}}, men må max fylde {{maxFilesize}}',
                dictInvalidFileType: 'Filtypen understøttes ikke',
                dictResponseError: 'Der skete en fejl under filupload og filen blev ikke uploadet',
                dictRemoveFile: 'Fjern fil',
                dictCancelUpload: 'Annuller upload',
                dictUploadCanceled: 'Upload blev annulleret',
                dictCancelUploadConfirmation: 'Sikker på at du vil annullere upload?'
            };
        }
    },
    components: {
        VueDropzone
    }
}
</script>