var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-menu" },
    [
      _vm.partType == "customer"
        ? _c(
            "router-link",
            {
              staticClass: "case-menu__item case-menu__item--tasks",
              attrs: { to: { name: _vm.pageName, params: { modal: null } } }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "case-menu__item__icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 46 46"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M21,33.42,14.62,26l1.82-1.83L21,27.77l8.59-7.59L31.45,22Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M34.4,8.59H26.84a3.91,3.91,0,1,0-7.81,0H11.47A2.39,2.39,0,0,0,9,10.89V38.07a2.34,2.34,0,0,0,2.34,2.53H34.5a2.34,2.34,0,0,0,2.34-2.53V10.89A2.39,2.39,0,0,0,34.4,8.59Zm-11.47-2a2,2,0,1,1-1.9,2A2,2,0,0,1,22.93,6.6ZM34.32,38.07H11.55v-27h3.83V14a1,1,0,0,0,.95,1h13.2a1,1,0,0,0,1-1V11.11h3.83Z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "case-menu__item__text" }, [
                _vm._v("Overblik")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "case-menu__item",
          attrs: { to: { name: _vm.pageName, params: { modal: "messages" } } }
        },
        [
          _c(
            "svg",
            {
              staticClass: "case-menu__item__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 46 46"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M37.22,13.17H8.78a2,2,0,0,0-2,2V33.76a2,2,0,0,0,2,2H37.22a2,2,0,0,0,2-2V15.13A2,2,0,0,0,37.22,13.17ZM27.45,25.6a1.25,1.25,0,0,0,.14,1.76l7.19,5.91H11.22l7.2-5.92a1.25,1.25,0,0,0,.42-1.17,1.28,1.28,0,0,0-.82-.95,1.23,1.23,0,0,0-1.22.23L9.28,31.65V17.06l13,10.31a1.23,1.23,0,0,0,1.52,0l13-10.27V31.65l-7.51-6.19A1.26,1.26,0,0,0,27.45,25.6Zm-16.06-10H34.61L23,24.83Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "case-menu__item__text" }, [
            _vm._v("Beskeder " + _vm._s(_vm.messageCount))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "case-menu__item",
          attrs: { to: { name: _vm.pageName, params: { modal: "documents" } } }
        },
        [
          _c(
            "svg",
            {
              staticClass: "case-menu__item__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 46 46"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M21.91,17.82H15.22a1.28,1.28,0,1,1,0-2.56h6.69a1.28,1.28,0,1,1,0,2.56Z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M30.59,22.94H15.22a1.28,1.28,0,0,1,0-2.56H30.59a1.28,1.28,0,0,1,0,2.56Z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M30.59,28.06H15.22a1.28,1.28,0,0,1,0-2.56H30.59a1.28,1.28,0,1,1,0,2.56Z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M30.59,33.18H15.22a1.28,1.28,0,1,1,0-2.56H30.59a1.28,1.28,0,1,1,0,2.56Z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M36.28,13.8c-.79-1-5-5.14-6-6.24a4.06,4.06,0,0,0-3.12-1.12H12.66a3.84,3.84,0,0,0-3.84,3.84V35.72a3.84,3.84,0,0,0,3.84,3.84H33.34a3.84,3.84,0,0,0,3.84-3.84V16.52A4.23,4.23,0,0,0,36.28,13.8ZM34.62,25.5V37H11.38V9H28.1V9l6.52,6.66Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "case-menu__item__text" }, [
            _vm._v("Dokumenter")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "case-menu__item case-menu__item--horizontal",
          attrs: { to: { name: _vm.pageName, params: { modal: "economy" } } }
        },
        [
          _c(
            "svg",
            {
              staticClass: "case-menu__item__icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 202.95 202.95"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M101.47,9.48a92,92,0,1,0,92,92A92.1,92.1,0,0,0,101.47,9.48Zm0,173a81,81,0,1,1,81-81A81.09,81.09,0,0,1,101.47,182.47Z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M118.61,98.71,95.6,92a7.15,7.15,0,0,1,2-14h14.12a13.93,13.93,0,0,1,7.77,2.39l1.7,1.15,8.36-8.31,1-1.23.06-.16a2.63,2.63,0,0,0-.92-2.93,29.32,29.32,0,0,0-17.91-6.22h-.86V51.49l-.09-1L109.4,49H99.08L99,61.79v.87H97.62A22.49,22.49,0,0,0,81,70a22.21,22.21,0,0,0-5.78,17.2c.83,9.08,7.75,17,17.23,19.83l21.83,6.39a7.14,7.14,0,0,1-2,14H98.19A14,14,0,0,1,90.42,125l-1.7-1.15-8.35,8.31-1,1.24,0,.16a2.62,2.62,0,0,0,.91,2.92,29.32,29.32,0,0,0,17.91,6.22H99v13.56l11.84.09.07-12.84v-.84l.85,0c9.94-.19,18.66-6.18,21.71-14.91a22.3,22.3,0,0,0-1.2-17.73A22.6,22.6,0,0,0,118.61,98.71Z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "case-menu__item__text" }, [
            _vm._v("Økonomi")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-288ce94d", { render: render, staticRenderFns: staticRenderFns })
  }
}