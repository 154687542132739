<template>
  <modal :show="true" title="Ny besked" @close="closeMessage" class="msg-new">
    <div>
      <div class="tool-line">
        <div ref="toolbar">
          <span class="ql-formats">
            <select class="ql-color">
              <option selected="selected"></option>
              <option value="#e60000"></option>
              <option value="#ff9900"></option>
              <option value="#ffff00"></option>
              <option value="#008a00"></option>
              <option value="#0066cc"></option>
              <option value="#9933ff"></option>
              <option value="#ffffff"></option>
              <option value="#facccc"></option>
              <option value="#ffebcc"></option>
              <option value="#ffffcc"></option>
              <option value="#cce8cc"></option>
              <option value="#cce0f5"></option>
              <option value="#ebd6ff"></option>
              <option value="#bbbbbb"></option>
              <option value="#f06666"></option>
              <option value="#ffc266"></option>
              <option value="#ffff66"></option>
              <option value="#66b966"></option>
              <option value="#66a3e0"></option>
              <option value="#c285ff"></option>
              <option value="#888888"></option>
              <option value="#a10000"></option>
              <option value="#b26b00"></option>
              <option value="#b2b200"></option>
              <option value="#006100"></option>
              <option value="#0047b2"></option>
              <option value="#6b24b2"></option>
              <option value="#444444"></option>
              <option value="#5c0000"></option>
              <option value="#663d00"></option>
              <option value="#666600"></option>
              <option value="#003700"></option>
              <option value="#002966"></option>
              <option value="#3d1466"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="ordered"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-header">
              <option value="1"></option>
              <option value="2"></option>
              <option selected="selected"></option>
              <option value="6"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
        </div>
        <div class="tool-line__actions">
          <button @click="sendMessage" :disabled="!readyToSend || loading" :class="{'btn--loading':loading}" class="btn btn-primary">Send</button>
        </div>
      </div>
      <div class="subject-line">
        <input-field name="Emne" v-model="subject" ref="subject"></input-field>
      </div>
      <div class="text-line">
        <quill-editor
          v-if="editorOptions"
          v-model="message"
          :options="editorOptions"
          class="msg-text"
        ></quill-editor>
        <inline-msg-thread :messages="messages" :part="currentUser" :reverse="true"></inline-msg-thread>
      </div>
      <div style="display: none" ref="signatur">
        <br />
        <br />
        <p>Med venlig hilsen</p>
        <p>
          <b>{{ currentUser.name }}</b>
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import InputField from "./../input-field/input-field.vue";
import { quillEditor } from "vue-quill-editor";
import API from "../../services/datastore.js";
import InlineMsgThread from "./inline-msg-trhead.vue";
import './../../utils/quill-toolbar-setup.js';

export default {
  props: {
    order: { type: Object, default: null },
    currentUser: { type: Object, default: null },
    messages: { type: Array, default() {return [ ]} },
    partHash: {type: String, default: null}
  },
  data() {
    return {
      messageToParties: [],
      message: "",
      subject: "",
      editorOptions: null,
      loading: false,
      originalMessage: ""
    };
  },
  mounted() {
    this.messageToParties = this.toParties;
    this.$nextTick(() => {
      this.initEditor();
    });
  },
  methods: {
    initEditor() {
      if (!this.editorOptions) {
        this.editorOptions = {
          placeholder: "",
          modules: {
            toolbar: this.$refs.toolbar
          }
        };

        if (this.currentUser && this.currentUser.name) {
          this.message += this.$refs.signatur.innerHTML;
        }

        this.originalMessage = this.message;
      }
    },
    closeMessage() {
      if (this.message != this.originalMessage || this.subject) {
        let c = confirm(
          "Du er i gang med at skrive en besked! Er du sikker på at du vil lukke?"
        );
        if (!c) {
          return;
        }
      }

      this.$emit("close");
    },
    sendMessage() {
      if (this.loading || !this.readyToSend || !this.order.main_order_hash)
        return;

      let form = new FormData();
      form.append("message", this.message);
      form.append("subject", this.subject);

      this.loading = true;
      API.addMessage(this.order.main_order_hash, form, this.partHash)
        .then(res => {
          this.loading = false;
          if (res.data.status !== 'error') {
            this.$emit("messagesent", res);
          } else {
            confirm("Der skete en fejl da bekseden skulle sendes!");
          }
        })
        .catch(error => {
          this.loading = false;
          console.error(error);
          confirm("Der skete en fejl da bekseden skulle sendes!");
        });
    }
  },
  computed: {
    readyToSend() {
      return this.message != this.originalMessage;
    }
  },
  components: {
    Modal,
    InputField,
    quillEditor,
    InlineMsgThread
  }
};
</script>