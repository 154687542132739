<template>
  <modal title="Din kommentar" :show="true" :noScroll="false" @close="onClose" ref="modal">
    <div class="lead-info" v-if="comment">
      <p v-text="comment.comment"></p>
      <p v-if="!comment">Din kommentar kunne ikke findes</p>
    </div>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";

export default {
  methods: {
    onClose() {
      this.$router.push({
        name: "lead-view",
        params: { view: null, slide: null },
      });
    },
  },
  computed: {
    comment() {
      if (this.$route.query.commentid > -1) {
        return this.$store.state.sendLeadComments[this.$route.query.commentid];
      }

      return null;
    },
  },
  components: {
    Modal,
  },
};
</script>