<template>
  <slide
    :title="title"
    class="order-flow__wrapper--6"
    @next="submit()"
    :validated="isValid"
  >
    <template slot="content">
      <div class="order-tiles order-tiles--selection">
        <div
          v-for="(rule, key) in incorporationRules"
          :key="key"
          @click="intSelectedCoporationRule = key"
          class="order-tiles__item"
          :class="[
            'order-tiles__item--0',
            { 'order-tiles__item--selected': intSelectedCoporationRule === key },
          ]"
        >
          <span class="order-tiles__item__heading" v-text="rule"></span>
        </div>
      </div>
    </template>
  </slide>
</template>

<script>
import Slide from "./slide.vue";

export default {
  props: {
    companyName: { type: String, default: "virksomhed" },
    selectedIncormationsRule: {type: Number, defualt: -1}
  },
  data() {
    return {
      intSelectedCoporationRule: -1,
      incorporationRules: [
        'Selskabet tegnes af en direktør',
        'Selskabet tegnes af direktionen i forening'
      ]
    };
  },
  mounted() {
    this.intSelectedCoporationRule = this.selectedIncormationsRule;
  },
  watch: {
    selectedIncormationsRule() {
      this.intSelectedCoporationRule = this.selectedIncormationsRule;
    },
  },
  methods: {
    submit() {
      this.$emit(
        "submit",
        Object.assign(
          {},
          {
            incorporationRule: this.incorporationRules[this.intSelectedCoporationRule],
            selectedRule: this.intSelectedCoporationRule
          }
        )
      );
    },
  },
  computed: {
    title() {
      return "Hvad er tegningsreglen for <br/>" + this.companyName;
    },
    isValid() {
      return this.intSelectedCoporationRule > -1 ? true : false;
    },
  },
  components: {
    Slide
  },
};
</script>