var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        title: "Aftalebrev",
        showBackBtn: true,
        show: true,
        showActions: true
      },
      on: { back: _vm.onBackInContractModal, close: _vm.onCloseContractModal }
    },
    [
      _vm.letterData
        ? _c("contract", {
            attrs: {
              letterData: _vm.letterData,
              "is-old": !_vm.customerLetter.is_current,
              "customer-department": _vm.customer
                ? _vm.customer.customer_department
                : null
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.letterData
        ? _c(
            "div",
            {
              staticClass: "agreement-letter-actions actions-full-width",
              attrs: { slot: "actions" },
              slot: "actions"
            },
            [
              _vm.letterData && _vm.letterData.version === "2.0"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-lg btn--print",
                      on: { click: _vm.print }
                    },
                    [_vm._v("\n      Print\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.customerLetter.approved
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn--positive btn--aprrove",
                      class: { loading: _vm.approveLoading },
                      attrs: {
                        disabled:
                          _vm.customerLetter.approved ||
                          !_vm.customerLetter.is_current ||
                          _vm.approveLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.approveLetter(_vm.customerLetter)
                        }
                      }
                    },
                    [_vm._v("\n      Godkend\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.customerLetter.approved
                ? _c("p", [
                    _vm._v("\n      Du har godkendt dette aftalebrev\n      "),
                    _vm.customerLetter.approved_at
                      ? _c("span", [
                          _vm._v(
                            "d.\n        " +
                              _vm._s(
                                _vm
                                  .moment(_vm.customerLetter.approved_at)
                                  .format("DD-MM-YYYY HH:mm")
                              )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(".\n    ")
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36d9ef6f", { render: render, staticRenderFns: staticRenderFns })
  }
}