var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--8",
      attrs: {
        title: "Er der andre produkter du vil høre mere om?",
        validated: false
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("div", { staticClass: "label-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.intExtraOptions.ownerdeal,
                  expression: "intExtraOptions.ownerdeal"
                }
              ],
              attrs: { type: "checkbox", id: "check-1" },
              domProps: {
                checked: Array.isArray(_vm.intExtraOptions.ownerdeal)
                  ? _vm._i(_vm.intExtraOptions.ownerdeal, null) > -1
                  : _vm.intExtraOptions.ownerdeal
              },
              on: {
                change: function($event) {
                  var $$a = _vm.intExtraOptions.ownerdeal,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "ownerdeal",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "ownerdeal",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.intExtraOptions, "ownerdeal", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "label", attrs: { for: "check-1" } }, [
              _c("span", { staticClass: "circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "check" }),
              _vm._v(" "),
              _c("span", { staticClass: "box" }),
              _vm._v("Ejeraftale\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.intExtraOptions.ceocontract,
                  expression: "intExtraOptions.ceocontract"
                }
              ],
              attrs: { type: "checkbox", id: "check-2" },
              domProps: {
                checked: Array.isArray(_vm.intExtraOptions.ceocontract)
                  ? _vm._i(_vm.intExtraOptions.ceocontract, null) > -1
                  : _vm.intExtraOptions.ceocontract
              },
              on: {
                change: function($event) {
                  var $$a = _vm.intExtraOptions.ceocontract,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "ceocontract",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "ceocontract",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.intExtraOptions, "ceocontract", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "label", attrs: { for: "check-2" } }, [
              _c("span", { staticClass: "circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "check" }),
              _vm._v(" "),
              _c("span", { staticClass: "box" }),
              _vm._v("Direktørkontrakt\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.intExtraOptions.trademark,
                  expression: "intExtraOptions.trademark"
                }
              ],
              attrs: { type: "checkbox", id: "check-3" },
              domProps: {
                checked: Array.isArray(_vm.intExtraOptions.trademark)
                  ? _vm._i(_vm.intExtraOptions.trademark, null) > -1
                  : _vm.intExtraOptions.trademark
              },
              on: {
                change: function($event) {
                  var $$a = _vm.intExtraOptions.trademark,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "trademark",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "trademark",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.intExtraOptions, "trademark", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "label", attrs: { for: "check-3" } }, [
              _c("span", { staticClass: "circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "check" }),
              _vm._v(" "),
              _c("span", { staticClass: "box" }),
              _vm._v("Varemærkeregistrering\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.intExtraOptions.debt,
                  expression: "intExtraOptions.debt"
                }
              ],
              attrs: { type: "checkbox", id: "check-4" },
              domProps: {
                checked: Array.isArray(_vm.intExtraOptions.debt)
                  ? _vm._i(_vm.intExtraOptions.debt, null) > -1
                  : _vm.intExtraOptions.debt
              },
              on: {
                change: function($event) {
                  var $$a = _vm.intExtraOptions.debt,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.intExtraOptions, "debt", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.intExtraOptions,
                          "debt",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.intExtraOptions, "debt", $$c)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "label", attrs: { for: "check-4" } }, [
              _c("span", { staticClass: "circle" }),
              _vm._v(" "),
              _c("span", { staticClass: "check" }),
              _vm._v(" "),
              _c("span", { staticClass: "box" }),
              _vm._v("Gældsbrev på anpartshaverlån\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("input-type", {
            attrs: {
              name: "Andet vi skal drøfte når vi ringer?",
              value: _vm.intExtraOptions.extra,
              "input-type": "textarea"
            },
            on: {
              input: function(val) {
                _vm.$set(_vm.intExtraOptions, "extra", val)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "actions" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--show",
            class: { "btn--loading": _vm.loading },
            attrs: { disabled: _vm.loading },
            on: {
              click: function($event) {
                return _vm.submit()
              }
            }
          },
          [_vm._v("Afslut")]
        ),
        _vm._v(" "),
        _vm.error
          ? _c("p", { staticClass: "error" }, [
              _vm._v(
                "Der skete en fejl under bestillingen! Tjek at du har internet og prøv igen..."
              )
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ff0d9bb6", { render: render, staticRenderFns: staticRenderFns })
  }
}