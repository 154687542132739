var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq-item", class: { "faq-item--open": _vm.unfold } },
    [
      _c(
        "div",
        {
          staticClass: "faq-item__header",
          on: {
            click: function($event) {
              _vm.unfold = !_vm.unfold
            }
          }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(_vm.title) } }),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "faq-item__body" },
        _vm._l(_vm.text, function(paragraph, key) {
          return _c("p", {
            key: key,
            domProps: { innerHTML: _vm._s(paragraph) }
          })
        }),
        0
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "faq-item__arrow" }, [
      _c("div", { staticClass: "faq-item__arrow__icon" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77d2d30c", { render: render, staticRenderFns: staticRenderFns })
  }
}