var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showContractModal
    ? _c(
        "modal",
        {
          staticClass: "contract-table-modal",
          class: { loading: _vm.loading },
          attrs: { title: "Aftalebreve", show: true },
          on: { close: _vm.onCloseModal }
        },
        [
          _vm.error
            ? _c("p", {
                staticClass: "error",
                domProps: { textContent: _vm._s(_vm.error) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "documents-table" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("td", [_vm._v("Dato")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("Status")])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.letterList, function(letter, key) {
                  return _c(
                    "tr",
                    {
                      key: key,
                      on: {
                        click: function($event) {
                          return _vm.showCustomerLetter(key)
                        }
                      }
                    },
                    [
                      _c("td", {
                        domProps: { textContent: _vm._s(letter.created_at) }
                      }),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "td-flex" }, [
                          _c("div", { staticClass: "td-flex__header" }, [
                            _c("span", {
                              domProps: { textContent: _vm._s(letter.status) }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "td-flex__body" }, [
                            key == 0 && !letter.approved
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn--positive",
                                    class: {
                                      "btn--loading": _vm.approveLoading
                                    },
                                    attrs: { disabled: _vm.approveLoading },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.approveLetter(key)
                                      }
                                    }
                                  },
                                  [_vm._v("Godkend")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn--secondary",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.showCustomerLetter(key)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("Vis aftalebrev")])]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]
      )
    : _c("contract-modal", {
        attrs: {
          "customer-letter": _vm.showContractModal,
          order: _vm.order,
          customer: _vm.customer
        },
        on: {
          back: function($event) {
            _vm.showContractModal = null
          },
          close: _vm.onCloseModal,
          approvedletter: _vm.approveShownLetter
        }
      })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2e287cb0", { render: render, staticRenderFns: staticRenderFns })
  }
}