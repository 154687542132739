<template>
  <div class="welcome-view">
    <h3 class="welcome-view__title">
      Velkommen,
      <span v-text="name"></span>
    </h3>
    <div class="welcome-view__text" v-if="partType == 'customer'">
      <p>Vi har nu bekræftet din e-mail og telefon som sikrer enheder for den videre kommunikation i vores sagsbehandling.</p>
      <p>Når der er nyt i din sag, sender vi dig en e-mail med et link til <b>NEMBLIK</b>. Dette link kan kun åbnes ved at anvende den SMS-kode som vi samtidig sender til din mobil.</p>
      <p>Vi skifter koderne hele tiden, du skal bare sørge for at have adgang til din e-mail og telefon.</p>
    </div>

    <div class="welcome-view__text" v-if="partType == 'part'">
      <p>Vi har nu bekræftet din e-mail som sikrer enheder for den videre kommunikation i vores sagsbehandling.</p>
    </div>
    <button @click="goToCases" class="btn">Gå til sager</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: ""
    };
  },
  methods: {
    goToCases() {
      let name = this.partType == "part" ? "part-profile" : "profile";

      this.$router.push({
        name
      });
    }
  },
  mounted() {
    this.$store
      .dispatch("getpart", {
        hash: this.$route.params.hash,
        loadCases: false
      })
      .then(customer => {
        this.name = customer.name;
      });
  },
  computed: {
    partType() {
      return this.$store.state.parttype;
    }
  }
};
</script>   