var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kyc-wrapper", class: { loading: _vm.loading } },
    [
      _c("h2", { domProps: { textContent: _vm._s(_vm.translations.title) } }),
      _vm._v(" "),
      _c("p", { domProps: { textContent: _vm._s(_vm.translations.text) } }),
      _vm._v(" "),
      _c("p", { staticClass: "kyc-wrapper__small-text" }, [
        _vm._v("\n    " + _vm._s(_vm.translations.esignatur_info) + "\n    "),
        _vm.validationMethod.sign_url
          ? _c("a", {
              staticClass: "kyc-wrapper__link kyc-wrapper__link--inline",
              attrs: { href: _vm.validationMethod.sign_url },
              domProps: { textContent: _vm._s(_vm.translations.esignatur_btn) }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bf142fbe", { render: render, staticRenderFns: staticRenderFns })
  }
}