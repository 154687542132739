var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "base-modal-nemblik--half",
      attrs: { title: _vm.title, show: true, showActions: true },
      on: { close: _vm.onClose }
    },
    [
      _vm.slide === "select-product"
        ? _c("div", { staticClass: "new-case-product" }, [
            _c("div", { staticClass: "new-case-product__header" }, [
              _c("img", {
                staticClass: "case-item__icon",
                attrs: { src: "/assets/img/icons/" + _vm.product.icon, alt: "" }
              }),
              _vm._v(" "),
              _c("h3", {
                staticClass: "case-item__title",
                domProps: { textContent: _vm._s(_vm.product.title) }
              })
            ]),
            _vm._v(" "),
            _vm.product.description
              ? _c("p", {
                  domProps: { textContent: _vm._s(_vm.product.description) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "new-case-product__body" },
              [
                _vm.bookMeeting
                  ? _c(
                      "div",
                      { staticClass: "book-meeting" },
                      [
                        _c("date-time-input", {
                          model: {
                            value: _vm.contactTime,
                            callback: function($$v) {
                              _vm.contactTime = $$v
                            },
                            expression: "contactTime"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("input-field", {
                  attrs: {
                    name: "Bemærkninger til sag",
                    "input-type": "textarea",
                    value: _vm.comment
                  },
                  on: {
                    input: function(inp) {
                      return (_vm.comment = inp)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.error
                  ? _c("p", { staticClass: "error" }, [
                      _vm._v(
                        "\n        Der skete desværre en fejl da sagen skulle oprettes! Prøv igen eller\n        kontakt NEMADVOKAT direkte...\n      "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "finished"
        ? _c("div", { staticClass: "new-case__select-finished" }, [
            _c("p", [_vm._v("Sagen er nu modtaget.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      Den vil blive oprettet indenfor 24 timer hvorefter der vil kunne\n      uploades dokumenter til sagen\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "actions-full-width",
          attrs: { slot: "actions" },
          slot: "actions"
        },
        [
          _vm.slide === "select-product"
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--positive btn--full-width",
                    class: { loading: _vm.loading },
                    attrs: { disabled: !_vm.valid || _vm.loading },
                    on: { click: _vm.orderCase }
                  },
                  [_vm._v("\n        Send\n      ")]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.slide === "finished"
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--positive",
                    on: { click: _vm.onClose }
                  },
                  [_vm._v("Ok")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0de5b7cf", { render: render, staticRenderFns: staticRenderFns })
  }
}