<template>
  <div class="kyc-view" :class="{ loading }">
    <kyc-modal
      v-if="exists && !finished"
      :validation="validation"
      :showCloseBtn="false"
      @close="finished = true"
    ></kyc-modal>
    <div
      class="kyc-wrapper kyc-wrapper--status"
      v-if="(!loading && exists === false) || finished"
    >
      <p
        v-if="!loading && exists === false"
        class="error"
        v-text="translations.not_exists"
      ></p>
      <p v-if="finished" class="success" v-text="translations.success"></p>
    </div>
  </div>
</template>

<script>
import KycModal from "./../kyc-flow/kyc-modal.vue";
import Datastore from "./../../services/datastore";
import DA_LANG from "./lang/da";
import EN_LANG from "./lang/en";

export default {
  data() {
    return {
      loading: true,
      exists: null,
      finished: false,
      validation: null,
    };
  },
  mounted() {
    this.checkKYCExists();
  },
  methods: {
    checkKYCExists() {
      this.loading = true;
      let reset = this.$route.query.kyccompleted ? null : 1;
      return Datastore.getKYCValidation(this.kycuid, { reset })
        .then(({ data }) => {
          let finished =
            data.validations.filter(({ ready }) => ready).length == 2;

          this.loading = false;
          if (finished && reset) {
            this.exists = false;
          } else {
            this.exists = true;
            this.validation = data;
          }
        })
        .catch((res) => {
          this.exists = false;
          this.loading = false;
        });
    },
  },
  computed: {
    kycuid() {
      return this.$route.params.uid;
    },
    translations() {
      switch (this.language) {
        case "en":
          return EN_LANG.kyc_view;
        default:
          return DA_LANG.kyc_view;
      }
    },
  },
  components: {
    KycModal,
  },
};
</script>