<template>
  <modal
    title="Identifikation"
    :show="true"
    @close="onClose"
    :showClose="showCloseBtn"
    class="kyc-modal"
    :showBackBtn="showBackBtn"
    @back="onGoBack"
    :class="{ loading }"
  >
    <order-flow-progress
      :percentComplete="percentComplete"
    ></order-flow-progress>
    <div class="language-selector" slot="header_right">
      <button
        class="language-selector__btn"
        :class="{ 'language-selector__btn--active': language == 'da' }"
        @click="language = 'da'"
      >
        DK
      </button>
      <button
        class="language-selector__btn"
        :class="{ 'language-selector__btn--active': language == 'en' }"
        @click="language = 'en'"
      >
        UK
      </button>
    </div>

    <intro-text
      :ready="uid ? true : false"
      v-if="slide == 'intro'"
      :translations="translations.intro_text"
      @submit="submitIntro"
    ></intro-text>
    <id-selector
      v-if="slide == 'id-selector'"
      :translations="translations.id_selector"
      :validation-types="validationTypes"
      :selected-types="selectedValidationMethods"
      @submit="submitValidationMethod"
      :uid="uid"
      :order-hash="orderHash"
    ></id-selector>
    <esignatur
      v-if="slide == 'esignatur'"
      :translations="translations.esignatur"
      :validation-method="lastValidationMethod"
    ></esignatur>
    <upload-file
      v-if="slide == 'upload-file'"
      :translations="translations.upload_file"
      :uid="uid"
      :validation-method="lastValidationMethod"
      :validation-types="validationTypes"
      @submit="submitUpload"
    ></upload-file>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import IntroText from "./intro-text.vue";
import IdSelector from "./id-selector.vue";
import UploadFile from "./upload-file.vue";
import Esignatur from "./kyc-esignatur.vue";
import OrderFlowProgress from "./../order-flow-progress.vue";
import Datastore from "./../../services/datastore";

import DataStore from "./../../services/datastore";
import DA_LANG from "./lang/da";
import EN_LANG from "./lang/en";

export default {
  props: {
    validation: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    "validation.validations"() {
      this.checkExistingValidations();
    },
  },
  data() {
    return {
      slide: "intro",
      history: [],
      loading: false,
      selectedValidationMethods: [],
      validationTypes: [],
      language: "da",
      finished: false,
    };
  },
  mounted() {
    this.checkExistingValidations();
    this.loadValidationTypes();
  },
  methods: {
    checkExistingValidations() {
      if (this.validation) {
        this.selectedValidationMethods = this.validation.validations.map(
          (validation_id) => {
            return {
              validation_id,
            };
          }
        );

        if (this.selectedValidationMethods.length >= 2) {
          this.finished = true;
          this.slide = "id-selector";
        }
      }
    },
    removeEsignaturAndGotoIDSelector() {
      if (this.lastValidationMethod) {
        this.loading = true;
        this.removeValidationMethod(this.lastValidationMethod)
          .then(() => {
            this.loading = false;
            this.gotoSlide("id-selector", false);
          })
          .catch((err) => {
            console.error(error);
            confirm(this.translations.esignatur.remove_error);
            this.loading = false;
          });
      }
    },
    onGoBack() {
      let slide = this.history.pop();
      if (
        (slide == "id-selector" || slide == "id-info-text") &&
        this.lastValidationMethod
      ) {
        this.loading = true;
        this.removeValidationMethod(this.lastValidationMethod)
          .then(() => {
            this.loading = false;
            this.gotoSlide(slide, false);
          })
          .catch((err) => {
            console.error(error);
            confirm(this.translations.id_selector.remove_error);
            this.loading = false;
          });
      } else {
        this.gotoSlide(slide, false);
      }
    },
    removeValidationMethod(validationMethod) {
      if (!validationMethod || !this.uid) {
        return;
      }

      return Datastore.KYCRemoveValidationMethod(this.uid, {
        type: validationMethod.validation_id.type,
      }).then(() => {
        this.loading = true;
        let idx = this.selectedValidationMethods.indexOf(validationMethod);
        if (idx > -1) {
          this.selectedValidationMethods.splice(idx, 1);
        }
      });
    },
    loadValidationTypes() {
      return DataStore.getKYCValidateTypes().then(({ data }) => {
        this.validationTypes = data;
      });
    },
    submitIntro() {
      this.gotoSlide("id-selector");
    },
    submitValidationMethod({ validationMethod }) {
      if (!validationMethod) {
        this.$emit("close");
      } else {
        this.selectedValidationMethods.push(validationMethod);
        if (validationMethod.validation_id.type > 1) {
          this.gotoSlide("upload-file");
        } else {
          this.gotoSlide("esignatur");
        }
      }
    },
    submitUpload() {
      if (this.selectedValidationMethods.length >= 2) {
        this.finished = true;
      }

      this.gotoSlide("id-selector");
    },
    gotoSlide(slide, addToHistory = true) {
      if (addToHistory) {
        this.history.push(this.slide);
      }
      this.slide = slide;
    },
    onClose() {
      this.$emit("close");
    },
  },
  computed: {
    showBackBtn() {
      return this.history.length ? true : false && !this.finished;
    },
    percentComplete() {
      if (this.finished) {
        return 100;
      }

      return ((this.history.length + 1) / 6) * 100;
    },
    lastValidationMethod() {
      return this.selectedValidationMethods[
        this.selectedValidationMethods.length - 1
      ];
    },
    translations() {
      switch (this.language) {
        case "en":
          return EN_LANG;
        default:
          return DA_LANG;
      }
    },
    uid() {
      return this.validation && this.validation.uid_hash
        ? this.validation.uid_hash
        : null;
    },
    orderHash() {
      return this.order.main_order_hash ? this.order.main_order_hash : null;
    },
  },
  components: {
    Modal,
    IntroText,
    IdSelector,
    UploadFile,
    OrderFlowProgress,
    Esignatur,
  },
};
</script>