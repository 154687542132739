var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-profile-view", class: { loading: _vm.loading } },
    [
      _c("div", { staticClass: "lead-section" }, [
        _c(
          "div",
          { staticClass: "nem-wrapper" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.order.caseid) + " Dokumenter")]),
            _vm._v(" "),
            _c("droppoint", { attrs: { "upload-url": _vm.upoloadUrl } }, [
              _c("p", [
                _vm._v("Her kan du sikkert sende filer til NEMADVOKAT.")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Træk de filer ud du ønsker at dele ind i boksen nedenfor, eller tryk på 'Vælg filer' for at finde dem på din enhed."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Herefter trykker du på uploadknappen for at sende dem til os. Vi får automatisk en notifikation, og går straks dine filer igennem."
                )
              ])
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-229ba56b", { render: render, staticRenderFns: staticRenderFns })
  }
}