<template>
    <div class="base-modal-nemblik" v-if="show">
        <div class="modal-box modal-box--text">
            <div class="modal-box__header">
                <div class="modal-box__header__left">
                     <button class="modal-box__header__back-btn" v-if="showBackBtn" @click="$emit('back')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 162.86">
                            <path d="M168,72.43H30.73L87.79,15.36A9,9,0,1,0,75.07,2.64L2.64,75.06c-.21.21-.41.44-.6.67a3.15,3.15,0,0,0-.22.29l-.31.41c-.08.13-.15.26-.23.38l-.22.38,0,0c-.06.12-.11.24-.16.35s-.13.26-.18.4-.1.26-.15.4-.11.28-.15.43-.07.27-.11.41-.08.29-.11.44-.05.32-.07.48L0,80.54a10.58,10.58,0,0,0,0,1.78l.06.38c0,.16,0,.33.07.49s.07.29.11.43.07.29.11.42.1.28.15.42a3.52,3.52,0,0,0,.15.42c.05.13.11.25.17.38s.13.28.2.41l.21.36c.08.13.16.27.25.4a4.61,4.61,0,0,0,.28.38,3.81,3.81,0,0,0,.24.32q.29.34.6.66h0l72.43,72.43A9,9,0,1,0,87.79,147.5L30.73,90.43H168a9,9,0,0,0,0-18Z"/>
                        </svg>
                    </button>
                    <h2 class="modal-box__header__heading" v-text="title"></h2>

                    <div class="modal-box-dropdown" v-if="dropDownOptions" :class="{'modal-box-dropdown--open': dropdownOpen}" ref="dropdownbox">
                        <button class="modal-box-dropdown__btn" @click="dropdownOpen = true">
                            <span v-if="dropDownOptions[selectedIndex]" v-text="dropDownOptions[selectedIndex]"></span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.43 242.86"><line x1="121.43" y1="121.43" x2="9" y2="233.86"/><line x1="9" y1="9" x2="121.43" y2="121.43"/></svg>
                        </button>
                        <ul class="modal-box-dropdown__list">
                            <li v-for="(text, key) in dropDownOptions">
                                <a href="javascript:void(0)" @click="selectItem(key)" v-text="text"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-box__header__right">
                    <slot name="header_right"></slot>
                    <button class="modal-box__header__btn" @click="close" v-if="showClose">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" class="modal-box__header__btn__icon"><line x1="160.64" y1="44" x2="39.65" y2="164.98" /><line x1="39.65" y1="44" x2="160.64" y2="164.98" /></svg>
                    </button>
                </div>
            </div>
            <div class="modal-box__content" ref="contentarea">
                <slot></slot>
            </div>
            <div class="modal-box__actions" v-if="showActions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: '',
        show: false,
        dropDownOptions: {type: Array, default() {return null}},
        selectedIndex: {type: Number, default: -1},
        showActions: {type: Boolean, default: false},
        noScroll: {type: Boolean, default: true},
        showClose: {type: Boolean, default: true},
        showBackBtn: {type: Boolean, default: false}
    },
    data() {
        return {
            dropdownOpen: false,
            listenerSetup: false
        }
    },
    mounted() {
        if(this.show) {
            this.setNoScroll();
        }
    },
    beforeDestroy() {
        if(this.noScroll) {
            let app = document.getElementById('app');
            app.classList.remove('noScroll');
        }
    },
    watch: {
        dropdownOpen() {
            if(this.dropdownOpen) {
                this.setupClickListener();
            }
        },
        show() {
            if(this.show) {
                this.dropdownOpen = false;
            }

            this.setNoScroll();
        }
    },
    methods: {
        scrollToTop() {
            this.$refs.contentarea.scrollTop = 0;
        },
        setupClickListener() {
            if(this.dropDownOptions && this.$refs.dropdownbox && !this.listenerSetup) {
                this.listenerSetup = true;
                window.document.addEventListener('click', (evt) => {
                    if(this.$refs.dropdownbox && !this.$refs.dropdownbox.contains(evt.target)) {
                        this.dropdownOpen = false;
                    }
                });
            }
        },
        selectItem(key) {
            this.$emit('selectitem', key);
            this.dropdownOpen = false;
        },
        setNoScroll() {
            if(!this.noScroll)
                return;

            let app = document.getElementById('app');
            if(this.show) {
                app.classList.add('noScroll')
            } else {
                app.classList.remove('noScroll');
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

