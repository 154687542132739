export default {
	intro_text: {
		title: 'Vi skal kende din identitet',
		text: [
			'Vi skal kende vores kunder og da vi ikke mødes med dig, er vi forpligtet til at indentificere dig på anden vis.',
			'Det tager ikke mange minutter før vi kan komme tilbage til sagen.'
		],
		submit: 'Næste',
		faq: {
			title: 'Lovgivningen bag',
			text: [
				'Som advokater er vi underlagt hvidvaskloven, og er således forpligtet til at indhente legitimation på alle vores klienter.',
				"Vi må ikke påbegynde arbejdet, førend vi har modtaget det efterspurgte legitimation. <a href='https://dda.nu/saadan-sender-du-legitimation/' target='_blank' class='kyc-wrapper__link kyc-wrapper__link--inline'>Læs mere her.</a>"
			]
		}
	},
	pep_person: {
		title: 'Politisk eksponeret',
		yes_option: 'Ja,',
		yes_option_text: 'Jeg er, eller inden for de seneste 12 måneder har været, politisk',
		no_option: 'Nej,',
		no_option_text: 'Jeg er, eller inden for de seneste 12 måneder har været, politisk',
		submit: 'Næste',
		faq: {
			title: 'Hvad vil det sige at være PEP?',
			text: [
				'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut reprehenderit quia quasi. In sunt ad veritatis deserunt quia iusto consequuntur dicta, quo dolorum! Suscipit corrupti, repellendus doloremque esse rem soluta!'
			]
		}
	},
	id_info_text: {
		title: 'Fremsendelse af ID',
		text: 'Nu skal du have dit NemID klar sammen med enten dit pas, kørekort eller sundhedskort.',
		esignatur_error:
			'Der skete en fejl og signeringsvinduet kunne ikke vises! Prøv igen eller gå videdere uden NemID',
		esignatur_btn: 'Åben signeringsvindue',
		no_esignatur: 'Har ikke NemID',
		submit: 'Næste'
	},
	esignatur: {
		title: 'Viderestiller til E-signatur...',
		text: 'Hav venligst NemID klar',
		esignatur_info: 'Hvis du ikke vidrestilles kan du åbne signeringen her ',
		esignatur_btn: 'her'
	},
	id_selector: {
		title_finish: 'Tak, vi er i mål',
		title_first_source: 'Vælg første informationskilde',
		title_secound_source: 'Vælg anden informationskilde',
		remove_error: 'Der skete en fejl! Prøv igen og tjek at du har internet forbindelse!',
		finish_btn: 'Afslut',
		validationNames: {
			1: 'NemID/MitID',
			2: 'Pas',
			3: 'Kørekort',
			4: 'Sundhedskort'
		},
		validationDescriptions: {
			1: 'Viderestiller til e-signatur for signering',
			3: 'Digtialt kørekort kan ikke anvendes',
			4: 'CPR-nr. skal fremgå'
		}
	},
	upload_file: {
		title: 'Upload',
		validationNames: {
			1: 'NemID/MitID',
			2: 'Pas',
			3: 'Kørekort',
			4: 'Sundhedskort'
		},
		upload_error: 'Der skete en fejl under upload! Prøv at uploade filen igen.',
		size_error: 'Filen fylder {{filesize}}, men må max fylde {{maxFilesize}}',
		type_error: 'Filtypen understøttes ikke',
		size_info: 'Bemærk at størrelsen på filen maks må være 5 MB og skal være af typen png, jpg eller PDF',
		upload_btn: 'Upload',
		select_files: 'Vælg filer',
		remove_file: 'Fjern fil',
		cancel_upload: 'Annuller upload',
		cancel_upload_confirm: 'Sikker på at du vil annullere upload?',
		upload_cancelled: 'Upload blev annulleret'
	},
	kyc_view: {
		not_exists: 'Validering ikke tilgændelig! Tjek linket i din mail og at du ikke har valideret dig',
		success: 'Din validering er nu modtaget!'
	}
};
