var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--2",
      attrs: {
        title: _vm.title,
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: { next: _vm.submit }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "number-tiles",
            class: { "number-tiles--selection": _vm.numberSelected > 0 }
          },
          _vm._l(4, function(n) {
            return _c(
              "div",
              {
                key: n,
                staticClass: "number-tiles__item",
                class: [
                  "number-tiles__item--" + n,
                  { "number-tiles__item--selected": _vm.numberSelected === n }
                ],
                on: {
                  click: function($event) {
                    return _vm.selectNumber(n)
                  }
                }
              },
              [_c("span", { domProps: { textContent: _vm._s(n) } })]
            )
          }),
          0
        ),
        _vm._v(" "),
        !_vm.showMoreOwners
          ? _c(
              "button",
              {
                staticClass: "order-flow__expand-btn",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    _vm.showMoreOwners = true
                  }
                }
              },
              [_vm._v("Flere ejere?")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "order-flow__content__expansion",
            class: {
              "order-flow__content__expansion--open": _vm.showMoreOwners
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "number-tiles",
                class: { "number-tiles--selection": _vm.numberSelected > 0 }
              },
              _vm._l(4, function(n) {
                return _c(
                  "div",
                  {
                    key: n,
                    staticClass: "number-tiles__item",
                    class: [
                      "number-tiles__item--" + n,
                      {
                        "number-tiles__item--selected":
                          _vm.numberSelected === n + 4
                      }
                    ],
                    on: {
                      click: function($event) {
                        _vm.selectNumber(n + 4)
                      }
                    }
                  },
                  [_c("span", { domProps: { textContent: _vm._s(n + 4) } })]
                )
              }),
              0
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f5662566", { render: render, staticRenderFns: staticRenderFns })
  }
}