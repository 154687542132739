<template>
  <div class="nem-message-page" :class="{'loading': loading}">
      <div class="nem-wrapper">
        <order-nav @loading="(onNavLoad) => loading = onNavLoad"></order-nav> 
        <case-page v-if="showCasePage"></case-page>
        <new-case :customer="customer" v-if="showNewCase" @finish="closeNewCase"></new-case>
        <div class="no-order-open" v-if="noOrderOpen">
            <img src="/assets/img/icons/no-order-arrow.svg" alt="">
            <p>Åben en sag på listen</p>
        </div>
      </div>
  </div>
</template>

<script>
import OrderNav from './order-nav.vue';
import CasePage from './../case/case-page.vue';
import NewCase from '../new-case/new-case.vue';

export default {
    data() {
        return {
            loading: true 
        }
    },
    methods: {
        closeNewCase() {
            this.$router.push({name: 'profile'});
        }
    },
    computed: {
        noOrderOpen() {
            return !this.showNewCase && !this.showCasePage;
        },
        showNewCase() {
            return (this.$route.name == 'profile-new-case' || this.$route.name == 'part-new-case') ? true : false;
        },
        showCasePage() {
            return this.$route.params.orderhash ? true : false;
        },
        customer() {
            return this.$store.state.part;
        }
    },
    components: {
        CasePage,
        NewCase,
        OrderNav
    }
}
</script>
</template>