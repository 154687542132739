var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "receipt-modal receipt-modal--personal-info",
      attrs: {
        title: "Kundeinformation",
        show: true,
        showActions: true,
        noScroll: false
      },
      on: { close: _vm.onClose }
    },
    [
      _c("div", { staticClass: "order-receipt" }, [
        _c("div", { staticClass: "company-content" }, [
          _c("div", { staticClass: "confirm-order-block" }, [
            _c("div", { staticClass: "confirm-order-block__content" }, [
              _c("p", [
                _c("span", [_vm._v("Type:")]),
                _vm._v(" " + _vm._s(_vm.personType))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("Navn:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_name))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("Adresse:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_address))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("Postnr.:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_postal))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("By:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_town))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("E-mail:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_email))
              ]),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v("Tlf.:")]),
                _vm._v(" " + _vm._s(_vm.lead.customer_phone))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--show",
          attrs: { slot: "actions" },
          on: { click: _vm.onClose },
          slot: "actions"
        },
        [_vm._v("Luk")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1faaf66b", { render: render, staticRenderFns: staticRenderFns })
  }
}