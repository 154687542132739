var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-documents" },
    [
      _c(
        "div",
        { staticClass: "documents-table", class: { loading: _vm.loading } },
        [
          !_vm.loading
            ? _c("table-data", {
                attrs: { headers: _vm.headers, "table-data": _vm.fileList },
                on: { click: _vm.openFile }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.orderActive
        ? _c("div", { staticClass: "case-documents_text" }, [
            _c("p", [
              _vm._v(
                "\n      Vil du uploade et dokument til os skal du benytte\n      "
              ),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      _vm.showUpload = true
                    }
                  }
                },
                [_vm._v("upload")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpload
        ? _c(
            "modal",
            {
              attrs: { show: true, title: "Upload ny fil", "no-scroll": true },
              on: {
                close: function($event) {
                  _vm.showUpload = false
                }
              }
            },
            [
              _vm.showUpload
                ? _c("case-droppoint", { attrs: { uploadUrl: _vm.upoloadUrl } })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-349f163a", { render: render, staticRenderFns: staticRenderFns })
  }
}