var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--5",
      attrs: { title: _vm.title, validated: _vm.isValid },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { staticClass: "order-tiles order-tiles--selection" },
          _vm._l(_vm.ceoInfo, function(ceo, key) {
            return _c(
              "div",
              {
                key: key,
                staticClass: "order-tiles__item",
                class: [
                  "order-tiles__item--" + key,
                  { "order-tiles__item--selected": ceo.selected }
                ],
                on: {
                  click: function($event) {
                    return _vm.toogleOwner(key)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "order-tiles__item__heading",
                  domProps: { textContent: _vm._s(ceo.name) }
                })
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "order-flow__expand-btn order-flow__expand-btn--with-dropdown",
            class: { "order-flow__expand-btn--open": _vm.showOtherDirector },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                _vm.showOtherDirector = !_vm.showOtherDirector
              }
            }
          },
          [
            _c("span", [_vm._v("Opret direktør")]),
            _c(
              "svg",
              {
                staticClass: "drop-down-arrow",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 236.86 124.43"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M235.1,1.76a6,6,0,0,0-8.48,0L118.43,109.95,10.24,1.76a6,6,0,0,0-8.49,8.49L114.19,122.67a6,6,0,0,0,8.48,0L235.1,10.24A6,6,0,0,0,235.1,1.76Z"
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "order-flow__content__expansion order-flow__content__expansion--auto-height",
            class: {
              "order-flow__content__expansion--open": _vm.showOtherDirector
            }
          },
          [
            _c("input-field", {
              attrs: { name: "Navn", value: _vm.newCeo.name },
              on: {
                input: function(val) {
                  _vm.newCeo.name = val
                },
                enter: _vm.addNewCeo
              }
            }),
            _vm._v(" "),
            _c("input-field", {
              attrs: {
                name: "E-mail",
                value: _vm.newCeo.email,
                "input-type": "email"
              },
              on: {
                input: function(val) {
                  _vm.newCeo.email = val
                },
                enter: _vm.addNewCeo
              }
            }),
            _vm._v(" "),
            _c("input-field", {
              attrs: {
                name: "Cpr",
                value: _vm.newCeo.secret_identifier,
                "input-type": "tel",
                mask: "999999-9999"
              },
              on: {
                input: function(val) {
                  _vm.newCeo.secret_identifier = val.substr(0, 11)
                },
                enter: _vm.addNewCeo
              }
            }),
            _vm._v(" "),
            _c("input-field", {
              attrs: {
                "input-type": "text",
                name: "Adresse",
                value: _vm.newCeo.address
              },
              on: {
                input: function(val) {
                  return (_vm.newCeo.address = val)
                },
                enter: _vm.addNewCeo
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-4", attrs: { "form-group": "small" } },
                [
                  _c("input-field", {
                    attrs: {
                      "input-type": "tel",
                      name: "Post nr.",
                      mask: "9999",
                      value: _vm.newCeo.postal.toString()
                    },
                    on: {
                      input: function(val) {
                        return (_vm.newCeo.postal = parseInt(val.substr(0, 4)))
                      },
                      stopTyping: _vm.getTownByZip,
                      enter: _vm.addNewCeo
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8", attrs: { "form-group": "small" } },
                [
                  _c("input-field", {
                    attrs: { name: "By", value: _vm.newCeo.town },
                    on: {
                      input: function(val) {
                        return (_vm.newCeo.town = val)
                      },
                      enter: _vm.addNewCeo
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("input-field", {
              attrs: { name: "Land", value: _vm.newCeo.country },
              on: {
                input: function(val) {
                  return (_vm.newCeo.country = val)
                },
                enter: _vm.addNewCeo
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-flow__actions order-flow__actions--ceo" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--show",
                    attrs: { disabled: !_vm.validCeo },
                    on: { click: _vm.addNewCeo }
                  },
                  [_vm._v("Opret")]
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cb9c6d5c", { render: render, staticRenderFns: staticRenderFns })
  }
}