<template>
    <modal v-if="!showContractModal" title="Aftalebreve" @close="onCloseModal" :show="true" :class="{ loading: loading }" class="contract-table-modal">
        <p v-if="error" v-text="error" class="error"></p>
        <div class="documents-table">
            <table>
                <thead>
                    <tr>
                        <td>Dato</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(letter, key) in letterList" :key="key" @click="showCustomerLetter(key)">
                        <td v-text="letter.created_at"></td>
                        <td>
                            <div class="td-flex">
                                <div class="td-flex__header">
                                    <span v-text="letter.status"></span>
                                </div>
                                <div class="td-flex__body">
                                    <button class="btn btn--positive" :class="{ 'btn--loading': approveLoading }"
                                        v-if="key == 0 && !letter.approved" :disabled="approveLoading"
                                        @click.stop="approveLetter(key)">Godkend</button>
                                    <a href="javascript:void(0)" @click.stop="showCustomerLetter(key)" class="btn btn--secondary">
                                        <span>Vis aftalebrev</span>
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </modal>
    <contract-modal v-else @back="showContractModal = null" @close="onCloseModal" :customer-letter="showContractModal"
        :order="order" :customer="customer" @approvedletter="approveShownLetter"></contract-modal>
</template>

<script>
import Modal from "../modal/base-modal.vue";
import DataStore from "../../services/datastore";
import Moment from "moment";
import ContractModal from "./contract-modal.vue";

export default {
    props: {
        order: {
            type: Object,
            default: () => { }
        },
        customer: {
            type: Object,
            default: () => { }
        },
    },
    mounted() {
        this.loadCustomerLetters();
    },
    data() {
        return {
            loading: false,
            approveLoading: false,
            error: '',
            customerLetters: [],
            showContractModal: null,
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
        },
        loadCustomerLetters() {
            if (this.loading || !this.order || !this.order.main_order_hash) return;

            this.error = "";
            this.loading = true;
            this.customerLetters = [];

            DataStore.getAftaleBrev(this.order.main_order_hash)
                .then((data) => {
                    this.loading = false;
                    if (data.data.status === "error") {
                        this.error = "Listen over aftalebreve kunne ikke hentes...";
                    } else {
                        this.customerLetters = data.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error =
                        "Listen over aftalebreve kunne ikke hentes... Prøv igen...";
                });
        },
        showCustomerLetter(key) {
            const letter = this.customerLetters[key];
            if (letter.letter_data && letter.letter_data.letter_file_id) {
                this.openCustomerLetterFile(letter);
            } else {
                const is_current = (key == 0) ? true : false;
                this.showContractModal = Object.assign({}, letter, { is_current });
            }
        },
        openCustomerLetterFile(custoemrLetter) {
            if (this.loading) return;
            this.loading = true;

            let win = null;
            if (!window.navigator || !window.navigator.msSaveOrOpenBlob) {
                win = window.open("");
                win.document.write("Indlæser...");
                win.document.title = "Indlæser...";
            }

            return DataStore.getAftaleBrevFile(this.order.main_order_hash, custoemrLetter.id)
                .then((blob) => {
                    this.loading = false;
                    const created_at_date =  Moment(custoemrLetter.created_at).format('ll');
                    const fileName = `Aftalebrev - ${created_at_date}.pdf`;
                    this.loading = false;
                    if (!win) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        win.location = URL.createObjectURL(blob);
                        setTimeout(() => {
                            win.document.title = fileName;
                        }, 150);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    alert("Der skete en fejl da filen skulle vises!");
            });
        },
        approveShownLetter() {
            if(!this.showContractModal) {
                return;
            }

            const letter = this.customerLetters.find(({id}) => id == this.showContractModal.id);
            if(letter) {
                const idx = this.customerLetters.indexOf(letter);
                this.$set(this.customerLetters, idx, Object.assign({}, letter, {
                        approved: true,
                        approved_at: Moment()
                }));
            }
            messageBus.$emit('approvedletter');
        },
        approveLetter(key) {
            if (this.approveLoading) return;

            this.approveLoading = true;
            const letter = this.customerLetters[key];
            DataStore.approveAftaleBrev(this.order.main_order_hash, letter.id)
                .then((res) => {
                    this.approveLoading = false;
                    this.$set(this.customerLetters, key, Object.assign({}, letter, {
                        approved: true,
                        approved_at: Moment()
                    }));
                    messageBus.$emit('approvedletter');
                })
                .catch((err) => {
                    console.error(err);
                    this.approveLoading = false;
                });
        },
    },
    computed: {
        letterList() {
            return this.customerLetters.map((letter, key) => {
                let status = 'Udløbet';
                if (key == 0) {
                    status = (letter.approved_at) ? 'Godkendt d. ' + Moment(letter.approved_at).format('ll') : 'Afventer din godkendelse';
                }

                return {
                    id: letter.id,
                    created_at: Moment(letter.created_at).format('ll'),
                    status,
                    approved: (letter.approved_at) ? true : false
                }
            })
        }
    },
    components: {
        Modal,
        ContractModal
    },
}
</script>