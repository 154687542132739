var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--3",
      attrs: {
        title: "Vælg selskabstype for holdingselskab",
        validated: _vm.isValid,
        "overwrite-save-btn": true
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "order-tiles",
            class: { "order-tiles--selection": _vm.isValid }
          },
          [
            _c(
              "div",
              {
                staticClass: "order-tiles__item order-tiles__item--2",
                class: {
                  "order-tiles__item--selected": _vm.intCompanyType === "ivs"
                },
                on: {
                  click: function($event) {
                    return _vm.selectType("ivs")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/assets/img/icons/icon-company-2.svg",
                    alt: "Ikon af selskab"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "order-tiles__item__heading" }, [
                  _vm._v("IVS")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "order-tiles__item order-tiles__item--3",
                class: {
                  "order-tiles__item--selected": _vm.intCompanyType === "aps"
                },
                on: {
                  click: function($event) {
                    return _vm.selectType("aps")
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/assets/img/icons/icon-company-2.svg",
                    alt: "Ikon af selskab"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "order-tiles__item__heading" }, [
                  _vm._v("APS")
                ])
              ]
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0751a3f6", { render: render, staticRenderFns: staticRenderFns })
  }
}