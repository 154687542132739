var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--6",
      attrs: {
        title: _vm.title,
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "order-tiles order-tiles--selection" }, [
          _c(
            "div",
            {
              staticClass: "order-tiles__item",
              class: [
                "order-tiles__item--0",
                {
                  "order-tiles__item--selected":
                    _vm.intSelectedDateRange === "year"
                }
              ],
              on: {
                click: function($event) {
                  _vm.intSelectedDateRange = "year"
                }
              }
            },
            [
              _c("span", { staticClass: "order-tiles__item__heading" }, [
                _vm._v("Kalenderåret")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "order-tiles__item",
              class: [
                "order-tiles__item--1",
                {
                  "order-tiles__item--selected":
                    _vm.intSelectedDateRange === "custom"
                }
              ],
              on: {
                click: function($event) {
                  _vm.intSelectedDateRange = "custom"
                }
              }
            },
            [
              _c("span", { staticClass: "order-tiles__item__heading" }, [
                _vm._v("Forskudt")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "order-flow__content__expansion",
            class: {
              "order-flow__content__expansion--open":
                _vm.intSelectedDateRange == "custom"
            }
          },
          [
            _c(
              "div",
              { staticClass: "input-fields" },
              [
                _c("date-picker", {
                  attrs: {
                    name: "Fra",
                    "is-ready": true,
                    "default-date": _vm.startDate,
                    "select-past": true,
                    "select-weekends": true,
                    disbaleAfterDate: _vm.intFinancialYear.end
                      ? _vm.intFinancialYear.end
                      : "",
                    format: "DD/MM"
                  },
                  on: { pickdate: _vm.setFromDate }
                }),
                _vm._v(" "),
                _c("date-picker", {
                  attrs: {
                    name: "Til",
                    "is-ready": true,
                    "default-date": _vm.endDate,
                    "select-past": true,
                    disbaleBeforeDate: _vm.intFinancialYear.start
                      ? _vm.intFinancialYear.start
                      : "",
                    "select-weekends": true,
                    format: "DD/MM"
                  },
                  on: { pickdate: _vm.setToDate }
                })
              ],
              1
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c4000ae8", { render: render, staticRenderFns: staticRenderFns })
  }
}