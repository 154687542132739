var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      ref: "modal",
      attrs: { title: "Udfyld ordredata", show: true },
      on: { close: _vm.onClose }
    },
    [
      _vm.lead
        ? _c("div", { staticClass: "lead-info" }, [
            _c(
              "div",
              { staticClass: "lead-products" },
              _vm._l(_vm.products, function(product) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    staticClass: "lead-product",
                    class: {
                      "lead-product--verified": _vm.copmanyGuideCompleted(
                        product
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.fillOrderData(product)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "lead-product__content" }, [
                      _c("h4", [
                        _vm._v(
                          "\n            " +
                            _vm._s(product.product.title) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.showCompanyGuide(product)
                        ? _c(
                            "button",
                            {
                              staticClass: "btn",
                              class: {
                                "btn--positive": !_vm.copmanyGuideCompleted(
                                  product
                                ),
                                "btn--filled": _vm.copmanyGuideCompleted(
                                  product
                                )
                              }
                            },
                            [
                              !_vm.copmanyGuideCompleted(product)
                                ? _c("span", [_vm._v("Udfyld")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.copmanyGuideCompleted(product)
                                ? _c("span", [_vm._v("Se kvittering")])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "/assets/img/icons/icon-check.svg",
                        alt: ""
                      }
                    })
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d22b452", { render: render, staticRenderFns: staticRenderFns })
  }
}