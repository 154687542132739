var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nem-message-page", class: { loading: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "nem-wrapper" },
        [
          _c("order-nav", {
            on: {
              loading: function(onNavLoad) {
                return (_vm.loading = onNavLoad)
              }
            }
          }),
          _vm._v(" "),
          _vm.showCasePage ? _c("case-page") : _vm._e(),
          _vm._v(" "),
          _vm.showNewCase
            ? _c("new-case", {
                attrs: { customer: _vm.customer },
                on: { finish: _vm.closeNewCase }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.noOrderOpen
            ? _c("div", { staticClass: "no-order-open" }, [
                _c("img", {
                  attrs: {
                    src: "/assets/img/icons/no-order-arrow.svg",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("Åben en sag på listen")])
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-225271af", { render: render, staticRenderFns: staticRenderFns })
  }
}