<template>
  <div class="kyc-wrapper">
    <h2 v-text="translations.title"></h2>
    <p v-for="(text, key) in translations.text" :key="key" v-text="text"></p>
    <button class="btn" :class="{loading: !ready}" :disabled="!ready" @click="$emit('submit')" v-text="translations.submit"></button>
    <faq-item :title="translations.faq.title" :text="translations.faq.text"></faq-item>
  </div>
</template>

<script>
import FaqItem from "./../faq-item.vue";

export default {
  props: {
    translations: {
      type: Object,
      defualt() {
        return {};
      },
    },
    ready: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FaqItem,
  },
};
</script>