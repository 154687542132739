<template>
    <div class="agreement-letter">
        <div class="agreement-letter__body">
            <p>Vi har nu beregnet den samlede tinglysningsafgift på din sag.</p>
            <p>Tinglysningsafgiften udgør <span v-price="order.registration_fee"></span> Beløbet bedes indbetalt på vores klientkonto i Nykredit med reg.nr. 8117 og konto nr. 3822677.</p>
            <p>Navngiv venligst overførslen {{ order.caseid }}TL</p>
            <p>Vi kan ikke gennemføre tinglsyningen før afgiften er modtaget.</p>
            <p>Se sagens fulde aftalebrev/ordrebekræftigelse <a href="javascript:void(0)" @click="$emit('showletter')">her</a></p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    order: {type: Object, default() { return { }}}
  }
}
</script>
