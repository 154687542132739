var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--6",
      attrs: {
        title: _vm.title,
        orderHelp: _vm.helpText,
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "order-tiles order-tiles--selection" }, [
          _c(
            "div",
            {
              staticClass: "order-tiles__item",
              class: [
                "order-tiles__item--0",
                { "order-tiles__item--selected": _vm.customCapital === false }
              ],
              on: {
                click: function($event) {
                  _vm.customCapital = false
                }
              }
            },
            [
              _c("span", {
                directives: [
                  {
                    name: "price",
                    rawName: "v-price",
                    value: _vm.standardCapital,
                    expression: "standardCapital"
                  }
                ],
                staticClass: "order-tiles__item__heading"
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "order-tiles__item",
              class: [
                "order-tiles__item--1",
                {
                  "order-tiles__item--selected": _vm.customCapital === true
                }
              ],
              on: {
                click: function($event) {
                  _vm.customCapital = true
                }
              }
            },
            [
              _c("span", { staticClass: "order-tiles__item__heading" }, [
                _vm._v("Anden")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "order-flow__content__expansion",
            class: {
              "order-flow__content__expansion--open": _vm.customCapital
            }
          },
          [
            _c("div", { staticClass: "input-fields" }, [
              _c(
                "div",
                { staticClass: "label-input" },
                [
                  _c(
                    "money",
                    _vm._b(
                      {
                        model: {
                          value: _vm.intCapital,
                          callback: function($$v) {
                            _vm.intCapital = $$v
                          },
                          expression: "intCapital"
                        }
                      },
                      "money",
                      _vm.money,
                      false
                    )
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7607083d", { render: render, staticRenderFns: staticRenderFns })
  }
}