<template>
  <modal title="Book telefonmøde" :show="true" :noScroll="false" @close="onClose">
    <div class="book-phone-meeting">
      <div class="input-fields">
        <date-picker
          name="Dato"
          @pickdate="updateDate"
          :is-ready="true"
          :default-date="startDate"
          format="DD-MM-YYYY"
        ></date-picker>
      </div>

      <div class="input-fields">
        <div class="drop-down">
          <label for="hour">Kl.</label>
          <select id="hour" v-model="setTimeHour" class="drop-down__select">
            <option v-for="hour in hours" :value="hour.key">
              {{ hour.value }}
            </option>
          </select>
        </div>
        <p class="error" v-show="error">
          Der skete desværre en fejl og det valgte tidspunkt blev ikke sat
        </p>
      </div>

      <button
        @click="selelectTime"
        class="btn btn--positive"
        :disabled="loading || lead.contact_state == 4"
        :class="{ loading: loading }"
      >
        Bestil
      </button>
    </div>
  </modal>
</template>

<script type="text/javascript">
import DatePicker from "../datepicker/datepicker.vue";
import MomentObj from "moment";
import DataStore from "../../services/datastore";
import Modal from "./../modal/base-modal.vue";
import { DefaultCallHours, DefaultStartDate} from './call-date-util';

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      setDate: "",
      setTimeHour: "09",
      setTimeMinute: "00",
      error: false,
      loading: false,
    };
  },
  mounted() {
    this.setDate = this.startDate;
    this.setTimeHour = this.hours[0].key;
  },
  methods: {
    onClose() {
      this.$router.push({
        name: "lead-view",
        params: {
          view: null,
        },
      });
    },
    updateDate(val) {
      this.setDate = val;
      this.setTimeHour = this.hours[0].key;
    },
    selelectTime() {
      let date = this.setDate + " " + this.setTimeHour + ":00";
      let time = MomentObj(date, "DD-MM-YYYY HH:mm");
      this.loading = true;
      this.error = false;

      return DataStore.updateLead(this.lead.hash, { update_contact_time: time, set_contact_method: 'callme' })
        .then((res) => {
          this.$emit("input", Object.assign({}, this.lead, res.data));
          this.loading = false;
          this.$nextTick(() => {
            this.$router.push({ name: "lead-view", params: { view: null } });
          });
        })
        .catch((err) => {
          console.error(err);
          this.error = true;
        });
    },
  },
  computed: {
    contactTime() {
      if (this.lead && this.lead.contact_time) {
        let dateStr = MomentObj(this.lead.contact_time).format("DD-MM-YYYY");
        dateStr += " ml. " + MomentObj(this.lead.contact_time).format("HH");
        dateStr +=
          " - " +
          MomentObj(this.lead.contact_time).add(2, "hours").format("HH");
        return dateStr;
      }

      return "";
    },
    startDate() {
      return DefaultStartDate();
    },
    hours() {
      return DefaultCallHours(this.setDate);    
    }
  },
  components: {
    "date-picker": DatePicker,
    Modal,
  },
};
</script>