var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--7",
      attrs: {
        title: "Skal vi hjælpe med andet?",
        subTitle: "Alle priser er ekskl. moms",
        validated: _vm.isValid
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "label-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.intOtherStuff.registerTax,
                expression: "intOtherStuff.registerTax"
              }
            ],
            attrs: { type: "checkbox", id: "check-1" },
            domProps: {
              checked: Array.isArray(_vm.intOtherStuff.registerTax)
                ? _vm._i(_vm.intOtherStuff.registerTax, null) > -1
                : _vm.intOtherStuff.registerTax
            },
            on: {
              change: function($event) {
                var $$a = _vm.intOtherStuff.registerTax,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerTax",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerTax",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.intOtherStuff, "registerTax", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "check-1" } }, [
            _c("span", { staticClass: "circle" }),
            _vm._v(" "),
            _c("span", { staticClass: "check" }),
            _vm._v(" "),
            _c("span", { staticClass: "box" }),
            _vm._v("Momsregistrering (kr. 495,00)\n                    ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.intOtherStuff.registerAsCeo,
                expression: "intOtherStuff.registerAsCeo"
              }
            ],
            attrs: { type: "checkbox", id: "check-2" },
            domProps: {
              checked: Array.isArray(_vm.intOtherStuff.registerAsCeo)
                ? _vm._i(_vm.intOtherStuff.registerAsCeo, null) > -1
                : _vm.intOtherStuff.registerAsCeo
            },
            on: {
              change: function($event) {
                var $$a = _vm.intOtherStuff.registerAsCeo,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerAsCeo",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerAsCeo",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.intOtherStuff, "registerAsCeo", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "check-2" } }, [
            _c("span", { staticClass: "circle" }),
            _vm._v(" "),
            _c("span", { staticClass: "check" }),
            _vm._v(" "),
            _c("span", { staticClass: "box" }),
            _vm._v(
              "Arbejdsgiverregistrering (kr. 195,00)\n                    "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.intOtherStuff.registerImportExport,
                expression: "intOtherStuff.registerImportExport"
              }
            ],
            attrs: { type: "checkbox", id: "check-3" },
            domProps: {
              checked: Array.isArray(_vm.intOtherStuff.registerImportExport)
                ? _vm._i(_vm.intOtherStuff.registerImportExport, null) > -1
                : _vm.intOtherStuff.registerImportExport
            },
            on: {
              change: function($event) {
                var $$a = _vm.intOtherStuff.registerImportExport,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerImportExport",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.intOtherStuff,
                        "registerImportExport",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.intOtherStuff, "registerImportExport", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "check-3" } }, [
            _c("span", { staticClass: "circle" }),
            _vm._v(" "),
            _c("span", { staticClass: "check" }),
            _vm._v(" "),
            _c("span", { staticClass: "box" }),
            _vm._v(
              "Import/eksport registrering (kr. 195,00)\n                    "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total-price" }, [
          _c("p", [
            _vm._v("I alt: "),
            _c("span", {
              directives: [
                {
                  name: "price",
                  rawName: "v-price",
                  value: _vm.otherStuffPrice,
                  expression: "otherStuffPrice"
                }
              ]
            })
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-53e195cc", { render: render, staticRenderFns: staticRenderFns })
  }
}