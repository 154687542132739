<template>
    <slide :title="title" class="order-flow__wrapper--5" :validated="isValid" @next="submit()">
        <template slot="content">
                    <div class="order-tiles order-tiles--selection">
                        <div class="order-tiles__item" :class="['order-tiles__item--' + key, {'order-tiles__item--selected': ceo.selected}]" v-for="(ceo, key) in ceoInfo" :key="key" @click="toogleOwner(key)">
                            <span class="order-tiles__item__heading" v-text="ceo.name"></span>
                        </div>
                    </div>
                    <button href="#" class="order-flow__expand-btn order-flow__expand-btn--with-dropdown" :class="{'order-flow__expand-btn--open': showOtherDirector}" @click="showOtherDirector = !showOtherDirector"><span>Opret direktør</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.86 124.43" class="drop-down-arrow"><path d="M235.1,1.76a6,6,0,0,0-8.48,0L118.43,109.95,10.24,1.76a6,6,0,0,0-8.49,8.49L114.19,122.67a6,6,0,0,0,8.48,0L235.1,10.24A6,6,0,0,0,235.1,1.76Z"/></svg></button>
                    <div class="order-flow__content__expansion order-flow__content__expansion--auto-height" :class="{'order-flow__content__expansion--open': showOtherDirector}">
                        <input-field name="Navn" :value="newCeo.name" @input="(val) => {newCeo.name = val}" @enter="addNewCeo" />
                        <input-field name="E-mail" :value="newCeo.email" @input="(val) => {newCeo.email = val}" input-type="email" @enter="addNewCeo" />
                        <input-field name="Cpr" :value="newCeo.secret_identifier" @input="(val) => {newCeo.secret_identifier = val.substr(0, 11)}" input-type="tel" mask="999999-9999" @enter="addNewCeo"/>
                        <input-field input-type="text" name="Adresse" :value="newCeo.address" @input="(val) => newCeo.address = val" @enter="addNewCeo"></input-field>
                        <div class="row">
                            <div form-group="small" class="col-sm-4">
                                <input-field input-type="tel" name="Post nr." mask="9999" :value="newCeo.postal.toString()" @input="(val) => newCeo.postal = parseInt(val.substr(0,4))" @stopTyping="getTownByZip" @enter="addNewCeo"></input-field>
                            </div>
                            <div form-group="small" class="col-sm-8">
                                <input-field name="By" :value="newCeo.town" @input="(val) => newCeo.town = val" @enter="addNewCeo"></input-field>
                            </div>
                        </div>
                        <input-field name="Land" :value="newCeo.country" @input="(val) => newCeo.country = val" @enter="addNewCeo"></input-field>
                        <div class="order-flow__actions order-flow__actions--ceo">
                            <button class="btn btn--show" :disabled="!validCeo" @click="addNewCeo">Opret</button>
                        </div>
                    </div>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';
import InputField from './../../input-field/input-field.vue';
import DataStore from './../../../services/datastore';

export default {
  props: {
      holdingCompanies: {type: Array, defualt() {return [ ]}},
      owners: {type: Array, default() {return {}}},
      companyName: {type: String, default: 'virksomhed'},
      ceoData: {type: Array, default() {return [ ]}}
  },
  data() {
      return {
          newCeo: {
            name: '',
            email: '',
            secret_identifier: '',
            address: '',
            postal: '',
            country: '',
            town: '',
          },
          showOtherDirector: false,
          ceoInfo: []
      }
  },
  mounted() {
      if(this.ceoData && this.ceoData.length) {
          this.setCeoDataFromProp();
      }

      this.initDirectores();
  },
  watch: {
      ceoData() {
        if(this.ceoData && this.ceoData.length) {
            this.setCeoDataFromProp();
        }

        this.initDirectores();
      },
      showOtherDirector() {
          if(!this.showOtherDirector) {
            this.resetNewCeo();
          }
      }
  },
  methods: {
      setCeoDataFromProp() {
            let ceoData = this.ceoData.map(item => Object.assign({}, item));
            this.ceoInfo = ceoData;
      },
      initDirectores() {
          if(this.ceoInfo.length <= 0) {
              this.ceoInfo = this.existingOwnerList.map((owner, id) => {
                  return {
                      id,
                        name: owner.info.name,
                        email: owner.info.email,
                        phone: owner.info.phone,
                        secret_identifier: owner.info.secret_identifier,
                        address: owner.info.address,
                        postal: owner.info.postal,
                        country: owner.info.country,
                        town: owner.info.town
                  }
              })

              for(let i in this.holdingCompanies) {
                  let ceos = this.holdingCompanies[i].ceo.filter((ceo) => ceo.selected).map((ceo) => {
                      ceo = Object.assign({}, ceo);
                      ceo.selected = false;
                      return ceo;
                  });

                  this.ceoInfo = this.ceoInfo.concat(ceos);
              }

              if(this.ceoInfo.length <= 0) {
                  this.showOtherDirector = true;
              }
          }
      },
      resetNewCeo() {
          this.newCeo = {
            name: '',
            email: '',
            secret_identifier: '',
            address: '',
            postal: '',
            country: '',
            town: '',
          }
      },
      addNewCeo() {
          if(!this.validCeo) {
              return;
          }

          let ceo = Object.assign({}, this.newCeo);
          ceo.selected = true;
          this.ceoInfo.push(ceo);
          this.showOtherDirector = false;
      },
      toogleOwner(key) {
          let ceo = this.ceoInfo[key];
          ceo.selected = !ceo.selected;
          this.$set(this.ceoInfo, key, ceo);
      },
      submit() {
        this.$emit('submit', this.ceoInfo.map((item) => Object.assign({}, item)));
      },
      getTownByZip(value) {
        if(value && value.length === 4 && parseInt(value)) {
            DataStore.getCityFromZipcode(value).then((res) => {
                if(res.data[0]) {
                    this.$set(this.newCeo, 'town', res.data[0].navn);
                }
            });
        }
      }
  },
  computed: {
    existingOwnerList() {
        return this.owners.filter((owner) => (owner.type === 'person'));
    },
    selectedCeos() {
        return (this.ceoInfo.length) ? this.ceoInfo.filter(item => item.selected) : [ ];
    },
    isValid() {
        return (this.selectedCeos.length && !this.showOtherDirector) ? true : false;
    },
    validCeo() {
        return (this.newCeo &&this.newCeo.name) ? true : false;
    },
    title() {
        return 'Vælg én eller flere direktører i <br/>' + this.companyName;
    }
  },
  components: {
    'slide': Slide,
    InputField
  }
}
</script>

