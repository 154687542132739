<template>
  <div class="related-products">
    <h2 v-if="relatedProducts.length" class="related-products__heading">
      Relateret produkter
    </h2>
    <div class="lead-products" v-if="relatedProducts.length">
      <div
        class="lead-product"
        v-for="product in relatedProducts"
        :key="product.id"
        @click="addRelatedProduct(product)"
      >
        <div class="lead-product__content">
          <h4>
            {{ product.product.title }}
            <span
              v-if="getProductPrice(product)"
              v-dkkprice="getProductPrice(product)"
            ></span>
          </h4>
          <button
            class="btn"
            :disabled="relatedProductLoading.indexOf(product.id) > -1"
            :class="{ loading: relatedProductLoading.indexOf(product.id) > -1 }"
          >
            <img src="/assets/img/icons/icon-check.svg" alt="" />
            <span>Tiføj</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataStore from "./../../services/datastore";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      relatedProductLoading: [],
    };
  },
  methods: {
    getProductPrice(product) {
      if (product.product && product.product.static_product) {
        return product.product.static_product.price_excl_vat * 1.25;
      }

      return 0;
    },
    addRelatedProduct(relatedProduct) {
      if (this.relatedProductLoading.indexOf(relatedProduct.id) > -1) return;

      this.relatedProductLoading.push(relatedProduct.id);
      let idx = this.relatedProductLoading.indexOf(relatedProduct.id);
      return DataStore.addRelatedProductToLead(this.lead.hash, {
        related_product_id: relatedProduct.id,
      })
        .then(({ data }) => {
          if (idx > -1) {
            this.relatedProductLoading.splice(idx, 1);
          }

          this.$emit("input", Object.assign({}, this.lead, data));
        })
        .catch((err) => {
          console.error(err);
          if (idx > -1) {
            this.relatedProductLoading.splice(idx, 1);
          }
        });
    },
  },
  computed: {
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    relatedProducts() {
      return this.lead && this.lead.campaign_id
        ? this.lead.campaign.products.filter((relatedProduct) => {
            if (!relatedProduct.related) {
              return false;
            }

            if (
              this.products.filter(
                (product) => relatedProduct.product_id == product.product_id
              ).length > 0
            ) {
              return false;
            }

            return true;
          })
        : [];
    },
  },
};
</script>