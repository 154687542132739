<template>
  <modal title="Udfyld ordredata" :show="true" @close="onClose" ref="modal">
    <div class="lead-info" v-if="lead">
      <div class="lead-products">
        <div
          class="lead-product"
          :class="{ 'lead-product--verified': copmanyGuideCompleted(product) }"
          v-for="product in products"
          :key="product.id"
          @click="fillOrderData(product)"
        >
          <div class="lead-product__content">
            <h4>
              {{ product.product.title }}
            </h4>
            <button
              class="btn"
              :class="{
                'btn--positive': !copmanyGuideCompleted(product),
                'btn--filled': copmanyGuideCompleted(product),
              }"
              v-if="showCompanyGuide(product)"
            >
              <span v-if="!copmanyGuideCompleted(product)">Udfyld</span>
              <span v-if="copmanyGuideCompleted(product)">Se kvittering</span>
            </button>
          </div>
          <img src="/assets/img/icons/icon-check.svg" alt="" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./../modal/base-modal.vue";
import { HasCaseDataToFill } from "./casedata-util";

export default {
  props: {
    lead: { type: Object, default: null },
  },
  data() {
    return {
      commentView: { name: "lead-view", params: { view: "kommentar" } },
      bookView: { name: "lead-view", params: { view: "book-telefon-mode" } },
    };
  },
  created() {
    if (this.products.length == 1) {
      this.fillOrderData(this.products[0]);
    }
  },
  methods: {
    fillOrderData(leadProduct) {
      if (!this.showCompanyGuide(leadProduct)) {
        return;
      }

      if (this.copmanyGuideCompleted(leadProduct)) {
        this.showInvoice(leadProduct);
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: { view: "company" },
        query: { productid: leadProduct.id },
      });
    },
    showInvoice(leadProduct) {
      this.$router.push({
        name: "lead-view",
        params: { view: "invoice" },
        query: { productid: leadProduct.id },
      });
    },
    showCompanyGuide(leadProduct) {
      return HasCaseDataToFill(leadProduct);
    },
    copmanyGuideCompleted(leadProduct) {
      return leadProduct.company_data_filled ? true : false;
    },
    onClose() {
      this.$router.push({ name: "lead-view", view: null, slide: null });
    },
  },
  computed: {
    delayedOrder() {
      if (!this.lead || !this.lead.delayed) {
        return null;
      }

      return this.lead.delayed;
    },
    products() {
      return this.lead && this.lead.products? this.lead.products : [];
    },
  },
  components: {
    Modal,
  },
};
</script>