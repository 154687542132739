var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-header" }, [
    _c("div", { staticClass: "main-header__wrapper" }, [
      _c(
        "button",
        { staticClass: "header-btn", on: { click: _vm.showOrders } },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 200 200"
              }
            },
            [
              _c("line", {
                attrs: { x1: "185.71", y1: "156", x2: "14.61", y2: "156" }
              }),
              _c("line", {
                attrs: { x1: "14.61", y1: "44", x2: "185.71", y2: "44" }
              }),
              _c("line", {
                attrs: { x1: "14.31", y1: "100", x2: "185.41", y2: "100" }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.showOrderHeader
        ? _c("div", { staticClass: "main-header__content" }, [
            _c("h1", [_vm._v(_vm._s(_vm.order.caseid))]),
            _vm._v(" "),
            _vm.contentName
              ? _c("h2", { staticClass: "main-header__content__name" }, [
                  _vm._v("| " + _vm._s(_vm.contentName))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.customer && !_vm.orderHeader && !_vm.isLogin
        ? _c("div", { staticClass: "main-header__content" }, [
            _c("h1", [_vm._v(_vm._s(_vm.customer.customer_no))]),
            _vm._v(" "),
            _c("h2", { staticClass: "main-header__content__name" }, [
              _vm._v(_vm._s(_vm.customer.name))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLogin
        ? _c("div", { staticClass: "main-header__content" }, [
            _c("h1", [_vm._v("NEMBLIK")]),
            _vm._v(" "),
            _c("h2", { staticClass: "main-header__content__name" }, [
              _vm._v("Login")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpload
        ? _c(
            "button",
            {
              staticClass: "header-action-btn header-action-btn--upload",
              on: { click: _vm.onUpload }
            },
            [
              _c("span", [_vm._v("Upload")]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 403.9 349.36"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M192.8,154.16l-46.5,49.9a8.94,8.94,0,0,1-6.5,2.9,9.19,9.19,0,0,1-6.7-2.5,9.45,9.45,0,0,1-3-6.5,9.19,9.19,0,0,1,2.5-6.7l62.9-67.5a9.32,9.32,0,0,1,6.8-3,9.16,9.16,0,0,1,6.8,3l62.8,67.5a9.19,9.19,0,0,1,2.5,6.7,9.34,9.34,0,0,1-2.9,6.5,9.19,9.19,0,0,1-6.7,2.5,9.45,9.45,0,0,1-6.5-3l-46.5-49.8v185.6a9.6,9.6,0,0,1-19.2,0V154.16Z"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M345.9,255.46H240.5a9.6,9.6,0,1,1,0-19.2H346c20,0,38.4-20.4,38.4-42.8a43.24,43.24,0,0,0-38.4-43.2,9.65,9.65,0,0,1-6.6-3.7,9.36,9.36,0,0,1-1.8-7.4,100.24,100.24,0,0,0,1.7-16.4,103.37,103.37,0,0,0-206.1-10.9,9.43,9.43,0,0,1-5.3,7.6,9.68,9.68,0,0,1-9.2-.3,18.85,18.85,0,0,0-10.1-2.9,19.8,19.8,0,0,0-19.9,19.9,7.61,7.61,0,0,0,.3,2.1c.3,1.5.6,3.1.8,4.6a9.6,9.6,0,0,1-12.8,10,43,43,0,1,0-14.7,83.3h94.9a9.6,9.6,0,1,1,0,19.2H62.5a62.2,62.2,0,1,1,0-124.4,62.82,62.82,0,0,1,7.5.4,39.28,39.28,0,0,1,38.8-34.5,37.09,37.09,0,0,1,7.4.7,122.54,122.54,0,0,1,242.5,25,88.93,88.93,0,0,1-.6,10.3,62.44,62.44,0,0,1,45.8,60.4C403.4,226.46,376.5,255.46,345.9,255.46Z"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReply
        ? _c(
            "button",
            {
              staticClass: "header-action-btn header-action-btn--reply",
              on: { click: _vm.onReply }
            },
            [
              _c("span", [_vm._v("Svar")]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 164.32 277.75"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M51.82,52.75H33.32V52.6L73.11,16.13A9.3,9.3,0,1,0,60.44,2.5L3,55.35A9.28,9.28,0,0,0,3,69l57.46,52.91a9.35,9.35,0,0,0,12.8-13.63L33.32,71.8v0h18.5a93.5,93.5,0,0,1,0,187,9.5,9.5,0,1,0,0,19,112.5,112.5,0,0,0,0-225Z"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0c5585d3", { render: render, staticRenderFns: staticRenderFns })
  }
}