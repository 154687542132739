<template>
    <slide :title="title" :orderHelp="helpText" class="order-flow__wrapper--2" :validated="isValid" @next="submit">
        <template slot="content">
                    <div class="number-tiles" :class="{'number-tiles--selection': (numberSelected > 0)}">
                        <div class="number-tiles__item" :class="['number-tiles__item--' + n, {'number-tiles__item--selected': (numberSelected === n)}]" @click="selectNumber(n)" v-for="n in 4" :key="n">
                            <span v-text="n"></span>
                        </div>
                    </div>
                    <button href="#" class="order-flow__expand-btn" @click="showMoreOwners = true" v-if="!showMoreOwners">Flere ejere?</button>
                    <div class="order-flow__content__expansion" :class="{'order-flow__content__expansion--open': showMoreOwners}">
                        <div class="number-tiles" :class="{'number-tiles--selection': (numberSelected > 0)}">
                            <div class="number-tiles__item" :class="['number-tiles__item--' + n, {'number-tiles__item--selected': (numberSelected === (n + 4))}]" @click="selectNumber((n + 4))" v-for="n in 4" :key="n">
                                <span v-text="n + 4"></span>
                            </div>
                        </div>
                    </div>
        </template>
    </slide>

    
</template>

<script>
import Slide from './slide.vue';
export default {
  props: {
      companyName: {type: String, default: 'din virksomhed?'},
      numberOfOwners: {type: Number, default: 0}
  },
  data() {
      return {
          showMoreOwners: false,
          numberSelected: 0,
          helpText: {
              heading: 'Information',
              paragraphs: [
                  'Du kan vælge op til 4 ejere ved stiftelse med fasttrack. Prisen vil dermed stige, hvis der er flere end 4 ejere.'
              ]
          }
      }
  },
  mounted() {
      this.numberSelected = this.numberOfOwners;
      if(this.numberSelected > 4) {
          this.showMoreOwners = true;
      }
  },
  watch: {
      numberOfOwners() {
        this.numberSelected = this.numberOfOwners;
        if(this.numberSelected > 4) {
            this.showMoreOwners = true;
        }
      }
  },
  methods: {
      selectNumber(n) {
          this.numberSelected = n;
      },
      submit() {
        this.$emit('submit', this.numberSelected);  
      }
  },
  computed: {
    isValid() {
        return (this.numberSelected > 0) ? true : false;
    },
    title() {
        return 'Hvor mange skal eje <br/>' + this.companyName;
    }
  },
  components: {
      'slide': Slide
  }
}
</script>

