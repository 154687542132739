<template>
  <slide
    title="Selskabets data er modtaget, bolden er ved os!"
    class="order-flow__wrapper--10"
    @next="submit()"
    :validated="false"
  >
    <template slot="content">
      <div class="order-flow__text-block">
        <p>
          Du kan fra dit overblik udfylde data på evt. andre produkter du har
          bestilt. Du kan også skrive til os - hvis der er noget du har glemt,
          eller booke et møde hvis du gerne vil tale med os.
        </p>
      </div>
      <final-order :lead="lead" :product="product"></final-order>
    </template>
    <template slot="actions">
      <button class="btn btn--show" @click="submit">Luk</button>
    </template>
  </slide>
</template>

<script>
import Slide from "./slide.vue";
import FinalOrder from "./final-order.vue";

export default {
  props: {
    lead: {
      type: Object,
      defualt() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit", true);
    },
  },
  components: {
    Slide,
    FinalOrder,
  },
};
</script>