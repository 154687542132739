<template>
  <div class="msg-view">
    <message-thread :order="order" :messages="messages" :part="customer" :class="{'loading': loading}"></message-thread>
    <new-message-view
      v-if="newMessage"
      :order="order"
      :current-user="customer"
      :part-hash="partHash"
      :messages="messages"
      @close="newMessage = false"
      @messagesent="onMessageSent"
    ></new-message-view>
  </div>
</template>

<script type="text/javascript">
import API from "../../services/datastore.js";
import MessageThread from "./../messages/message-thred.vue";
import NewMessageView from "./../messages/new-message-view.vue";

export default {
  props: {
    hash: { type: String, default: "" },
    partHash: {type: String, default: null},
    customer: { type: Object, default: null },
    order: { type: Object, default: null }
  },
  data() {
    return {
      messages: [],
      loaded: false,
      loading: false,
      newMessage: false
    };
  },
  watch: {
    hash() {
      this.loadData();
    },
    partHash() {
      this.loadData();
    }
  },
  mounted() {
    window.messageBus.$on('reply_headerbtn_click', this.onReplyHeaderClick.bind(this));

    if (this.hash) {
      this.loadData();
      window.document.getElementById('app').classList.add('noScroll');
    }
  },
  beforeDestroy() {
      window.messageBus.$off('reply_headerbtn_click', this.onReplyHeaderClick.bind(this));
      window.document.getElementById('app').classList.remove('noScroll');
  },
  methods: {
    onReplyHeaderClick() {
      this.newMessage = true;
    },
    loadData() {
      if (this.loading || !this.hash) return;

      this.loading = true;
      API.getMessages(this.hash, true, this.partHash)
        .then(res => {
          this.loading = false;
          this.$set(this, "messages", res.data);
          this.$emit("messageread", true);
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    onMessageSent(res) {
      let mesages = this.messages;
      mesages.push(res.data);
      this.$set(this, "messages", mesages);
      this.newMessage = false;
    }
  },
  components: {
    MessageThread,
    NewMessageView
  }
};
</script>