var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dda-header" }, [
      _c("div", { staticClass: "nem-wrapper" }, [
        _c("img", {
          staticClass: "nem-header__logo",
          attrs: {
            src: "/assets/img/dda-logo.png",
            alt: "Danmarks Digitale Advokater"
          }
        })
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4304486f", { render: render, staticRenderFns: staticRenderFns })
  }
}