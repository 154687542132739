<template>
  <div class="kyc-wrapper">
    <h2 v-text="title"></h2>
    <div class="nem-dropzone">
      <vue-dropzone
        ref="dropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-queue-complete="finishUploading"
        @vdropzone-file-added="onFileAdd"
        @vdropzone-files-added="onFileAdd"
        @vdropzone-max-files-exceeded="onMaxFiles"
      ></vue-dropzone>
    </div>
    <p v-if="error" class="error" v-text="error"></p>
    <button
      class="btn"
      :disabled="uploadingFiles || !queueSize"
      :class="{ loading: uploadingFiles }"
      @click="submit"
      v-text="translations.upload_btn"
    ></button>
  </div>
</template>

<script>
import DataStore from "./../../services/datastore";
import VueDropzone from "vue2-dropzone";

export default {
  props: {
    uid: {
      type: String,
      default: "",
    },
    validationTypes: {
      type: Object,
      default() {
        return {};
      },
    },
    validationMethod: {
      type: Object,
      defualt: null,
    },
    translations: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      file: null,
      loading: false,
      error: "",
      uploadingFiles: false,
      queueSize: 0,
    };
  },
  watch: {
    queueSize() {
      if (this.uploadingFiles && this.queueSize <= 0) {
        this.uploadingFiles = false;
      }
    },
  },
  methods: {
    onFileAdd() {
      let files = this.$refs.dropzone.dropzone.files;
      if(files.length > 1) {
        this.$refs.dropzone.removeFile(files[0]);
      }

      this.$nextTick(() => {
        this.checkQueue();
      });
    },
    checkQueue() {
      return (this.queueSize =
        this.$refs.dropzone.getAcceptedFiles().length +
        this.$refs.dropzone.getQueuedFiles().length);
    },
    submit() {
      this.error = "";

      if (this.checkQueue() > 0) {
        this.$refs.dropzone.processQueue();
        this.uploadingFiles = true;
      }
    },
    finishUploading() {
      if (this.uploadingFiles) {
        this.$emit("submit");
      }
    },
    onMaxFiles(file) {
      this.$refs.dropzone.removeAllFiles();
      this.$refs.dropzone.addFile(file);
      this.checkQueue();
    },
    onError(file, message) {
      this.checkQueue();
    },
  },
  computed: {
    selectedType() {
      if (!this.validationMethod || !this.validationMethod.validation_id) {
        return null;
      }

      return this.validationMethod.validation_id.type;
    },
    title() {
      let str = this.translations.title;
      if (!this.validationMethod || !this.validationMethod.validation_id) {
        return str;
      }

      let type = Object.keys(this.validationTypes).filter(
        (type) =>
          parseInt(type) == parseInt(this.validationMethod.validation_id.type)
      );

      if (type.length) {
        str = str + " " + this.translations.validationNames[type[0]];
      }

      return str;
    },
    dropzoneOptions() {
      let uploadIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.19 32.19"><path d="M16,23.7a.88.88,0,0,1-.69-.33L6.73,12.71a.89.89,0,0,1,.69-1.45h3.89V3.75a.89.89,0,0,1,.89-.89h7.6a.89.89,0,0,1,.89.89v7.51h3.88a.88.88,0,0,1,.8.51.87.87,0,0,1-.11.94L16.69,23.37A.88.88,0,0,1,16,23.7ZM7.66,12.26,16,22.63l8.34-10.37H20.57a.88.88,0,0,1-.88-.89V3.86H12.31v7.52a.88.88,0,0,1-.89.88Z"/><path d="M30.94,20.66a.5.5,0,0,0-.5.5V27c0,.06-.12.1-.29.1H1.84c-.16,0-.28,0-.28-.1V21.16a.51.51,0,0,0-.5-.5.5.5,0,0,0-.5.5V27a1.15,1.15,0,0,0,1.28,1.1H30.15A1.16,1.16,0,0,0,31.44,27V21.16A.51.51,0,0,0,30.94,20.66Z"/></svg>';

      return {
        maxFiles: 1,
        url: DataStore.KYCSignValidationFileURL(this.uid, this.selectedType),
        thumbnailWidth: 150,
        maxFilesize: 5,
        acceptedFiles: "image/jpeg,image/png,image/gif,application/pdf",
        autoProcessQueue: false,
        addRemoveLinks: false,
        dictDefaultMessage:
          uploadIcon + "<br/>" + this.translations.select_files,
        dictFallbackMessage: this.translations.select_files,
        dictFileTooBig: this.translations.size_error,
        dictInvalidFileType: this.translations.type_error,
        dictResponseError: this.translations.upload_error,
        dictRemoveFile: this.translations.remove_file,
        dictCancelUpload: this.translations.cancel_upload,
        dictUploadCanceled: this.translations.upload_cancelled,
        dictCancelUploadConfirmation: this.translations.cancel_upload_confirm,
      };
    },
  },
  components: {
    VueDropzone,
  },
};
</script>