<template>
  <div
    class="log-message"
    v-if="logType"
    :class="[
      'log-message-type--' + logType,
      { 'log-message-error': statuslog.contact_log_status > 500 },
    ]"
  >
    <div class="log-message__header">
      <div>
        <span class="log-message__date">{{ date }}</span>
        <span class="log-message__name">{{ name }}</span>
      </div>
    </div>
    <div class="log-message__body">
      <div class="log-message__text">
        <template v-if="logType == 'WELCOME_MESSAGE'">
          <p>
            <strong>Hej {{ lead.name }}</strong>
          </p>
          <p>
            Tak for din henvendelse til NEMADVOKAT. Vi har sendt en kvittering
            til din mail med et link hertil. Vi finder nu en medarbejder til din
            sag.
            <strong>Hvordan ønsker du vi kontakter dig?</strong>
          </p>
          <div class="log-message__actions" v-if="statuslog.showActions">
            <a
              href="javascript:void(0)"
              @click="$emit('contactmethod', 'email')"
            >
              <span>På e-mail</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="log-message__phone-icon"
              >
                <path
                  d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                />
              </svg>
            </a>
            <span>|</span>
            <a
              href="javascript:void(0)"
              @click="$emit('contactmethod', 'phone')"
            >
              <span>På telefon</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="log-message__email-icon"
              >
                <path
                  d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                />
              </svg>
            </a>
          </div>
        </template>

        <template v-if="logType == 'WELCME_ACTION_NOT_SET_ERROR'">
          <p>Der skete en fejl og dit svar blev ikke sendt! Prøv igen...</p>
          <div class="log-message__actions">
            <a
              href="javascript:void(0)"
              @click="$emit('contactmethod', 'email')"
            >
              <span>På e-mail</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="log-message__phone-icon"
              >
                <path
                  d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
                />
              </svg>
            </a>
            <span>|</span>
            <a
              href="javascript:void(0)"
              @click="$emit('contactmethod', 'phone')"
            >
              <span>På telefon</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="log-message__email-icon"
              >
                <path
                  d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                />
              </svg>
            </a>
          </div>
        </template>

        <template v-if="logType == 'WE_CALl_MESSAGE'">
          <p><strong>Vi ringer til dig</strong></p>
          <p>
            Det gør vi senest indenfor to timer i vores åbningstid. Passer det
            bedre på et andet tidspunkt, så sæt gerne et
            <a href="javascript:void(0)" @click="$emit('setcontacttime')"
              >tidspunkt her</a
            >, hvor du træffes bedst.
          </p>
          <p>
            Mens du venter på os, kan du bekræfte kundeinformation og udfylde
            sagsdata med knapper nedenfor.
            <a v-if="typeof lead.customer_name !== 'undefined'" href="javascript:void(0)" @click="$emit('setcustomerinfo')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/><circle cx="16.53" cy="13.56" r="2.84" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/></svg>
            </a>
            <a v-if="containsCaseData" href="javascript:void(0)" @click="$emit('setcasedata')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/><path d="M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><circle cx="16.53" cy="4.38" r="2.08" style="fill:none; stroke:#0f436b; stroke-miterlimit:10; stroke-width:1.5px;"/><path d="M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/></svg>
            </a>
          </p>
          <p>En stor hjælp der sparer en masse tid.</p>
        </template>

        <template v-if="logType == 'WE_CALL_MESSAGE_RELATED_PRODUCTS'">
          <p><strong>Vi ringer til dig</strong></p>
          <p>
            Det gør vi senest indenfor to timer i vores åbningstid. Har du et tidspunkt der passer bedre,
            <a href="javascript:void(0)" @click="$emit('setcontacttime')"
              >så sæt det her</a
            >.
          </p>
          <p><strong>Vi kan hjælpe med mere</strong></p>
          <p>For de fleste af vores kunder som vi har hjulpet med {{ statuslog.mainProductName }} har vi også lavet en uforpligtende gennemgang af behovet for {{ statuslog.relatedProductNames }}</p>
          <p>Skal vi tage en snak om dette når vi ringer?</p>
          <p v-if="statuslog.showActions"><a href="javascript:void(0)" @click="$emit('addrelatedproducts', true)">Ja tak</a> | <a href="javascript:void(0)" @click="$emit('addrelatedproducts', false)">Nej tak</a></p>
        </template>

        <template v-if="logType == 'WE_WRITE_MESSAGE'">
          <p><strong>Vi skriver til dig </strong></p>
          <p>
            Måtte et opkald være bedre, men på et specifikt tidspunkt kan du give
            os et
            <a href="javascript:void(0)" @click="$emit('setcontacttime')"
              >tidspunkt her</a
            >, hvor du træffes bedst.
          </p>
          <p>
            Mens du venter på os, kan du bekræfte kundeinformation og udfylde
            sagsdata med knapper nedenfor.
            <a v-if="typeof lead.customer_name !== 'undefined'" href="javascript:void(0)" @click="$emit('setcustomerinfo')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/><circle cx="16.53" cy="13.56" r="2.84" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/></svg>
            </a>
            <a v-if="containsCaseData" href="javascript:void(0)" @click="$emit('setcasedata')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/><path d="M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><circle cx="16.53" cy="4.38" r="2.08" style="fill:none; stroke:#0f436b; stroke-miterlimit:10; stroke-width:1.5px;"/><path d="M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/></svg>
            </a>
          </p>
          <p>En stor hjælp der sparer en masse tid.</p>
        </template>

        <template v-if="logType == 'WE_WRITE_MESSAGE_RELATED_PRODUCTS'">
          <p><strong>Vi skriver til dig</strong></p>
          <p>
            Det gør vi senest indenfor to timer i vores åbningstid.
          </p>
          <p><strong>Vi kan hjælpe med mere</strong></p>
          <p>For de fleste af vores kunder som vi har hjulpet med {{ statuslog.mainProductName }} har vi også lavet en uforpligtende gennemgang af behovet for {{ statuslog.relatedProductNames }}</p>
          <p>Skal vi tage en snak om dette når vi ringer?</p>
          <p v-if="statuslog.showActions"><a href="javascript:void(0)" @click="$emit('addrelatedproducts', true)">Ja tak</a> | <a href="javascript:void(0)" @click="$emit('addrelatedproducts', false)">Nej tak</a></p>
        </template>

        <template v-if="logType == 'RELATED_PRODUCTS_ACCEPTED'">
          <p><strong>Vi udvider holdet</strong></p>
          <p>
            Din henvendelse får nu nogle ekstra hænder og øjne, så vi kommer hele vejen rundt.
          </p>
          <p>
            Mens du venter på nyt fra os, kan du selv hjælpe sagen videre ved at udfylde kundedata og evt. sagsdata med knapperne nedenfor.
          </p>
          <p>
            <a v-if="typeof lead.customer_name !== 'undefined'" href="javascript:void(0)" @click="$emit('setcustomerinfo')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/><circle cx="16.53" cy="13.56" r="2.84" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/></svg>
            </a>
            <a v-if="containsCaseData" href="javascript:void(0)" @click="$emit('setcasedata')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/><path d="M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><circle cx="16.53" cy="4.38" r="2.08" style="fill:none; stroke:#0f436b; stroke-miterlimit:10; stroke-width:1.5px;"/><path d="M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/></svg>
            </a>
          </p>
          <p>En stor hjælp der sparer en masse tid.</p>
        </template>

        <template v-if="logType == 'RELATED_PRODUCTS_REJECTED'">
          <p><strong>Det er modtaget</strong></p>
          <p>
            Du skal dog ikke holde dig tilbage, hvis du ønsker at vi kigger på det senere i processen.
          </p>
          <p>
            Mens du venter på nyt fra os, kan du selv hjælpe sagen videre ved at udfylde kundedata og evt. sagsdata med knapperne nedenfor.
          </p>
          <p>
            <a v-if="typeof lead.customer_name !== 'undefined'" href="javascript:void(0)" @click="$emit('setcustomerinfo')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/><circle cx="16.53" cy="13.56" r="2.84" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/></svg>
            </a>
            <a v-if="containsCaseData" href="javascript:void(0)" @click="$emit('setcasedata')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="log-message__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z" style="fill:none; stroke:#0f436b; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/><path d="M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><path d="M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#0f436b;"/><circle cx="16.53" cy="4.38" r="2.08" style="fill:none; stroke:#0f436b; stroke-miterlimit:10; stroke-width:1.5px;"/><path d="M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#0f436b;"/></svg>
            </a>
          </p>
          <p>En stor hjælp der sparer en masse tid.</p>
        </template>


        <template v-if="logType == 'CUSTOMER_INFO_RECEIVED'">
          <p><strong>Kundeinformation er modtaget</strong></p>
          <p>
            De indtastede oplysninger er modtaget og arkiveret på rette sted.
            <span v-if="!leadDisabled"
              >Du kan se en
              <a href="javascript:void(0)" @click="$emit('viewcustomerinvoice')"
                >kvittering</a
              >
              med dine inputs her.</span
            >
          </p>
          <p>
            Måtte der være fejl, kan du
            <a href="javascript:void(0)" @click="$emit('comment')"
              >sende os en kommentar</a
            >
          </p>
        </template>

        <template v-if="logType == 'CUSTOMER_CASEINFO_RECEIVED'">
          <p><strong>Sagsdata er modtaget</strong></p>
          <p>
            De indtastede oplysninger er modtaget og arkiveret på rette sted.
            <span v-if="!leadDisabled"
              >Du kan se en
              <a
                href="javascript:void(0)"
                @click="$emit('viewproductinvoice', metadata.productid)"
                >kvittering</a
              >
              med dine inputs her.</span
            >
          </p>
          <p>
            Måtte der være fejl, kan du sende os en kommentar med knappen
            nedenfor
          </p>
        </template>

        <template v-if="logType == 'CUSTOMER_COMMENT_RECEIVED'">
          <p><strong>Din kommentar er modtaget</strong></p>
          <p>
            Samtidigt er der sendt en notifikation til den ansvarlige
            medarbejder. Du kan forvente svar fra os indenfor to timer i vores
            åbningstid.
          </p>
          <p v-if="statuslog.commentidx > -1">
            Du kan se
            <a
              href="javascript:void(0)"
              @click="$emit('viewcomment', statuslog.commentidx)"
              >din kommentar her.</a
            >
          </p>
        </template>

        <template v-if="logType == 'CUSTOMER_WANTS_CALL'">
          <p><strong>Det er en aftale</strong></p>
          <p v-if="metadata.startTime && metadata.endTime">
            Vi ringer til dig
            {{ getMoment(metadata.startTime).format("DD-MM-YYYY") }} mellem
            {{ getMoment(metadata.startTime).format("HH") }} -
            {{ getMoment(metadata.endTime).format("HH") }}
          </p>
          <p>
            Ønsker du at ændre tidspunktet kan du gøre det
            <a href="javascript:void(0)" @click="$emit('setcontacttime')"
              >lige her.</a
            >
          </p>
        </template>

        <template v-if="logType == 'NO_CALL_RESPONSE_CUSTOMER'">
          <p><strong>Du tog ikke telefonen</strong></p>
          <p>
            Jeg har lige forsøgt at ringe til dig uden held. Det vil jeg prøve
            at gøre igen
          </p>
          <p>
            Du er også velkommen til at give os et
            <a href="javascript:void(0)" @click="$emit('setcontacttime')"
              >tidspunkt her</a
            >, hvor du træffes bedst. Så ringer vi der.
          </p>
        </template>

        <template v-if="logType == 'CUSTOMER_ON_HOLD'">
          <p><strong>Henvendelse sat på hold</strong></p>
          <p>
            Jeg har sat din henvendelse på pause indtil d. {{ (metadata.delayed_time) ? getMoment(metadata.delayed_time).format("DD-MM-YYYY") : '' }}. Dette
            kan skyldes flere forgæves forsøg på kontakt, eller en aftale med
            dig.
          </p>
          <p>
            Skal vi modsat videre med det samme skal du sende os en
            <a href="javascript:void(0)" @click="$emit('comment')">kommentar</a
            >.
          </p>
        </template>

        <template v-if="logType == 'AWAITING_CUSTOMER_INFO'">
          <nl2br tag="p" v-if="metadata.reason" :text="metadata.reason" />
        </template>

        <template v-if="logType == 'LEAD_ARCHIVED'">
          <p>
            <strong>VI har arkiveret din henvendelse</strong>
          </p>
          <p>Vi fortager os ikke yderligere vedrørende denne henvendelse.</p>
          <p>
            Du kan sende en
            <a href="javascript:void(0)" @click="$emit('comment')">kommentar</a>
          </p>
        </template>
      </div>
      <img :src="image" class="log-message__avatar" />
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import nl2br from "vue-nl2br";

export default {
  props: {
    statuslog: {
      type: Object,
      default() {
        return {};
      },
    },
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
    containsCaseData: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getMoment(time) {
      return Moment(time);
    },
  },
  computed: {
    leadDisabled() {
      return this.lead.contact_state == 4 ? true : false;
    },
    logType() {
      return this.statuslog.logType;
    },
    date() {
      return this.statuslog
        ? Moment(this.statuslog.created_at).format("lll")
        : "";
    },
    name() {
      return this.statuslog && this.statuslog.user
        ? this.statuslog.user.fullname
        : "Nemadvokat";
    },
    image() {
      if (this.statuslog && this.statuslog.user) {
        return this.statuslog.user.image
          ? this.statuslog.user.image
          : "/assets/img/client-icon.svg";
      }

      return "/assets/img/nemadvokat-avatar.svg";
    },
    metadata() {
      return this.statuslog && this.statuslog.metadata
        ? this.statuslog.metadata
        : {};
    }
  },
  components: {
    nl2br,
  },
};
</script>