var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "intro-header" }, [
    _c("div", { staticClass: "intro-header__wrapper" }, [
      _c("div", { staticClass: "content-block" }, [
        _c(
          "h1",
          { staticClass: "content-block__heading" },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _vm.content
          ? _c("p", { staticClass: "content-block__text" }, [
              _vm._v(_vm._s(_vm.content))
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78d3005b", { render: render, staticRenderFns: staticRenderFns })
  }
}