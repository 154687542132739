<template>
    <div class="order-flow__wrapper">
        <div class="order-flow__header">
            <h1 class="order-flow__heading" v-html="title"></h1>
            <h3 class="order-flow__subheading" v-if="subTitle" v-text="subTitle"></h3>
            <h4 class="order-flow__subsubheading" v-if="subSubTitle" v-text="subSubTitle"></h4>
        </div>
        <div class="order-flow__content">
            <slot name="content"></slot>
            <div class="order-help" v-if="orderHelp">
                <h3 class="order-help__heading" v-text="orderHelp.heading"></h3>
                <template v-if="orderHelp.paragraphs">
                    <p class="order-help__text" v-for="(text, key) in orderHelp.paragraphs" v-html="text" :key="key"></p>
                </template>
            </div>
        </div>
        <div class="order-flow__actions" v-if="showNextBtn" :class="{'order-flow__actions--show': validated}">
            <slot name="actions"></slot>
            <button class="btn" :class="{'btn--show': validated}" @click="(validated) ? $emit('next') : null" :disabled="!this.validated" v-if="!isNavigatedFromOrder">Videre</button>
            <button class="btn" :class="{'btn--show': validated}" @click="(validated) ? $emit('next') : null" :disabled="!this.validated" v-if="isNavigatedFromOrder">Gem</button>
        </div>
    </div>
</template>

<script>
export default {
  props: {
      title: {type: String, defualt: ''},
      subTitle: {type: String, default: null},
      subSubTitle: {type: String, default: null},
      validated: {type: Boolean, default: true},
      showNextBtn: {type: Boolean, default: true},
      overwriteSaveBtn: {type: Boolean, defalt: false},
      orderHelp: {type: Object, default: null}
  },
  computed: {
      isNavigatedFromOrder() {
          return (this.$route.query.navigatedFromOrder && !this.overwriteSaveBtn) ? true : false;
      }
  }
}
</script>

