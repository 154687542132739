<template>
  <div class="case-menu">
    <a 
      v-if="lead.contact_state != 4 && typeof lead.customer_name !== 'undefined'"
      :class="{ 'case-menu__item--verified': lead.customer_verified }"
      class="case-menu__item case-menu__item--tasks"
      href="javascript:void(0)"
      @click="fillCustomerData"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="case-menu__item__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-1V4.91q0-1,1-1H24.84c1,0,1,.07,1,1V28.16c0,1,0,1-1,1Z" style="fill:none; stroke:#fff; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M19.25,6.24h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#fff;"/><circle cx="16.53" cy="13.56" r="2.84" style="fill:none; stroke:#fff; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M21.21,23.98H11.86s.46-5.11,4.68-5.11,4.68,5.11,4.68,5.11Z" style="fill:none; stroke:#fff; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/></svg>
      <span class="case-menu__item__text">Kundedata</span>
    </a>
    <router-link
      v-if="lead.contact_state != 4 && containsCaseData"
      :class="{ 'case-menu__item--verified': verifiedCaseData }"
      :to="{ name: 'lead-view', params: { view: 'products' } }"
      class="case-menu__item"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="case-menu__item__icon"><path d="M24.84,29.16H8.23c-1,0-1,0-1-.95V6.04q0-.95,1-.95H24.84c1,0,1,.07,1,.95V28.2c0,.95,0,.95-1,.95Z" style="fill:none; stroke:#fff; stroke-linejoin:round; stroke-width:1.5px;"/><rect width="33.07" height="33.07" style="fill:none;"/><path d="M21.96,12.27s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#fff;"/><path d="M21.96,17.03s0,0-.01,0l-10.82,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l10.82-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#fff;"/><path d="M16.8,21.79s0,0-.01,0l-5.67,.02c-.27,0-.49,.32-.49,.74,0,.41,.21,.75,.48,.76,0,0,0,0,.01,0l5.67-.02c.27,0,.49-.32,.49-.74,0-.41-.21-.75-.48-.76Z" style="fill:#fff;"/><circle cx="16.53" cy="4.38" r="2.08" style="fill:none; stroke:#fff; stroke-miterlimit:10; stroke-width:1.5px;"/><path d="M19.25,7.44h-5.43c-1.17,0-1.17,0-1.17-1.17v-.52h7.77v.52c0,1.17,.01,1.17-1.17,1.17Z" style="fill:#fff;"/></svg>
      <span class="case-menu__item__text">Sagsdata</span>
    </router-link>
    <router-link
      :to="{ name: 'lead-view', params: { view: 'kommentar' } }"
      class="case-menu__item"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.07 33.07" class="case-menu__item__icon"><rect width="33.07" height="33.07" style="fill:none;"/><path d="M29.16,9.72v13.62c0,1,0,1-1,1H4.91q-1,0-1-1V9.72c0-1,.07-1,1-1H28.16c1,0,1,0,1,1Z" style="fill:none; stroke:#fff; stroke-linejoin:round; stroke-width:1.5px;"/><path d="M28.81,9.61c-.24-.33-.61-.48-.83-.32l-11.45,8.15L5.09,9.29c-.21-.16-.58-.02-.83,.32-.24,.33-.27,.74-.05,.9h.01l12.07,8.61c.07,.05,.16,.06,.25,.05,.09,0,.18,0,.25-.05l12.07-8.6h.01c.21-.17,.19-.57-.05-.9Z" style="fill:#fff;"/><path d="M4.22,23.06h-.01c-.21,.17-.19,.57,.05,.9,.24,.33,.61,.48,.83,.32l7.95-5.66-1.29-.92-7.53,5.36Z" style="fill:#fff;"/><path d="M28.85,23.06l-7.53-5.36-1.29,.92,7.95,5.66c.21,.16,.58,.02,.83-.32,.24-.33,.27-.74,.05-.9h-.01Z" style="fill:#fff;"/></svg>
      <span class="case-menu__item__text">Send kommentar</span>
    </router-link>
  </div>
</template>

<script>
import { HasCaseDataToFill } from "./casedata-util";

export default {
  props: {
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    fillCustomerData() {
      if (this.lead.customer_verified) {
        this.$router.push({
          name: "lead-view",
          params: { view: "customer-recept" },
        });
        return;
      }

      this.$router.push({
        name: "lead-view",
        params: { view: "kundevalidering" },
      });
    },
  },
  computed: {
    products() {
      return this.lead && this.lead.products ? this.lead.products : [];
    },
    verifiedCaseData() {
      return this.products.filter(
        ({ company_data_filled }) => !company_data_filled
      ).length <= 0
        ? true
        : false;
    },
    containsCaseData() {
      return this.products.filter((leadProduct) =>
        HasCaseDataToFill(leadProduct)
      ).length > 0
        ? true
        : false;
    },
  },
};
</script>