var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "case-economy" },
    [
      _c("case-deposits", {
        attrs: { order: _vm.order },
        on: {
          showcustomerletter: _vm.onShowContract,
          showdeposits: _vm.shoeDepositData
        }
      }),
      _vm._v(" "),
      _vm.showDepositModal
        ? _c(
            "modal",
            {
              class: { loading: _vm.aftaleLoading },
              attrs: { title: "Deponering af honorar", show: true },
              on: { close: _vm.onCloseContractModal }
            },
            [
              _vm.letterData
                ? _c("deposit", {
                    attrs: { letterData: _vm.letterData },
                    on: { showletter: _vm.onShowContract }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.aftaleError
                ? _c("p", { staticClass: "error" }, [
                    _vm._v("\n      Deponeringsdata kunne ikke hentes...\n    ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.aftaleLoading,
                      expression: "aftaleLoading"
                    }
                  ]
                },
                [_vm._v("Henter deponerings info...")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showContractTableModal
        ? _c("contract-table-modal", {
            attrs: { order: _vm.order, customer: _vm.customer },
            on: {
              close: function($event) {
                _vm.showContractTableModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-729fc940", { render: render, staticRenderFns: staticRenderFns })
  }
}