<template>
        <div class="create-profile-view" :class="{'loading': loading}">
            <div class="lead-section">
                <div class="nem-wrapper">
                    <h2>{{ order.caseid }} Dokumenter</h2>
                    <droppoint :upload-url="upoloadUrl">
                        <p>Her kan du sikkert sende filer til NEMADVOKAT.</p>
                        <p>Træk de filer ud du ønsker at dele ind i boksen nedenfor, eller tryk på 'Vælg filer' for at finde dem på din enhed.</p>
                        <p>Herefter trykker du på uploadknappen for at sende dem til os. Vi får automatisk en notifikation, og går straks dine filer igennem.</p>
                    </droppoint>
                </div>
            </div>
        </div>
</template>

<script>
import Droppoint from './case/case-droppoint.vue';
import DataStore from './../services/datastore.js';

export default {
    data() {
        return {
            loading: false,
            order: { }
        }
    },
    watch: {
        hash() {
            this.loadOrder();
        }
    },
    mounted() {
        this.loadOrder();
    },
    methods: {
        loadOrder() {
            if(this.loading || !this.hash)
                return;
            
            this.loading = true;
            DataStore.getPublicOrder(this.hash).then((res) => {
                this.loading = false;
                this.order = res.data;
            }).catch((err) => {
                document.location = '/';
            });
        }
    },
    computed: {
        hash() {
            return this.$route.params.hash;
        },
        upoloadUrl() {
            return (this.hash) ? DataStore.getPublicUploadUrl(this.hash) : null;
        }
    },
    components: {
        Droppoint
    }
}
</script>