var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product
    ? _c(
        "modal",
        {
          staticClass: "receipt-modal",
          attrs: {
            title: _vm.product.product.title,
            show: true,
            noScroll: false,
            showActions: true
          },
          on: { close: _vm.onClose }
        },
        [
          _vm.lead && _vm.product
            ? _c("invoice", {
                attrs: {
                  "show-header": false,
                  lead: _vm.lead,
                  product: _vm.product
                },
                on: {
                  updated: function(udpatedLead) {
                    return _vm.$emit("input", udpatedLead)
                  },
                  close: _vm.onClose
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--show",
              attrs: { slot: "actions" },
              on: { click: _vm.onClose },
              slot: "actions"
            },
            [_vm._v("Luk")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c335a2a0", { render: render, staticRenderFns: staticRenderFns })
  }
}